import { ACCOUNTS_ROLES } from '../constants/Properties';

export function isAdmin (userAccount, uniqRole = false) {
  return (!uniqRole || userAccount.roles.length === 1)
    && userAccount.roles.indexOf(ACCOUNTS_ROLES.ADMIN) !== -1;
}

export function isClientContact (userAccount, uniqRole = false) {
  return (!uniqRole || userAccount.roles.length === 1)
    && userAccount.roles.indexOf(ACCOUNTS_ROLES.CLIENT_CONTACT) !== -1;
}

export function isTechnicianAdmin (userAccount, uniqRole = false) {
  return (!uniqRole || userAccount.roles.length === 1)
    && userAccount.roles.indexOf(ACCOUNTS_ROLES.TECHNICIAN_ADMIN) !== -1;
}

export function canDoSomething (userAccount) {
  return isAdmin(userAccount) || isClientContact(userAccount) || isTechnicianAdmin(userAccount);
}

export function isSupport (userAccount, uniqRole = false) {
  return (userAccount.email === "support@linaia.com")
}