import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Route } from 'react-router-dom';
import { pickBy, get } from 'lodash';

import ReportsListView from '../../../components/documents/reports/ReportsListView';

import ReportsGraphView from '../../../components/documents/reports/ReportsGraphView';

const ReportsSiteContainer = ({
  match: { url }, reportsDocuments, trackingDocuments, contracts,
}) => (
  <div className="grid-noBottom full-height">
    <Route
      path={`${url}/:contractId?`}
      render={subProps => (
        <ReportsListView
          contracts={contracts}
          selectedContract={subProps.match.params.contractId}
          reportsDocuments={reportsDocuments}
          trackingDocuments={trackingDocuments}
          baseUrl={url}
        />
      )}
    />
    <Route
      path={`${url}/:contractId`}
      render={subProps => (
        <ReportsGraphView contract={get(contracts, subProps.match.params.contractId, {})} />
      )}
    />
  </div>
);

ReportsSiteContainer.propTypes = {
  reportsDocuments: PropTypes.objectOf(PropTypes.shape()),
  trackingDocuments: PropTypes.objectOf(PropTypes.shape()),
  contracts: PropTypes.objectOf(PropTypes.shape()),

  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default connect(
  (state, { siteId, reportCategory, trackingCategory }) => ({
    contracts: pickBy(
      state.data.entities.contracts,
      d => d.contractAffectedSite === siteId,
    ),
    reportsDocuments: pickBy(
      state.data.entities.documents,
      d => d.documentSite === siteId && d.documentCategory === reportCategory,
    ),
    trackingDocuments: pickBy(
      state.data.entities.documents,
      d => d.documentSite === siteId && d.documentCategory === trackingCategory,
    ),
  }),
)(withRouter(ReportsSiteContainer));
