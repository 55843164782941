import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { ButtonConfirmModal } from 'react-components-linaia';

import CardListView from '../shared/CardListView';

const StructuresCardListView = ({
  structures, baseUrl, onClickDelete,
}) => (
  <CardListView
    className="card-list-structures"
    data={structures}
    cardComponent={structure => (
      <div className="col-3_xs-12_sm-6_md-4">
        <div className="card box">
          <div className="box-content">
            <div className="grid-noBottom">
              <div className="col-12">
                <div className="card-title">
                  {structure.structureName}
                </div>
              </div>
              <div className="col-12">
                <a href={`tel:${structure.structurePhone}`}>
                  <i className="fas fa-phone" />
                  <span>
                    {structure.structurePhone}
                  </span>
                </a>
              </div>
              <div className="col-12">
                <a href={`mailto:${structure.structureEmail}`}>
                  <i className="fas fa-envelope" />
                  <span>
                    {structure.structureEmail}
                  </span>
                </a>
              </div>
            </div>
            <div className="card-actions btn-group">
              <NavLink to={`${baseUrl}/${structure.structure_id}/edit`} className="btn">
                <i className="far fa-pencil" />
                <span>Modifier</span>
              </NavLink>
              {onClickDelete && (
                <ButtonConfirmModal
                  onConfirmationButton={() => onClickDelete(structure.structure_id)}
                  message="Voulez-vous vraiment supprimer cette structure ?"
                >
                  <button className="btn" type="button">
                    <i className="far fa-trash" />
                    <span>Supprimer</span>
                  </button>
                </ButtonConfirmModal>
              )}
            </div>
          </div>
        </div>
      </div>
    )}
    search="structureName"
    searchLabel="Rechercher une structure"
    sortByDefault="structureName"
    buttons={(
      <div>
        <NavLink className="btn btn-main-mobile" to={`${baseUrl}/new`}>
          <span>Ajouter une structure</span>
          <i className="far fa-plus" />
        </NavLink>
      </div>
    )}
  />
);

StructuresCardListView.propTypes = {
  structures: PropTypes.objectOf(PropTypes.shape({
    structure_id: PropTypes.number.isRequired,
    structureName: PropTypes.string.isRequired,
    structurePhone: PropTypes.string.isRequired,
    structureEmail: PropTypes.string.isRequired,
  })).isRequired,

  baseUrl: PropTypes.string.isRequired,
  onClickDelete: PropTypes.func,
};

export default (StructuresCardListView);
