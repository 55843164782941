import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Login from './login/Login';
import ResetPassword from './login/ResetPassword';
import AuthenticatedComponent from './shared/AuthenticatedComponent';
import PageNotFound from './shared/PageNotFound';

import AccountsContainer from './accounts/AccountsContainer';
import OptionsContainer from './options/OptionsContainer';

// import MyAccountContainer from './accounts/MyAccountContainer';

import DynamicPlanContainer from './dynamicplan/DynamicPlanContainer';

import ActualitysContainer from './actualitys/ActualitysContainer';
import ClientsAdminContainer from './clients/ClientsAdminContainer';
import ShareddocumentsContainer from './shareddocuments/ShareddocumentsContainer';
import StructuresContainer from './structures/StructuresContainer';
import TechniciansContainer from './technicians/TechniciansContainer';
import TechniciansAdminContainer from './technicians/TechniciansAdminContainer';


import ClientsUserContainer from './clients/ClientsUserContainer';

function requireAuthentication (Component) {
  return () => (
    <AuthenticatedComponent>
      <Component />
    </AuthenticatedComponent>
  );
}

const Router = () => (
  <BrowserRouter>
    <Switch>
      {/* Not authenticated routes */}
      <Route exact path="/login" component={Login} />
      <Route exact path="/reset-password" component={ResetPassword} />

      {/* Admin routes */}
      <Route path="/admin/clients" render={requireAuthentication(ClientsAdminContainer)} />
      <Route path="/admin/technicians" render={requireAuthentication(TechniciansContainer)} />
      <Route path="/admin/structures" render={requireAuthentication(StructuresContainer)} />
      <Route path="/admin/actualitys" render={requireAuthentication(ActualitysContainer)} />
      <Route path="/admin/shareddocuments" render={requireAuthentication(ShareddocumentsContainer)} />
      <Route path="/admin/accounts" render={requireAuthentication(AccountsContainer)} />
      <Route path="/admin/options" render={requireAuthentication(OptionsContainer)} />

      {/* Client routes */}
      <Route path="/clients" render={requireAuthentication(ClientsUserContainer)} />

      {/* Technicians Admin routes */}
      <Route path="/technicians" render={requireAuthentication(TechniciansAdminContainer)} />

      {/* POC / Tests routes */}
      <Route exact path="/admin/dynamic-plan" render={requireAuthentication(DynamicPlanContainer)} />

      <Route path="*" render={requireAuthentication(PageNotFound)} />
    </Switch>
  </BrowserRouter>
);

export default Router;
