import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Route } from 'react-router-dom';
import { pickBy, get, filter, indexOf } from 'lodash';

import PlansListView from '../../../components/documents/plans/PlansListView';
import PlansDetailsView from '../../../components/documents/plans/PlansDetailsView';

const PlansSiteContainer = ({
  match: { url }, documents, equipments, passages,
}) => (
  <div className="grid-noBottom full-height plan-wrapper">
    <PlansListView
      documents={documents}
      baseUrl={url}
    />
    <Route
      exact
      path={`${url}/:documentId/detail`}
      render={subProps => (
        get(documents, subProps.match.params.documentId, null) && (
          <PlansDetailsView
            planId={subProps.match.params.documentId}
            documentPlan={documents[subProps.match.params.documentId]}
            equipments={filter(equipments, equip => (indexOf(equip.equipmentPlan, (parseInt(subProps.match.params.documentId, 10)))) !== -1)}
            passages={passages}
          />
        )
      )}
    />
  </div>
);

PlansSiteContainer.propTypes = {
  documents: PropTypes.objectOf(PropTypes.shape()).isRequired,
  equipments: PropTypes.objectOf(PropTypes.shape()),
  passages: PropTypes.objectOf(PropTypes.shape()),

  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default connect(
  (state, { siteId, documentCategory }) => ({
    documents: pickBy(
      state.data.entities.documents,
      d => d.documentSite === siteId && d.documentCategory === documentCategory,
    ),
    equipments: state.data.entities.equipments,
    passages: state.data.entities.passages,
  }),
)(withRouter(PlansSiteContainer));
