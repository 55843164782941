import React from "react";
import PropTypes from "prop-types";
import { withRouter, NavLink } from "react-router-dom";
import { capitalize, get } from "lodash";
import { connect } from "react-redux";
import { ButtonConfirmModal } from "react-components-linaia";
import moment from "moment";

import TableListView from "../shared/TableListView";

import { UPLOADS_URL, DOCUMENT_OPTIONS } from "../../constants/Config";

const ShareddocumentsTableListView = ({
  history: { push },
  shareddocuments,
  baseUrl,
  onClickDelete,
  sharedcategorys
}) => (
  <TableListView
    className="table-boxed full-height"
    data={shareddocuments}
    cols={{
      shareddocumentName: {
        label: "Nom du document",
        className: "col_sm-12",
        render: (docName, entity) => (
          <a
            href={entity.sharedDocumentType === 'lien' ? `${UPLOADS_URL}/${entity.shareddocumentFile}` : entity.shareddocumentFile}
            target="_blank"
            rel="noopener noreferrer"
          >
            {capitalize(docName)}
          </a>
        ),
        value: capitalize
      },
      shareddocumentCategory: {
        label: "Catégorie",
        className: "col_sm-12",
        value: id =>
          capitalize(get(sharedcategorys, `${id}.sharedcategoryName`))
      }
    }}
    search="shareddocumentName"
    searchLabel="Rechercher un document"
    sortByDefault="shareddocumentName"
    buttons={
      <div>
        <NavLink className="btn btn-main-mobile" to={`${baseUrl}/new`}>
          <span>Ajouter un document</span>
          <i className="far fa-plus" />
        </NavLink>
      </div>
    }
    rowButtons={shareddocument => (
      <div className="col-0_sm-12 cln-2btn">
        <div className="td td-buttons">
          {shareddocument && (
            <NavLink
              className="btn"
              to={`${baseUrl}/${shareddocument.shareddocument_id}/edit`}
            >
              <i className="far fa-pencil" />
            </NavLink>
          )}
          {shareddocument && (
            <ButtonConfirmModal
              onConfirmationButton={() =>
                onClickDelete(shareddocument.shareddocument_id)
              }
              message="Voulez-vous vraiment supprimer ce document ?"
            >
              <button className="btn" type="button">
                <i className="far fa-trash" />
              </button>
            </ButtonConfirmModal>
          )}
        </div>
      </div>
    )}
  />
);

ShareddocumentsTableListView.propTypes = {
  shareddocuments: PropTypes.objectOf(
    PropTypes.shape({
      shareddocument_id: PropTypes.number.isRequired,
      shareddocumentName: PropTypes.string.isRequired,
      shareddocumentCategory: PropTypes.number.isRequired,
      shareddocumentFile: PropTypes.string.isRequired,
      shareddocumentStartDate: PropTypes.string,
      shareddocumentEndDate: PropTypes.string
    })
  ).isRequired,

  sharedcategorys: PropTypes.objectOf(PropTypes.shape()).isRequired,

  baseUrl: PropTypes.string.isRequired,
  onClickDelete: PropTypes.func,

  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default connect(state => ({
  sharedcategorys: state.data.entities.sharedcategorys
}))(withRouter(ShareddocumentsTableListView));
