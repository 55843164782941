import { flow, set } from 'lodash/fp';
import { omit } from 'lodash';

import { CALL_API, Schemas } from '../Schemas';
import { loadAccounts } from './accounts';

const FETCH_ALL_CONTACTS_REQUEST = 'fgwa/contacts/FETCH_ALL_CONTACTS_REQUEST';
export const FETCH_ALL_CONTACTS_SUCCESS = 'fgwa/contacts/FETCH_ALL_CONTACTS_SUCCESS';
const FETCH_ALL_CONTACTS_FAILURE = 'fgwa/contacts/FETCH_ALL_CONTACTS_FAILURE';
const FETCH_CONTACT_REQUEST = 'fgwa/contacts/FETCH_CONTACT_REQUEST';
const FETCH_CONTACT_SUCCESS = 'fgwa/contacts/FETCH_CONTACT_SUCCESS';
const FETCH_CONTACT_FAILURE = 'fgwa/contacts/FETCH_CONTACT_FAILURE';
const SET_CONTACT_REQUEST = 'fgwa/contacts/SET_CONTACT_REQUEST';
const SET_CONTACT_SUCCESS = 'fgwa/contacts/SET_CONTACT_SUCCESS';
const SET_CONTACT_FAILURE = 'fgwa/contacts/SET_CONTACT_FAILURE';
const DELETE_CONTACT_REQUEST = 'fgwa/contacts/DELETE_CONTACT_REQUEST';
const DELETE_CONTACT_SUCCESS = 'fgwa/contacts/DELETE_CONTACT_SUCCESS';
const DELETE_CONTACT_FAILURE = 'fgwa/contacts/DELETE_CONTACT_FAILURE';

export const contactsActionsHandlers = {
  [FETCH_ALL_CONTACTS_REQUEST]: state => flow(
    set('loaded.contacts', false),
    set('loading.contacts', true),
  )(state),
  [FETCH_ALL_CONTACTS_SUCCESS]: (state, action) => flow(
    set('entities.contacts', action.response.entities.contacts || {}),
    set('loaded.contacts', true),
    set('loading.contacts', false),
  )(state),
  [FETCH_ALL_CONTACTS_FAILURE]: state => flow(
    set('loaded.contacts', false),
    set('loading.contacts', false),
  )(state),
  [FETCH_CONTACT_SUCCESS]: (state, action) => flow(
    set(`entities.contacts.${action.id}`, action.response.entities.contacts),
  )(state),
  [SET_CONTACT_SUCCESS]: (state, action) => flow(
    set('entities.contacts', {
      ...state.entities.contacts,
      ...action.response.entities.contacts,
    }),
  )(state),
  [DELETE_CONTACT_SUCCESS]: (state, action) => flow(
    set('entities.contacts', omit(state.entities.contacts, action.id)),
  )(state),
};

export function loadContact (id) {
  return {
    id,
    [CALL_API]: {
      types: [
        FETCH_CONTACT_REQUEST,
        FETCH_CONTACT_SUCCESS,
        FETCH_CONTACT_FAILURE,
      ],
      method: 'GET',
      endpoint: `/contacts/${id}`,
      schema: Schemas.CONTACT,
    },
  };
}

export function loadContacts () {
  return {
    [CALL_API]: {
      types: [
        FETCH_ALL_CONTACTS_REQUEST,
        FETCH_ALL_CONTACTS_SUCCESS,
        FETCH_ALL_CONTACTS_FAILURE,
      ],
      method: 'GET',
      endpoint: '/contacts',
      schema: Schemas.CONTACT_ARRAY,
    },
  };
}

export function createOrUpdateContact (contact) {
  return {
    [CALL_API]: {
      types: [
        SET_CONTACT_REQUEST,
        SET_CONTACT_SUCCESS,
        SET_CONTACT_FAILURE,
      ],
      method: !contact || !Number.isInteger(contact.contact_id) ? 'POST' : 'PUT',
      endpoint: !contact || !Number.isInteger(contact.contact_id) ? '/contacts' : `/contacts/${contact.contact_id}`,
      schema: Schemas.CONTACT,
      body: contact,
      successMessage: 'Contact enregistrée avec succès',
      successNext: loadAccounts,
    },
  };
}

export function deleteContact (id) {
  return {
    id,
    [CALL_API]: {
      types: [
        DELETE_CONTACT_REQUEST,
        DELETE_CONTACT_SUCCESS,
        DELETE_CONTACT_FAILURE,
      ],
      method: 'DELETE',
      endpoint: `/contacts/${id}`,
      successMessage: 'Contact supprimé avec succès',
    },
  };
}
