import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, NavLink } from 'react-router-dom';
import { map, split } from 'lodash';
import { connect } from 'react-redux';

import createSelector from '../../../selectors/ClientSubEntitiesSelector';

const clientSubEntitiesSelector = createSelector();

const PlansListView = ({
  history: { push }, documents, baseUrl,
}) => (
  <div className="col-3_sm-12 plans-list-block">
    <div className="box">
      <div className="box-header">
        <div className="box-title">
          Liste des plans
        </div>
      </div>
      <div className="box-content">
        <div className="plans-list">
          {map(documents, (document, documentId) => (
            <NavLink key={documentId} className="plan" to={`${split(baseUrl, 'plans')[0]}plans/${document.document_id}/detail`}>
              {document.documentName}
            </NavLink>
          ))}
        </div>
      </div>
    </div>
    <div className="box box-legend">
      <div className="box-header">
        <div className="box-title">
          Légende
        </div>
      </div>
      <div className="box-content">
        <div className="equipment-list">
          <div className="equipment">
            <div className="equipment-icon equipment-icon-note" />
            <div className="equipment-txt">
              <div className="equipment-txt-title">Point d&apos;attention</div>
            </div>
          </div>
          <div className="equipment">
            <div className="equipment-icon equipment-icon-DEIV" />
            <div className="equipment-txt">
              <div className="equipment-txt-title">Deiv</div>
            </div>
          </div>
          <div className="equipment">
            <div className="equipment-icon equipment-icon-baitStation" />
            <div className="equipment-txt">
              <div className="equipment-txt-title">Dératisation</div>
            </div>
          </div>
          <div className="equipment">
            <div className="equipment-icon equipment-icon-mothsTrap" />
            <div className="equipment-txt">
              <div className="equipment-txt-title">Teigne</div>
            </div>
          </div>
        </div>
        <div className="legend-separator" />
        <div className="conso-list">
          <div className="conso">
            <div className="conso-icon" />
            <div className="conso-title">Non consommé</div>
          </div>
          <div className="conso no-conso">
            <div className="conso-icon" />
            <div className="conso-title">Consommé</div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

PlansListView.propTypes = {
  documents: PropTypes.objectOf(PropTypes.shape({
    document_id: PropTypes.number.isRequired,
    documentName: PropTypes.string.isRequired,
    documentType: PropTypes.string.isRequired,
    documentURL: PropTypes.string.isRequired,
    documentCategory: PropTypes.number.isRequired,
    documentStartDate: PropTypes.string,
    documentEndDate: PropTypes.string,
    documentSite: PropTypes.number.isRequired,
  })).isRequired,

  baseUrl: PropTypes.string.isRequired,

  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect(
  (state, { clientId }) => ({
    categorys: state.data.entities.categorys,
    sites: clientSubEntitiesSelector(state.data.entities.sites, clientId, 'siteClient'),
  }),
)(withRouter(PlansListView));
