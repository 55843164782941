import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { NavLink } from 'react-router-dom';
import { ButtonConfirmModal } from 'react-components-linaia';

import createSelector from '../../selectors/ClientSubEntitiesSelector';

const clientSubEntitiesSelector = createSelector();

const ContractDetailView = ({
  contract = {}, baseUrl, onClickDelete, clients, sites,
}) => (
  <div>
    <div>
      Contract Id:&nbsp;
      {contract.contract_id}
    </div>
    <div>
      Contract Number:&nbsp;
      {contract.contractNumber}
    </div>
    <div>
      Contract Label:&nbsp;
      {contract.contractLabel}
    </div>
    <div>
      Contract Start Date:&nbsp;
      {contract.contractStartDate}
    </div>
    <div>
      Contract Endt Date:&nbsp;
      {contract.contractEndDate}
    </div>
    <div>
      Contract Nb Passage:&nbsp;
      {contract.contractNbPassage}
    </div>
    <div>
      Contract Type:&nbsp;
      {contract.contractType}
    </div>
    <div>
      Contract Client:&nbsp;
      {get(clients, `${contract.contractClient}.clientName`)}
    </div>
    <div>
      Contract Affected Site:&nbsp;
      {get(sites, `${contract.contractAffectedSite}.siteName`)}
    </div>

    <div className="btn-group right">
      <NavLink className="btn" to={`${baseUrl}/${contract.contract_id}/edit`}>
        Modifier
      </NavLink>
      {onClickDelete && (
        <ButtonConfirmModal
          onConfirmationButton={() => onClickDelete()}
          message="Voulez-vous vraiment supprimer ce contrat ?"
        >
          <button className="btn" type="button">Supprimer</button>
        </ButtonConfirmModal>
      )}
    </div>
  </div>
);

ContractDetailView.propTypes = {
  contract: PropTypes.shape({
    contract_id: PropTypes.number.isRequired,
    contractNumber: PropTypes.string.isRequired,
    contractLabel: PropTypes.string.isRequired,
    contractStartDate: PropTypes.string.isRequired,
    contractEndDate: PropTypes.string.isRequired,
    contractNbPassage: PropTypes.number.isRequired,
    contractType: PropTypes.string.isRequired,
    contractClient: PropTypes.number.isRequired,
    contractAffectedSite: PropTypes.number.isRequired,
  }).isRequired,

  clients: PropTypes.objectOf(PropTypes.shape()).isRequired,
  sites: PropTypes.objectOf(PropTypes.shape()).isRequired,

  baseUrl: PropTypes.string.isRequired,
  onClickDelete: PropTypes.func,
};

export default connect(
  (state, { clientId }) => ({
    clients: state.data.entities.clients,
    sites: clientSubEntitiesSelector(state.data.entities.sites, clientId, 'siteClient'),
  }),
)(ContractDetailView);
