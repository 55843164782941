import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, NavLink } from 'react-router-dom';
import { ButtonConfirmModal } from 'react-components-linaia';
import moment from 'moment';

import TableListView from '../shared/TableListView';

const ActualitysTableListView = ({
  history: { push }, actualitys, baseUrl, onClickDelete,
}) => (
  <TableListView
    className="table-boxed full-height"
    data={actualitys}
    cols={{
      actualityStartDate: {
        label: 'Début',
        className: 'col-0_sm-12 col-date',
        render: date => date && moment(date).format('DD/MM/YYYY'),
      },
      actualityEndDate: {
        label: 'Fin',
        className: 'col-0_sm-12 col-date',
        render: date => date && moment(date).format('DD/MM/YYYY'),
      },
      actualityTitle: {
        label: 'Actualité',
        className: 'col_sm-12',
        render: (article, actuality) => (
          <div className="grid-noBottom">
            <div className="col-12">
              <div className="td td-bold td-uncropping">
                {actuality.actualityTitle}
              </div>
            </div>
            <div className="col-12 ">
              <div className="td td-small actuality-resume td-uncropping">
                {actuality.actualitySummary}
              </div>
            </div>
          </div>
        ),
      },
    }}
    search={['actualityTitle', 'actualitySummary', 'actualityContent']}
    searchLabel="Rechercher une actualité"
    sortByDefault="actualityStartDate"
    sortOrderDefault="desc"
    buttons={(
      <div>
        <NavLink className="btn btn-main-mobile" to={`${baseUrl}/new`}>
          <span>Ajouter une actualité</span>
          <i className="far fa-plus" />
        </NavLink>
      </div>
    )}
    rowButtons={actuality => (
      <div className="col-0_sm-12 cln-2btn">
        <div className="td td-buttons">
          {actuality && (
            <NavLink className="btn" to={`${baseUrl}/${actuality.actuality_id}/edit`}>
              <i className="far fa-pencil" />
            </NavLink>
          )}
          {actuality && (
            <ButtonConfirmModal
              onConfirmationButton={() => onClickDelete(actuality.actuality_id)}
              message="Voulez-vous vraiment supprimer cette actualité ?"
            >
              <button className="btn" type="button">
                <i className="far fa-trash" />
              </button>
            </ButtonConfirmModal>
          )}
        </div>
      </div>
    )}
  />
);

ActualitysTableListView.propTypes = {
  actualitys: PropTypes.objectOf(PropTypes.shape({
    actuality_id: PropTypes.number.isRequired,
    actualityStartDate: PropTypes.string.isRequired,
    actualityEndDate: PropTypes.string,
    actualityTitle: PropTypes.string.isRequired,
    actualitySummary: PropTypes.string.isRequired,
    actualityContent: PropTypes.string.isRequired,
    actualityThumbnail: PropTypes.string,
    actualityFiles: PropTypes.arrayOf(PropTypes.string),
    actualityLinks: PropTypes.arrayOf(PropTypes.string),
  })).isRequired,

  baseUrl: PropTypes.string.isRequired,
  onClickDelete: PropTypes.func,

  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(ActualitysTableListView);
