import { flow, set } from 'lodash/fp';
import { omit } from 'lodash';

import { CALL_API, Schemas } from '../Schemas';

const FETCH_ALL_TECHNICIANS_REQUEST = 'fgwa/technicians/FETCH_ALL_TECHNICIANS_REQUEST';
export const FETCH_ALL_TECHNICIANS_SUCCESS = 'fgwa/technicians/FETCH_ALL_TECHNICIANS_SUCCESS';
const FETCH_ALL_TECHNICIANS_FAILURE = 'fgwa/technicians/FETCH_ALL_TECHNICIANS_FAILURE';
const FETCH_TECHNICIAN_REQUEST = 'fgwa/technicians/FETCH_TECHNICIAN_REQUEST';
const FETCH_TECHNICIAN_SUCCESS = 'fgwa/technicians/FETCH_TECHNICIAN_SUCCESS';
const FETCH_TECHNICIAN_FAILURE = 'fgwa/technicians/FETCH_TECHNICIAN_FAILURE';
const SET_TECHNICIAN_REQUEST = 'fgwa/technicians/SET_TECHNICIAN_REQUEST';
const SET_TECHNICIAN_SUCCESS = 'fgwa/technicians/SET_TECHNICIAN_SUCCESS';
const SET_TECHNICIAN_FAILURE = 'fgwa/technicians/SET_TECHNICIAN_FAILURE';
const DELETE_TECHNICIAN_REQUEST = 'fgwa/technicians/DELETE_TECHNICIAN_REQUEST';
const DELETE_TECHNICIAN_SUCCESS = 'fgwa/technicians/DELETE_TECHNICIAN_SUCCESS';
const DELETE_TECHNICIAN_FAILURE = 'fgwa/technicians/DELETE_TECHNICIAN_FAILURE';

export const techniciansActionsHandlers = {
  [FETCH_ALL_TECHNICIANS_REQUEST]: state => flow(
    set('loaded.technicians', false),
    set('loading.technicians', true),
  )(state),
  [FETCH_ALL_TECHNICIANS_SUCCESS]: (state, action) => flow(
    set('entities.technicians', action.response.entities.technicians || {}),
    set('loaded.technicians', true),
    set('loading.technicians', false),
  )(state),
  [FETCH_ALL_TECHNICIANS_FAILURE]: state => flow(
    set('loaded.technicians', false),
    set('loading.technicians', false),
  )(state),
  [FETCH_TECHNICIAN_SUCCESS]: (state, action) => flow(
    set(`entities.technicians.${action.id}`, action.response.entities.technicians),
  )(state),
  [SET_TECHNICIAN_SUCCESS]: (state, action) => flow(
    set('entities.technicians', {
      ...state.entities.technicians,
      ...action.response.entities.technicians,
    }),
  )(state),
  [DELETE_TECHNICIAN_SUCCESS]: (state, action) => flow(
    set('entities.technicians', omit(state.entities.technicians, action.id)),
  )(state),
};

export function loadTechnician (id) {
  return {
    id,
    [CALL_API]: {
      types: [
        FETCH_TECHNICIAN_REQUEST,
        FETCH_TECHNICIAN_SUCCESS,
        FETCH_TECHNICIAN_FAILURE,
      ],
      method: 'GET',
      endpoint: `/technicians/${id}`,
      schema: Schemas.TECHNICIAN,
    },
  };
}

export function loadTechnicians () {
  return {
    [CALL_API]: {
      types: [
        FETCH_ALL_TECHNICIANS_REQUEST,
        FETCH_ALL_TECHNICIANS_SUCCESS,
        FETCH_ALL_TECHNICIANS_FAILURE,
      ],
      method: 'GET',
      endpoint: '/technicians',
      schema: Schemas.TECHNICIAN_ARRAY,
    },
  };
}

export function createOrUpdateTechnician (technician) {
  return {
    [CALL_API]: {
      types: [
        SET_TECHNICIAN_REQUEST,
        SET_TECHNICIAN_SUCCESS,
        SET_TECHNICIAN_FAILURE,
      ],
      method: !technician || !Number.isInteger(technician.technician_id) ? 'POST' : 'PUT',
      endpoint: !technician || !Number.isInteger(technician.technician_id) ? '/technicians' : `/technicians/${technician.technician_id}`,
      schema: Schemas.TECHNICIAN,
      body: technician,
      successMessage: 'Technicien enregistré avec succès',
    },
  };
}

export function deleteTechnician (id) {
  return {
    id,
    [CALL_API]: {
      types: [
        DELETE_TECHNICIAN_REQUEST,
        DELETE_TECHNICIAN_SUCCESS,
        DELETE_TECHNICIAN_FAILURE,
      ],
      method: 'DELETE',
      endpoint: `/technicians/${id}`,
      successMessage: 'Technicien supprimé avec succès',
    },
  };
}
