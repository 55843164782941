import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import {
  Field, InputField, SelectField,
} from 'react-components-linaia';

import { ACCOUNTS_ROLES } from '../../constants/Properties';

const AccountEditForm = ({
  handleSubmit, initialValues, isMyAccount, createOnlyAdmin, baseUrl,
}) => (
  <Fragment>
    <div className="modal-title">
      <div className="modal-title-content">
        {initialValues.account_id >= 0 ? 'Modifier l\'administrateur' : 'Ajouter un administrateur'}
        <NavLink className="btn" to={baseUrl}>
          <i className="fal fa-times" />
        </NavLink>
      </div>
    </div>
    <form onSubmit={handleSubmit}>
      <div className="modal-body">
        <div className="grid">
          <Field name="username" component={InputField} placeholder="Prénom Nom" label="Identité" disabled={isMyAccount} autoComplete={false} className="col-12"/>
          <Field name="email" component={InputField} placeholder="Email" label="Email" type="email" autoComplete={false} className="col-12"/>
          {!isMyAccount && !createOnlyAdmin && (
            <Field name="roles" component={SelectField} placeholder="Rôles" label="Rôles" options={ACCOUNTS_ROLES} isMulti className="col-12"/>
          )}
          {isMyAccount && (
            <Field name="password" component={InputField} placeholder="Mot de passe" label="Mot de passe" type="password" className="col-12"/>
          )}
        </div>
      </div>
      <div className="modal-footer">
        <div className="btn-group center">
          <button className="btn btn-primary" type="submit">
            Enregistrer
          </button>
        </div>
      </div>
    </form>
  </Fragment>
);

AccountEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
  initialValues: PropTypes.shape().isRequired,

  isMyAccount: PropTypes.bool.isRequired,
  createOnlyAdmin: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'AccountEditForm',
  validate: (values = {}) => {
    const errors = {};

    if (!values.username) errors.username = 'Merci de renseigner l’identifiant de l’administrateur';
    if (!values.email) errors.email = 'Merci de renseigner le mot de passe de l’administrateur';

    return errors;
  },
})(AccountEditForm);
