import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter, NavLink } from 'react-router-dom';
import { map, split } from 'lodash';

const SubNavLink = ({ documentId, url, title, setRef }) => (
  <li
    ref={ref => (setRef(ref))}
  >
    <NavLink
      to={`${split(url, '/contract')[0]}/contract/${documentId}/plan`}
      activeClassName="active"
    >
      <span>{title}</span>
    </NavLink>
  </li>
);

SubNavLink.propTypes = {
  documentId: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  setRef: PropTypes.func.isRequired,
};

const TechniciansAdminSitesHeader = ({
  baseUrl, history: { push }, documents, location: { pathname },
}) => {
  const [nav, setNavref] = useState();
  const [products, setProductsRef] = useState();

  const activeName = pathname.replace(`${baseUrl}/`, '');
  switch (activeName) {
  default:
    if (products && products.offsetLeft && nav) {
      nav.scrollTo({ top: 0, left: (products.offsetLeft - (nav.offsetWidth / 2) + (products.offsetWidth / 2)), behavior: 'smooth' });
    }
    break;
  }
  return (
    <div className="col-12">
      <div className="submenu-wrapper">
        { documents !== undefined ? (
          <nav ref={ref => setNavref(ref)} className="submenu">
            <ul>
              {map(documents, doc => (
                <SubNavLink
                  key={doc.document_id}
                  setRef={setProductsRef}
                  documentId={doc.document_id}
                  url={`${baseUrl}`}
                  title={doc.documentName}
                />
              ))}
            </ul>
          </nav>
        ) : (
          <div>
            Aucun plan disponible pour ce site
          </div>
        )}

      </div>
    </div>
  );
};

TechniciansAdminSitesHeader.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  documents: PropTypes.objectOf(PropTypes.shape()),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(TechniciansAdminSitesHeader);
