import React from "react";
import PropTypes from "prop-types";
import { withRouter, NavLink } from "react-router-dom";
import { capitalize, get, map } from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import { ButtonConfirmModal } from "react-components-linaia";

import createSelector from "../../selectors/ClientSubEntitiesSelector";

import TableListView from "../shared/TableListView";

import { UPLOADS_URL } from "../../constants/Config";
import { isSupport } from "../../utils/RolesUtils";

import { refreshSitesDocuments } from "../../store/modules/sites";

const clientSubEntitiesSelector = createSelector();

const getTitle = (documentCategory, categorys) => {
  if (documentCategory > 4) {
    return categorys[documentCategory].categoryName;
  }
  switch (documentCategory) {
    case 0:
      return "Liste des devis";
    default:
      return null;
  }
};

const getSearchLabel = (documentCategory) => {
  switch (documentCategory) {
    case 0:
      return "Rechercher un devis";
    default:
      return "Rechercher un document";
  }
};

const getDocumentNameLabel = (documentCategory) => {
  switch (documentCategory) {
    case 0:
      return "Numéro du devis";
    default:
      return "Nom du document";
  }
};

const DocumentsTableListView = ({
  history: { push },
  documents,
  documentCategory,
  baseUrl,
  onClickDelete,
  categorys,
  sites,
  isAdmin,
  refreshSitesDocuments,
  user,
  client_id,
}) => (
  <TableListView
    title={getTitle(documentCategory, categorys)}
    className="table-boxed full-height"
    data={documents}
    cols={
      isAdmin
        ? {
            documentName: {
              label: "Document",
              className: "col-4_sm-12",
              render: (docName, entity) => (
                <a
                  href={
                    entity.documentType === "lien"
                      ? `${entity.documentURL}`
                      : `${UPLOADS_URL}${entity.documentURL}`
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {docName}
                </a>
              ),
              value: capitalize,
            },

            documentCategory: {
              label: "Catégorie",
              className: "col-2_sm-12",
              value: (id) => get(categorys, `${id}.categoryName`),
            },
            documentStartDate: {
              label: "Début",
              className: "col-0_sm-12 col-date",
              render: (date) => date && moment(date).format("DD/MM/YYYY"),
            },
            documentEndDate: {
              label: "Fin",
              className: "col-0_sm-12 col-date",
              render: (date) => date && moment(date).format("DD/MM/YYYY"),
            },
            documentSite: {
              label: "Site",
              className: "col_sm-12",
              value: (id) => get(sites, `${id}.siteName`),
            },
          }
        : {
            documentName: {
              label: getDocumentNameLabel(documentCategory),
              className: "col-3_sm-12",
              render: capitalize,
            },
            documentURL: {
              label: "Lien",
              className: "col_sm-12",
              render: (img, { documentType }) => (
                <a
                  href={documentType === "lien" ? `${img}` : `${UPLOADS_URL}${img}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {img}
                </a>
              ),
            },
          }
    }
    search={["documentName", "documentSite", "documentURL"]}
    searchLabel={getSearchLabel(documentCategory)}
    sortByDefault="documentStartDate"
    sortOrderDefault="desc"
    buttonsRefresh={
      isAdmin &&
      isSupport(user) && (
        <button
          type="button"
          className="btn btn-main-mobile"
          onClick={() =>
            refreshSitesDocuments(
              client_id,
              map(sites, (site) => site.site_id)
            )
          }
        >
          <span>Refresh les documents des sites</span>
          <i className="far fa-sync" />
        </button>
      )
    }
    buttons={
      isAdmin && (
        <div>
          <NavLink className="btn btn-main-mobile" to={`${baseUrl}/new`}>
            <span>Ajouter un document</span>
            <i className="far fa-plus" />
          </NavLink>
        </div>
      )
    }
    rowButtons={(document) =>
      isAdmin && (
        <div className="col-0_sm-12 cln-2btn">
          <div className="td td-buttons">
            {document && (
              <NavLink className="btn" to={`${baseUrl}/${document.document_id}/edit`}>
                <i className="far fa-pencil" />
              </NavLink>
            )}
            {document && (
              <ButtonConfirmModal
                onConfirmationButton={() => onClickDelete(document.document_id)}
                message="Voulez-vous vraiment supprimer ce document ?"
              >
                <button className="btn" type="button">
                  <i className="far fa-trash" />
                </button>
              </ButtonConfirmModal>
            )}
          </div>
        </div>
      )
    }
  />
);

DocumentsTableListView.propTypes = {
  documents: PropTypes.objectOf(
    PropTypes.shape({
      document_id: PropTypes.number.isRequired,
      documentName: PropTypes.string.isRequired,
      documentType: PropTypes.string.isRequired,
      documentURL: PropTypes.string.isRequired,
      documentCategory: PropTypes.number.isRequired,
      documentStartDate: PropTypes.string,
      documentEndDate: PropTypes.string,
      documentSite: PropTypes.number.isRequired,
    })
  ).isRequired,
  documentCategory: PropTypes.number,

  isAdmin: PropTypes.bool,

  categorys: PropTypes.objectOf(PropTypes.shape()).isRequired,
  sites: PropTypes.objectOf(PropTypes.shape()).isRequired,

  baseUrl: PropTypes.string.isRequired,
  onClickDelete: PropTypes.func,

  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect(
  (state, { clientId }) => ({
    categorys: state.data.entities.categorys,
    user: state.auth.user,
    sites: clientSubEntitiesSelector(state.data.entities.sites, clientId, "siteClient"),
    client_id: clientId,
  }),
  {
    refreshSitesDocuments,
  }
)(withRouter(DocumentsTableListView));
