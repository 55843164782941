import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { withRouter, Switch, Route } from 'react-router-dom';
import { get } from 'lodash';

import {
  createOrUpdateTechnician,
  deleteTechnician,
} from '../../store/modules/technicians';

import BaseLayout from '../shared/BaseLayout';

import TechnicianDetailView from '../../components/technicians/TechnicianDetailView';
import TechnicianEditForm from '../../components/technicians/TechnicianEditForm';
import TechniciansCardListView from '../../components/technicians/TechniciansCardListView';

import modalDefaultClass from '../../utils/ModalDefaultClass';

const RenderEditDetailsModalsRouter = ({
  url,
  editTechnician,
  delTechnician,
  push,
  technicians,
}) => (
  <Switch>
    <Route
      exact
      path={`${url}/new`}
      render={() => (
        <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
          <TechnicianEditForm
            initialValues={{ technicianAdmin: false }}
            onSubmit={v => {
              editTechnician(v).then(() => push(url));
            }}
            baseUrl={url}
          />
        </Modal>
      )}
    />
    <Route
      exact
      path={`${url}/:technicianId/edit`}
      render={subProps =>
        get(technicians, subProps.match.params.technicianId, null) && (
          <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
            <TechnicianEditForm
              initialValues={{
                ...technicians[subProps.match.params.technicianId],
                technicianAdmin: technicians[subProps.match.params.technicianId]
                  .technicianAdmin
                  ? technicians[subProps.match.params.technicianId]
                      .technicianAdmin
                  : false,
              }}
              onSubmit={v => {
                editTechnician(v).then(() => push(url));
              }}
              baseUrl={url}
            />
          </Modal>
        )
      }
    />
    <Route
      exact
      path={`${url}/:technicianId`}
      render={subProps =>
        get(technicians, subProps.match.params.technicianId, null) && (
          <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
            <TechnicianDetailView
              technician={technicians[subProps.match.params.technicianId]}
              baseUrl={url}
              onClickDelete={() => {
                delTechnician(subProps.match.params.technicianId).then(() =>
                  push(url),
                );
              }}
            />
          </Modal>
        )
      }
    />
  </Switch>
);

RenderEditDetailsModalsRouter.propTypes = {
  url: PropTypes.string.isRequired,
  editTechnician: PropTypes.func.isRequired,
  delTechnician: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  technicians: PropTypes.objectOf(PropTypes.shape()).isRequired,
};

const TechniciansContainer = ({
  match: { url },
  history: { push },
  technicians,
  ...props
}) => (
  <BaseLayout title="Techniciens">
    <Route
      path={url}
      render={({ match }) => (
        <Fragment>
          <TechniciansCardListView
            technicians={technicians}
            baseUrl={match.url}
            onClickDelete={technicianId => {
              props.deleteTechnician(technicianId).then(() => push(match.url));
            }}
          />
          <RenderEditDetailsModalsRouter
            url={match.url}
            editTechnician={props.createOrUpdateTechnician}
            delTechnician={props.deleteTechnician}
            push={push}
            technicians={technicians}
          />
        </Fragment>
      )}
    />
  </BaseLayout>
);

TechniciansContainer.propTypes = {
  createOrUpdateTechnician: PropTypes.func.isRequired,
  deleteTechnician: PropTypes.func.isRequired,

  technicians: PropTypes.objectOf(PropTypes.shape()).isRequired,

  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect(
  state => ({
    technicians: state.data.entities.technicians,
  }),
  { createOrUpdateTechnician, deleteTechnician },
)(withRouter(TechniciansContainer));
