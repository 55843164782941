import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { get, find, parseInt } from 'lodash';
import { withRouter, Route } from 'react-router-dom';

import modalDefaultClass from '../../utils/ModalDefaultClass';
import ActualityDetailModalView from '../../components/actualitys/ActualityDetailModalView';

import ClientHomeComponent from '../../components/home/ClientHomeComponent';

const ClientHomeContainer = ({
  username, match: { url }, history: { push }, welcomeMessage, actualitys,
}) => (
  <Fragment>
    <ClientHomeComponent
      username={username}
      welcomeMessage={welcomeMessage}
      baseUrl={url}
    />
    <Route
      exact
      path={`${url}/:actualityId/detail`}
      render={(subProps) => {
        const initValue = find(actualitys, { actuality_id: parseInt(subProps.match.params.actualityId, 10) });
        return (initValue && (
          <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)} className="modal-content medium-modal">
            <ActualityDetailModalView
              baseUrl={url}
              actuality={initValue}
            />
          </Modal>
        ));
      }}
    />
  </Fragment>
);

ClientHomeContainer.propTypes = {
  username: PropTypes.string.isRequired,
  welcomeMessage: PropTypes.string.isRequired,

  actualitys: PropTypes.objectOf(PropTypes.shape()),

  match: PropTypes.shape({
    url: PropTypes.string,
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect(
  state => ({
    username: state.auth.user.username,
    welcomeMessage: state.data.entities.options.welcomeMessage,
    actualitys: state.data.entities.actualitys,
  }),
)(withRouter(ClientHomeContainer));
