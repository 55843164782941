import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Modal from "react-modal";
import { withRouter, Switch, Route } from "react-router-dom";
import { get } from "lodash";

import createSelector from "../../selectors/ClientSubEntitiesSelector";

import { createOrUpdateBill, deleteBill } from "../../store/modules/bills";

import BillDetailView from "../../components/bills/BillDetailView";
import BillEditForm from "../../components/bills/BillEditForm";
import BillsTableListView from "../../components/bills/BillsTableListView";

import modalDefaultClass from "../../utils/ModalDefaultClass";

const clientSubEntitiesSelector = createSelector();

const RenderEditDetailsModalsRouter = ({
  url,
  editBill,
  delBill,
  push,
  bills,
  clientId
}) => (
  <Switch>
    <Route
      exact
      path={`${url}/new`}
      render={() => (
        <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
          <BillEditForm
            initialValues={{
              factureClient: clientId
            }}
            onSubmit={v => {
              editBill(v).then(() => push(url));
            }}
            baseUrl={url}
          />
        </Modal>
      )}
    />
    <Route
      exact
      path={`${url}/:billId/edit`}
      render={subProps =>
        get(bills, subProps.match.params.billId, null) && (
          <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
            <BillEditForm
              initialValues={bills[subProps.match.params.billId]}
              onSubmit={v => {
                editBill(v).then(() => push(url));
              }}
              baseUrl={url}
            />
          </Modal>
        )
      }
    />
    <Route
      exact
      path={`${url}/:billId`}
      render={subProps =>
        get(bills, subProps.match.params.billId, null) && (
          <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
            <BillDetailView
              bill={bills[subProps.match.params.billId]}
              baseUrl={url}
              onClickDelete={() => {
                delBill(subProps.match.params.billId).then(() => push(url));
              }}
            />
          </Modal>
        )
      }
    />
  </Switch>
);

RenderEditDetailsModalsRouter.propTypes = {
  url: PropTypes.string.isRequired,
  editBill: PropTypes.func.isRequired,
  delBill: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  bills: PropTypes.objectOf(PropTypes.shape()).isRequired,
  clientId: PropTypes.number.isRequired
};

const BillsContainer = ({
  clientId,
  isAdmin,
  match: { url },
  history: { push },
  bills,
  ...props
}) => (
  <Fragment>
    <BillsTableListView
      bills={bills}
      baseUrl={url}
      clientId={clientId}
      onClickDelete={billId => {
        props.deleteBill(billId).then(() => push(url));
      }}
      isAdmin={isAdmin}
    />
    <RenderEditDetailsModalsRouter
      url={url}
      editBill={props.createOrUpdateBill}
      delBill={props.deleteBill}
      push={push}
      bills={bills}
      clientId={clientId}
    />
  </Fragment>
);

BillsContainer.propTypes = {
  clientId: PropTypes.number.isRequired,
  isAdmin: PropTypes.bool,

  createOrUpdateBill: PropTypes.func.isRequired,
  deleteBill: PropTypes.func.isRequired,

  bills: PropTypes.objectOf(PropTypes.shape()).isRequired,

  match: PropTypes.shape({
    url: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default connect(
  (state, { clientId }) => ({
    bills: clientSubEntitiesSelector(
      state.data.entities.bills,
      clientId,
      "factureClient"
    )
  }),
  { createOrUpdateBill, deleteBill }
)(withRouter(BillsContainer));
