import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { NavLink } from 'react-router-dom';
import { indexOf } from 'lodash';
import {
  Field, InputField, SelectField,
} from 'react-components-linaia';

import { EQUIPMENT_LOCATION } from '../../constants/Properties';

const EquipmentAddForm = ({
  handleSubmit, baseUrl, initialValues, onClickDelete, planId, loadingEquipments,
}) => (
  <Fragment>
    <div className="modal-title">
      <div className="modal-title-content">
        {(initialValues !== undefined && initialValues.equipmentPlan && indexOf(initialValues.equipmentPlan, planId) !== -1) ? 'Modifier l\'équipement dans le plan' : 'Ajouter l\'équipement dans le plan'}
        <NavLink className="btn" to={baseUrl}>
          <i className="fal fa-times" />
        </NavLink>
      </div>
    </div>
    <form onSubmit={handleSubmit}>
      <div className="modal-body">
        <div className="grid">
          {initialValues.equipmentType !== 'remarqueLibre' && (
            <Field
              name="equipmentPosition"
              component={SelectField}
              options={EQUIPMENT_LOCATION}
              label="Emplacement de l'équipement"
              className="col-12"
            />
          )}
          <Field name="equipmentNote" component={InputField} label="Remarque" className="col-12" />
        </div>
      </div>
      <div className="modal-footer">
        <div className="btn-group center">
          <button className="btn btn-primary" type="submit">
            Enregistrer
          </button>
          { !loadingEquipments
            && indexOf(initialValues.equipmentPlan, planId) !== -1
            && (
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => onClickDelete(initialValues)}
              >
                Supprimer
              </button>
            )
          }
        </div>
      </div>
    </form>
  </Fragment>
);

EquipmentAddForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
  planId: PropTypes.number,
  initialValues: PropTypes.shape().isRequired,
  onClickDelete: PropTypes.func.isRequired,
  loadingEquipments: PropTypes.bool,
};

export default reduxForm({
  form: 'EquipmentAddForm',
  validate: (values = {}) => {
    const errors = {};
    if (!values.equipmentPosition) errors.equipmentPosition = 'Merci de renseigner l\'emplacement de l\'équipement';
    if (!values.equipmentNote) errors.equipmentPostNumber = 'Merci de renseigner le numéro du poste de l\'équipement';

    return errors;
  },
})(connect(
  state => ({
    loadingEquipments: state.data.loading.equipments,
  }),
)(EquipmentAddForm));
