import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { ButtonConfirmModal } from 'react-components-linaia';

import CardListView from '../shared/CardListView';

import { UPLOADS_URL } from '../../constants/Config';

import clientSitePhoto from '../../../assets/images/ui/client-site-photo.jpg';
import defaultTechnicianPhoto from '../../../assets/images/ui/user-profil.png';

const SitesCardListView = ({ sites, baseUrl, onClickDelete, technicians }) => (
  <CardListView
    className="card-list-sites"
    data={sites}
    cardComponent={site => {
      const tech = get(technicians, site.siteTechnician, {});
      return (
        <div className="col-3_xs-12_sm-6_lg-4">
          <div className="box card simple-card picture-bg">
            <div className="card-header-picture">
              {site.sitePhotoUrl ? (
                <img
                  src={`${UPLOADS_URL}/${site.sitePhotoUrl}`}
                  alt={site.siteName}
                />
              ) : (
                <img src={clientSitePhoto} alt={site.siteName} />
              )}
            </div>
            <div className="box-content">
              <div className="card-overlay">
                <div className="card-overlay-content">
                  <div className="grid-noBottom">
                    {site.siteName && (
                      <div className="col-12">
                        <div className="card-title">{site.siteName}</div>
                      </div>
                    )}
                    {site.siteAddress1 && (
                      <div className="col-12">
                        <span className="iconed-data icon-top">
                          <i className="fas fa-map-marker-alt" />
                          <span>
                            {site.siteAddress1}
                            {site.siteAddress2 && <br />}
                            {site.siteAddress2}
                            {site.siteAddress3 && <br />}
                            {site.siteAddress3}
                            {site.sitePostalCode && <br />}
                            {site.sitePostalCode} {site.siteCity}
                          </span>
                        </span>
                      </div>
                    )}
                    {site.sitePhone1 && (
                      <div className="col-12">
                        <div className="btn-group">
                          <a href={`tel:${site.sitePhone1}`}>
                            <i className="fas fa-phone" />
                            <span>{site.sitePhone1}</span>
                          </a>
                        </div>
                      </div>
                    )}
                    {site.sitePhone2 && (
                      <div className="col-12">
                        <div className="btn-group">
                          <a href={`tel:${site.sitePhone2}`}>
                            <i className="fas fa-phone" />
                            <span>{site.sitePhone2}</span>
                          </a>
                        </div>
                      </div>
                    )}
                    {site.siteEmail && (
                      <div className="col-12">
                        <div className="btn-group">
                          <a href={`mailto:${site.siteEmail}`}>
                            <i className="fas fa-envelope" />
                            <span>{site.siteEmail}</span>
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="box-separator" />
                  <div className="grid-noBottom">
                    <div className="col-3">
                      {tech.technicianPhotoUrl ? (
                        <img
                          src={`${UPLOADS_URL}/${tech.technicianPhotoUrl}`}
                          alt={`${tech.technicianFirstname} ${tech.technicianLastname}`}
                        />
                      ) : (
                        <img
                          src={defaultTechnicianPhoto}
                          alt={`${tech.technicianFirstname} ${tech.technicianLastname}`}
                        />
                      )}
                    </div>
                    <div className="col">
                      <div className="grid-noBottom">
                        <div className="col-12">
                          <div className="card-title">
                            {tech.technicianFirstname} {tech.technicianLastname}
                          </div>
                        </div>
                        {tech.technicianPhone && (
                          <div className="col-12">
                            <div className="btn-group">
                              <a href={`tel:${tech.technicianPhone}`}>
                                <i className="fas fa-phone" />
                                <span>{tech.technicianPhone}</span>
                              </a>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="grid-noBottom">
                        {tech.technicianEmail && (
                          <div className="col-12">
                            <div className="btn-group">
                              <a href={`mailto:${tech.technicianEmail}`}>
                                <i className="fas fa-envelope" />
                                <span>{tech.technicianEmail}</span>
                              </a>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-overlay-mask">
                  <div />
                </div>
              </div>
              <div className="card-actions btn-group">
                <NavLink to={`${baseUrl}/${site.site_id}/edit`} className="btn">
                  <i className="far fa-pencil" />
                  <span>Modifier</span>
                </NavLink>
                {onClickDelete && !site.siteIdExterne && (
                  <ButtonConfirmModal
                    onConfirmationButton={() => onClickDelete(site.site_id)}
                    message="Voulez-vous vraiment supprimer ce site ?"
                  >
                    <button className="btn" type="button">
                      <i className="far fa-trash" />
                      <span>Supprimer</span>
                    </button>
                  </ButtonConfirmModal>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }}
    sortByDefault="siteName"
    search={['siteName']}
    searchLabel="Rechercher un site"
    buttons={
      <div>
        <NavLink className="btn btn-main-mobile" to={`${baseUrl}/new`}>
          <span>Ajouter un site</span>
          <i className="far fa-plus" />
        </NavLink>
      </div>
    }
  />
);

SitesCardListView.propTypes = {
  sites: PropTypes.objectOf(
    PropTypes.shape({
      site_id: PropTypes.number.isRequired,
      siteName: PropTypes.string.isRequired,
      sitePhone1: PropTypes.string.isRequired,
      sitePhone2: PropTypes.string,
      siteEmail: PropTypes.string.isRequired,
      siteAddress1: PropTypes.string.isRequired,
      siteAddress2: PropTypes.string,
      siteAddress3: PropTypes.string,
      sitePostalCode: PropTypes.string.isRequired,
      siteCity: PropTypes.string.isRequired,
      sitePhotoUrl: PropTypes.string,
      siteClient: PropTypes.number.isRequired,
      siteTechnician: PropTypes.number.isRequired,
    }),
  ).isRequired,

  technicians: PropTypes.objectOf(PropTypes.shape()).isRequired,

  baseUrl: PropTypes.string.isRequired,
  onClickDelete: PropTypes.func,
};

export default connect(state => ({
  technicians: state.data.entities.technicians,
}))(SitesCardListView);
