import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm, change } from "redux-form";
import { NavLink } from "react-router-dom";
import { get, isNumber, map, values, pickBy, eq, find } from "lodash";
import {
  Field,
  ArrayField,
  DateField,
  InputField,
  SelectField,
  ToggleField,
  RadioField,
} from "react-components-linaia";
import buildOptionsFromContracts from "../options/OptionsContracts";
import { PASSAGE_STATE_DERATISATION } from "../../constants/Properties";

import createSelector from "../../selectors/ClientSubEntitiesSelector";

const clientSitesSelector = createSelector();
const siteContractsSelector = createSelector();
const clientEquipmentsSelector = createSelector();

const PassageEditForm = ({
  handleSubmit,
  baseUrl,
  initialValues,
  sites,
  contracts,
  technicians,
  equipments,
  passageContract,
  passageEquipments,
  passageSite,
  passageTechnicien,
  setValue,
}) => {
  const [site, setSite] = useState(passageSite);
  if (site !== passageSite) setSite(passageSite);
  useEffect(() => {
    if (isNumber(passageSite))
      setValue("passageTechnicien", get(sites, `${passageSite}.siteTechnician`));
  }, [site]);
  passageEquipments = passageEquipments.split(";");
  equipments = pickBy(equipments, (eq) => eq.equipmentType !== "remarqueLibre");
  if (values(contracts).length === 0 && passageContract !== null) {
    setValue("passageContract", null);
    setValue("passageEquipments", null);
    return null;
  }
  if (values(contracts).length === 1 && passageContract !== values(contracts)[0].contract_id) {
    setValue("passageContract", values(contracts)[0].contract_id);
    return null;
  }
  // detruis ce qui a dans la base de donnée
  // a quoi ca sert ??????
  // a recreer des trucs vides si y'a des nouveaux passages.
  // if (
  //   isNumber(passageContract) &&
  //   (passageEquipments.length !== values(equipments).length ||
  //     (values(equipments).length > 0 &&
  //       values(equipments)[0].equipment_id !== parseInt(passageEquipments[0], 10)))
  // ) {
  //   console.log("on est dans le if !");
  //   // setValue(
  //   //   "passageEquipments",
  //     map(equipments, (e) => ({
  //       equipment:
  //         find(
  //           initialValues.passageEquipments,
  //           (equipmentPassage) => equipmentPassage.equipment_id === e.equipement_id
  //         ) || e.equipment_id,
  //     }))
  //   // );
  //   // return null;
  // }
  return (
    <Fragment>
      <div className="modal-title">
        <div className="modal-title-content">
          {initialValues.passage_id >= 0 ? "Modifier le passage" : "Ajouter un passage"}
          <NavLink className="btn" to={baseUrl}>
            <i className="fal fa-times" />
          </NavLink>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="modal-body">
          <div className="grid-noBottom">
            <div className="col-12">
              <div className="grid-noBottom">
                <Field
                  name="passageDate"
                  component={DateField}
                  label="Date"
                  className="col-date"
                  displayFormat="dd/MM/yyyy"
                />
                <Field
                  name="passageSite"
                  component={SelectField}
                  options={sites}
                  optionsKeys={{ value: "site_id", label: "siteName" }}
                  label="Site"
                  className="col"
                />
                <Field
                  name="passageContract"
                  component={SelectField}
                  options={buildOptionsFromContracts(contracts)}
                  // optionsKeys={{ value: 'contract_id', label: 'contractLabel' }}
                  label="Contrat"
                  className="col_xs-12"
                />
                <Field
                  name="passageTechnicien"
                  component={SelectField}
                  options={map(technicians, (tech) => ({
                    value: tech.technician_id,
                    label: `${tech.technicianFirstname} ${tech.technicianLastname}`,
                  }))}
                  label="Technicien"
                  className="col_xs-12"
                />
              </div>
              <div className="box-separator" />
            </div>
            <ArrayField
              name="passageEquipments"
              component={({ field, index }) => {
                const equipment = get(equipments, passageEquipments[index], {});
                return (
                  <Fragment>
                    <div className="grid-noBottom">
                      <div className="col-12">
                        {equipment.equipmentPostNumber}
                        &nbsp;-&nbsp;
                        {equipment.equipmentName}
                        &nbsp;-&nbsp;
                        {equipment.equipmentLocation}
                      </div>
                      {equipment.equipmentType === "baitStation" && (
                        <Field
                          name={`${field}.equipmentState`}
                          component={RadioField}
                          options={PASSAGE_STATE_DERATISATION}
                          placeholder="État"
                          className="col-12"
                        />
                      )}
                      {equipment.equipmentType === "DEIV" && (
                        <Field
                          name={`${field}.equipmentState.nbTotal`}
                          component={InputField}
                          type="number"
                          placeholder="Nombre"
                          className="col-12"
                        />
                      )}
                      {equipment.equipmentType === "mothsTrap" && (
                        <Fragment>
                          <Field
                            name={`${field}.equipmentState`}
                            component={InputField}
                            type="number"
                            placeholder="Nombre"
                            className="col-12"
                          />
                          <Field
                            name={`${field}.equipmentReplaced`}
                            component={ToggleField}
                            label="Equipement remplacé"
                            className="col-12"
                            fieldClassName="checkbox"
                          />
                        </Fragment>
                      )}
                    </div>
                    <div className="box-separator" />
                  </Fragment>
                );
              }}
              placeholder="État des équipements"
              className="col-12"
            />
          </div>
        </div>
        <div className="modal-footer">
          <div className="btn-group center">
            <button className="btn btn-primary" type="submit">
              Enregistrer
            </button>
          </div>
        </div>
      </form>
    </Fragment>
  );
};

PassageEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
  initialValues: PropTypes.shape().isRequired,

  sites: PropTypes.objectOf(PropTypes.shape()).isRequired,
  contracts: PropTypes.objectOf(PropTypes.shape()).isRequired,
  technicians: PropTypes.objectOf(PropTypes.shape()).isRequired,
  equipments: PropTypes.objectOf(PropTypes.shape()).isRequired,
  passageContract: PropTypes.number,
  passageEquipments: PropTypes.string,
  passageTechnicien: PropTypes.number,
  passageSite: PropTypes.number,

  setValue: PropTypes.func.isRequired,
};

export default reduxForm({
  form: "PassageEditForm",
  validate: (values = {}) => {
    const errors = {};

    if (!values.passageDate) errors.passageDate = "Merci de renseigner la date du passage";
    if (!isNumber(values.passageSite))
      errors.passageSite = "Merci de renseigner le site du passage";
    if (!isNumber(values.passageContract))
      errors.passageContract = "Merci de renseigner le contrat du passage";
    if (!isNumber(values.passageTechnicien))
      errors.passageTechnicien = "Merci de renseigner le technicien du passage";
    if (!isNumber(values.passageEquipments)) errors.passageEquipments = "Must be set";

    return errors;
  },
})(
  connect(
    (state, { clientId }) => ({
      sites: clientSitesSelector(state.data.entities.sites, clientId, "siteClient"),
      contracts: siteContractsSelector(
        state.data.entities.contracts,
        get(state, "form.PassageEditForm.values.passageSite"),
        "contractAffectedSite"
      ),
      technicians: state.data.entities.technicians,
      equipments: clientEquipmentsSelector(
        state.data.entities.equipments,
        get(state, "form.PassageEditForm.values.passageContract"),
        "equipmentContract"
      ),
      passageContract: get(state, "form.PassageEditForm.values.passageContract"),
      passageEquipments: map(
        get(state, "form.PassageEditForm.values.passageEquipments"),
        (e) => e.equipment
      ).join(";"),
      passageTechnicien: get(state, "form.PassageEditForm.values.passageTechnicien"),
      passageSite: get(state, "form.PassageEditForm.values.passageSite"),
    }),
    {
      setValue: (field, value) => change("PassageEditForm", field, value),
    }
  )(PassageEditForm)
);
