import { createSelector } from 'reselect';
import {
  pickBy, get,
} from 'lodash';

export default () => createSelector(
  [
    data => data,
    (data, clientId) => clientId,
    (data, clientId, propertyName) => propertyName,
    (data, clientId, propertyName, subData) => subData,
    (data, clientId, propertyName, subData, subPropertyName) => subPropertyName,
  ],
  (data, clientId, propertyName, subData, subPropertyName) => pickBy(
    data,
    (subData && subPropertyName)
      ? entity => get(subData, `${entity[propertyName]}.${subPropertyName}`) === clientId
      : entity => entity[propertyName] === clientId,
  ),
);
