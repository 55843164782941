import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { map, find, pickBy, sortBy, head, values } from 'lodash';

import { ButtonConfirmModal } from 'react-components-linaia';

import CardListView from '../shared/CardListView';

const TechniciansAdminCardListView = ({
  clients, baseUrl, sites, documents, contracts,
}) => {
  let clientSite = {};
  return (
    <CardListView
      className="card-list-clients linked-cards"
      data={sites}
      cardComponent={(site) => {
        clientSite = find(clients, o => (o.client_id === site.siteClient)) || { clientName: '' };
        const documentsSite = values(pickBy(documents, d => (d.documentSite === parseInt(site.site_id, 10))));
        // order Contract
        const contractsSite = pickBy(contracts, d => d.contractAffectedSite === parseInt(site.site_id, 10));
        let orderContract = sortBy(contractsSite, cont => (moment(cont.contractStartDate)));
        // show n° contract - label in the select field
        orderContract = (map(orderContract, contract => ({
          ...contract,
          contractInputLabel: contract.contractNumber + ' - ' + contract.contractLabel,
        })));
        let contract_id = null;
        let plan_id = null;
        if (orderContract && head(orderContract)) {
          contract_id = head(orderContract).contract_id;
        }
        if (contractsSite && head(documentsSite)) {
          plan_id = head(documentsSite).document_id;
        }
        return (
          <div className="col-3_xs-12_sm-6_md-4">
            <div className="box card simple-card">
              <div className="box-content">
                <NavLink
                  to={`${baseUrl}/manage/${site.site_id}/sites${contract_id ? ('/' + contract_id + '/contract') : ''}${plan_id ? ('/' + plan_id + '/plan') : ''}`}
                  className="grid-noBottom"
                >
                  <div className="col-12">
                    {(clientSite !== undefined) && (clientSite.clientName !== undefined) && (
                      <div className="card-title">
                        {clientSite.clientName}
                      </div>
                    )}
                  </div>
                  {site && site.siteName && (
                    <div className="col-12">
                      {site.siteName}
                    </div>
                  )}
                </NavLink>
              </div>
            </div>
          </div>
        );
      }}
      search={['siteName']}
      searchLabel="Recherche"
      sortByDefault="clientName"
    />
  );
};

TechniciansAdminCardListView.propTypes = {
  clients: PropTypes.objectOf(PropTypes.shape({
    client_id: PropTypes.number.isRequired,
    clientName: PropTypes.string.isRequired,
    clientOutstanding: PropTypes.number,
  })).isRequired,

  sites: PropTypes.objectOf(PropTypes.shape()).isRequired,

  documents: PropTypes.objectOf(PropTypes.shape()),
  contracts: PropTypes.objectOf(PropTypes.shape()),

  baseUrl: PropTypes.string.isRequired,
};

export default connect(
  state => ({
    contracts: state.data.entities.contracts,
    documents: state.data.entities.documents,
  }),
)(withRouter(TechniciansAdminCardListView));
