/*****************************************************************************
 * Properties
 *****************************************************************************/

/*
 * ACCOUNTS_ROLES
 */
export const ACCOUNTS_ROLES = {
  ADMIN: 'ADMIN',
  CLIENT_CONTACT: 'CLIENT_CONTACT',
  TECHNICIAN_ADMIN: 'TECHNICIAN_ADMIN',
};

/*
 * OPT_COURTESY_TITLE
 */
export const OPT_COURTESY_TITLE = {
  mr: { short: 'M.', long: 'Monsieur' },
  mme: { short: 'Mme', long: 'Madame' },
};

/*
 * EQUIPMENT_TYPES
 */
export const EQUIPMENT_TYPES = {
  baitStation: 'Poste d\'appâtage',
  DEIV: 'DEIV',
  mothsTrap: 'Piège à teignes',
};
/*
 * EQUIPMENT_TYPES
 */
export const equipmentLibre = contractId => ({
  image: 'fa-exclamation-triangle',
  text: 'Point d\'attention',
  equipmentPlan: [],
  equipmentType: 'remarqueLibre',
  equipmentPlanDetail: [],
  equipmentName: 'Point d\'attention',
  equipmentContract: parseInt(contractId, 10),
});
/*
 * EQUIPMENT_LOCATION
 */
export const EQUIPMENT_LOCATION = {
  inside: 'Intérieur',
  outside: 'Extérieur',
};
/*
 * CONTRACT_TYPES
 */
export const CONTRACT_TYPES = {
  deratization: 'Dératisation',
  DEIV: 'DEIV',
  mothsTrap: 'Piège à teignes',
};

/*
 * PASSAGE_STATE_DERATISATION
 */
export const PASSAGE_STATE_DERATISATION = {
  unconsumed: 'Non Consommé',
  consumedRenewed: 'Consommé et renouvelé',
  deterioratedRenewed: 'Détérioré et renouvelé',
  inaccessible: 'Poste inaccessible',
  disappearedReplaced: 'Poste disparu et remplacé',
};
