import React, { useState, Fragment } from 'react';
import {
  Image as KonvaImage,
} from 'react-konva';

class URLImage extends React.Component {
  state = {
    image: null,
  };

  componentDidMount () {
    this.loadImage();
  }

  componentDidUpdate (oldProps) {
    if (oldProps.src !== this.props.src) {
      this.loadImage();
    }
  }

  componentWillUnmount () {
    this.image.removeEventListener('load', this.handleLoad);
  }

  handleLoad = () => {
    // after setState react-konva will update canvas and redraw the layer
    // because "image" property is changed

    const ratio = this.image.width / this.image.height;
    this.setState({
      image: this.image,
      ratio,
    });
    // if you keep same image object during source updates
    // you will have to update layer manually:
    // this.imageNode.getLayer().batchDraw();
  };

  loadImage () {
    // save to "this" to remove "load" handler on unmount
    this.image = new window.Image();
    this.image.src = this.props.src;
    this.image.addEventListener('load', this.handleLoad);
  }

  render () {
    //.....
    return (
      <Fragment>
        { this.state.image && (
          <KonvaImage
            x={this.props.x}
            y={this.props.y}
            width={this.props.usePropsWidth ? this.props.width : this.state.image.width}
            height={this.props.usePropsHeight ? this.props.height : this.state.image.height}
            opacity={this.props.opacity}
            image={this.state.image}
            ref={(node) => {
              this.imageNode = node;
            }}
          />
        )}
      </Fragment>
    );
  }
}
export default (URLImage);
