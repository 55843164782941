import React, { Fragment } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { NavLink } from 'react-router-dom';

import { UPLOADS_URL, DATE_FORMAT } from '../../constants/Config';

const ActualityDetailModalView = ({
  actuality = {}, baseUrl,
}) => (
  <Fragment>
    <div className="modal-news-header">
      <div className="modal-news-title">
        {actuality.actualityTitle}
        <NavLink className="btn" to={baseUrl}>
          <i className="fal fa-times" />
        </NavLink>
      </div>
      <div className="modal-news-date">{moment(actuality.actualityStartDate).format(DATE_FORMAT)}</div>
    </div>
    <div className="modal-news-body">
      <div className="grid">
        {actuality.actualityThumbnail && (
          <div className="col-5">
            <img src={`${UPLOADS_URL}/${actuality.actualityThumbnail}`} alt={actuality.actualityThumbnail}  />
          </div>
        )}
        <div className="col">
          <div className="modal-news-summary">
            {actuality.actualitySummary}
          </div>
        </div>
      </div>
      <div className="modal-news-content" dangerouslySetInnerHTML={{ __html:actuality.actualityContent }} />
      {actuality.actualityFiles && actuality.actualityFiles.length > 0 && (
        <Fragment>
          <div className="modal-news-block-title">Pièces jointes :</div>
          <ul>
            {map(actuality.actualityFiles, file => file && (
              <li>
                <a href={`${UPLOADS_URL}/${file}`} target="_blank" rel="noopener noreferrer">{file}</a>
              </li>
            ))}
          </ul>
        </Fragment>
      )}
      {actuality.actualityLinks && actuality.actualityLinks.length > 0 && (
        <Fragment>
          <div className="modal-news-block-title">Liens externes :</div>
          <ul>
            {map(actuality.actualityLinks, link => link && (
              <li>
                <a href={`${link}`}>{link}</a>
              </li>
            ))}
          </ul>
        </Fragment>
      )}
    </div>
  </Fragment>
);

ActualityDetailModalView.propTypes = {
  actuality: PropTypes.shape({
    actuality_id: PropTypes.number.isRequired,
    actualityStartDate: PropTypes.string.isRequired,
    actualityEndDate: PropTypes.string,
    actualityTitle: PropTypes.string.isRequired,
    actualitySummary: PropTypes.string.isRequired,
    actualityContent: PropTypes.string.isRequired,
    actualityThumbnail: PropTypes.string,
    actualityFiles: PropTypes.arrayOf(PropTypes.string),
    actualityLinks: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,

  baseUrl: PropTypes.string.isRequired,
};

export default (ActualityDetailModalView);
