import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
// import { isUserAgentMobile } from 'react-components-linaia';

import { loadAccounts } from '../../store/modules/accounts';
import { loadOption } from '../../store/modules/options';

import { loadActualitys } from '../../store/modules/actualitys';
import { loadBills } from '../../store/modules/bills';
import { loadCategorys } from '../../store/modules/categorys';
import { loadClients } from '../../store/modules/clients';
import { loadContacts } from '../../store/modules/contacts';
import { loadContracts } from '../../store/modules/contracts';
import { loadDocuments } from '../../store/modules/documents';
import { loadEquipments } from '../../store/modules/equipments';
import { loadPassages } from '../../store/modules/passages';
import { loadSharedcategorys } from '../../store/modules/sharedcategorys';
import { loadShareddocuments } from '../../store/modules/shareddocuments';
import { loadSites } from '../../store/modules/sites';
import { loadStructures } from '../../store/modules/structures';
import { loadTechnicians } from '../../store/modules/technicians';

import { isAdmin, isClientContact, isTechnicianAdmin } from '../../utils/RolesUtils';

@connect(
  state => ({
    user: state.auth.user,
    loaded: state.data.loaded,
    loading: state.data.loading,
  }),
  {
    loadAccounts,
    loadOption,

    loadActualitys,
    loadBills,
    loadCategorys,
    loadClients,
    loadContacts,
    loadContracts,
    loadDocuments,
    loadEquipments,
    loadPassages,
    loadSharedcategorys,
    loadShareddocuments,
    loadSites,
    loadStructures,
    loadTechnicians,
  },
)
class AuthenticatedComponent extends React.PureComponent {
  static propTypes = {
    loadAccounts: PropTypes.func.isRequired,
    loadOption: PropTypes.func.isRequired,

    loadActualitys: PropTypes.func.isRequired,
    loadBills: PropTypes.func.isRequired,
    loadCategorys: PropTypes.func.isRequired,
    loadClients: PropTypes.func.isRequired,
    loadContacts: PropTypes.func.isRequired,
    loadContracts: PropTypes.func.isRequired,
    loadDocuments: PropTypes.func.isRequired,
    loadEquipments: PropTypes.func.isRequired,
    loadPassages: PropTypes.func.isRequired,
    loadSharedcategorys: PropTypes.func.isRequired,
    loadShareddocuments: PropTypes.func.isRequired,
    loadSites: PropTypes.func.isRequired,
    loadStructures: PropTypes.func.isRequired,
    loadTechnicians: PropTypes.func.isRequired,

    children: PropTypes.node.isRequired,

    user: PropTypes.shape({
      client_id: PropTypes.number,
      roles: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    history: PropTypes.shape({
      replace: PropTypes.func.isRequired,
    }).isRequired,
    loaded: PropTypes.shape({
      appstorage: PropTypes.bool.isRequired,
      accounts: PropTypes.bool.isRequired,
      options: PropTypes.bool.isRequired,
    }).isRequired,
    loading: PropTypes.shape({
      accounts: PropTypes.bool.isRequired,
      options: PropTypes.bool.isRequired,
    }).isRequired,
    match: PropTypes.shape().isRequired,
  };

  state = { preventRender: true };

  static getDerivedStateFromProps (props) {
    // Waiting for appstorage loading
    if (!props.loaded.appstorage) {
      return { preventRender: true };
    }

    const redirectLocation = (pathname = null) => {
      if (pathname !== null) {
        props.history.replace(pathname);
      }
      return { preventRender: true };
    };

    // Check router path
    if (!props.user) {
      // oops, not logged in, so can't be here!
      return redirectLocation(props.location.pathname !== '/login'
        ? '/login' // quickfix #2527 `/login?next=${props.location.pathname}`
        : null);
    }
    if (isClientContact(props.user) && props.location.pathname.indexOf('/clients') !== 0) {
      // User access only to /user
      return redirectLocation('/clients');
    }
    if (isTechnicianAdmin(props.user) && props.location.pathname.indexOf('/technicians') !== 0) {
      // User access only to /user
      return redirectLocation('/technicians');
    }
    if (isAdmin(props.user) && props.location.pathname.indexOf('/admin') !== 0) {
      // Redirect Admin automatocally to ClientsList
      return redirectLocation('/admin/clients');
    }

    // Load application data if not currently loaded
    const isNotLoaded = entityKey => !get(props, `loading.${entityKey}`) && !get(props, `loaded.${entityKey}`);
    // Only Clients
    if (isClientContact(props.user) || isAdmin(props.user)) {
      if (isNotLoaded('options')) {
        props.loadOption('welcomeMessage');
      }
      if (isNotLoaded('clients')) {
        props.loadClients();
      }
      if (isNotLoaded('bills')) {
        props.loadBills();
      }
      if (isNotLoaded('actualitys')) {
        props.loadActualitys();
      }
      if (isNotLoaded('sharedcategorys')) {
        props.loadSharedcategorys();
      }
      if (isNotLoaded('shareddocuments')) {
        props.loadShareddocuments();
      }
      if (isNotLoaded('categorys')) {
        props.loadCategorys();
      }
      if (isNotLoaded('sites')) {
        props.loadSites();
      }
      if (isNotLoaded('documents')) {
        props.loadDocuments();
      }
      if (isNotLoaded('contracts')) {
        props.loadContracts();
      }
      if (isNotLoaded('structures')) {
        props.loadStructures();
      }
      if (isNotLoaded('technicians')) {
        props.loadTechnicians();
      }
      if (isNotLoaded('passages')) {
        props.loadPassages();
      }
      if (isNotLoaded('equipments')) {
        props.loadEquipments();
      }
    }
    // Only Technicians (get the sites)
    if (isTechnicianAdmin(props.user)) {
      if (isNotLoaded('sites')) {
        // we need to load all sites with technicianId
        props.loadSites();
      }
      if (isNotLoaded('clients')) {
        // we need clients for sites
        props.loadClients();
      }
      if (isNotLoaded('equipments')) {
        // we need equipments
        props.loadEquipments();
      }
      if (isNotLoaded('contracts')) {
        // we need contracts
        props.loadContracts();
      }
      if (isNotLoaded('documents')) {
        // we need contracts
        props.loadDocuments();
      }
    }

    // Only Admins
    if (isAdmin(props.user)) {
      if (isNotLoaded('accounts')) {
        props.loadAccounts();
      }
      if (isNotLoaded('contacts')) {
        props.loadContacts();
      }
    }
    // Only desktop
    // if (!isUserAgentMobile) {
    // }

    return { preventRender: false };
  }

  render () {
    const {
      children, match,
    } = this.props;
    const { preventRender } = this.state;

    return !preventRender && children
      ? React.cloneElement(children, { match })
      : null;
  }
}

export default withRouter(AuthenticatedComponent);
