import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { withRouter, Switch, Route } from 'react-router-dom';
import { get } from 'lodash';

import { createOrUpdateShareddocument, deleteShareddocument } from '../../store/modules/shareddocuments';

import BaseLayout from '../shared/BaseLayout';

import ShareddocumentDetailView from '../../components/shareddocuments/ShareddocumentDetailView';
import ShareddocumentEditForm from '../../components/shareddocuments/ShareddocumentEditForm';
import ShareddocumentsTableListView from '../../components/shareddocuments/ShareddocumentsTableListView';

import modalDefaultClass from '../../utils/ModalDefaultClass';

const RenderEditDetailsModalsRouter = ({
  url, editShareddocument, delShareddocument, push, shareddocuments,
}) => (
  <Switch>
    <Route
      exact
      path={`${url}/new`}
      render={() => (
        <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
          <ShareddocumentEditForm
            initialValues={{}}
            onSubmit={(v) => {
              editShareddocument(v)
              .then(() => push(url));
            }}
            baseUrl={url}
          />
        </Modal>
      )}
    />
    <Route
      exact
      path={`${url}/:shareddocumentId/edit`}
      render={subProps => get(shareddocuments, subProps.match.params.shareddocumentId, null) && (
        <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
          <ShareddocumentEditForm
            initialValues={shareddocuments[subProps.match.params.shareddocumentId]}
            onSubmit={(v) => {
              editShareddocument(v)
              .then(() => push(url));
            }}
            baseUrl={url}
          />
        </Modal>
      )}
    />
    <Route
      exact
      path={`${url}/:shareddocumentId`}
      render={subProps => get(shareddocuments, subProps.match.params.shareddocumentId, null) && (
        <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
          <ShareddocumentDetailView
            shareddocument={shareddocuments[subProps.match.params.shareddocumentId]}
            baseUrl={url}
            onClickDelete={() => {
              delShareddocument(subProps.match.params.shareddocumentId)
              .then(() => push(url));
            }}
          />
        </Modal>
      )}
    />
  </Switch>
);

RenderEditDetailsModalsRouter.propTypes = {
  url: PropTypes.string.isRequired,
  editShareddocument: PropTypes.func.isRequired,
  delShareddocument: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  shareddocuments: PropTypes.objectOf(PropTypes.shape()).isRequired,
};

const ShareddocumentsContainer = ({
  match: { url }, history: { push }, shareddocuments, ...props
}) => (
  <BaseLayout title="Documents partagés">
    <Route
      path={url}
      render={({ match }) => (
        <Fragment>
          <ShareddocumentsTableListView
            shareddocuments={shareddocuments}
            baseUrl={match.url}
            onClickDelete={(shareddocumentId) => {
              props.deleteShareddocument(shareddocumentId)
              .then(() => push(match.url));
            }}
          />
          <RenderEditDetailsModalsRouter
            url={match.url}
            editShareddocument={props.createOrUpdateShareddocument}
            delShareddocument={props.deleteShareddocument}
            push={push}
            shareddocuments={shareddocuments}
          />
        </Fragment>
      )}
    />
  </BaseLayout>
);

ShareddocumentsContainer.propTypes = {
  createOrUpdateShareddocument: PropTypes.func.isRequired,
  deleteShareddocument: PropTypes.func.isRequired,

  shareddocuments: PropTypes.objectOf(PropTypes.shape()).isRequired,

  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect(
  state => ({
    shareddocuments: state.data.entities.shareddocuments,
  }),
  { createOrUpdateShareddocument, deleteShareddocument },
)(withRouter(ShareddocumentsContainer));
