import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Modal from "react-modal";
import { withRouter, Switch, Route } from "react-router-dom";
import { get, pickBy } from "lodash";

import createSelector from "../../selectors/ClientSubEntitiesSelector";

import { createOrUpdateEquipment, deleteEquipment } from "../../store/modules/equipments";

import EquipmentDetailView from "../../components/equipments/EquipmentDetailView";
import EquipmentEditForm from "../../components/equipments/EquipmentEditForm";
import EquipmentsTableListView from "../../components/equipments/EquipmentsTableListView";

import modalDefaultClass from "../../utils/ModalDefaultClass";

const clientSubEntitiesSelector = createSelector();

const RenderEditDetailsModalsRouter = ({
  clientId,
  url,
  editEquipment,
  delEquipment,
  push,
  equipments,
}) => (
  <Switch>
    <Route
      exact
      path={`${url}/new`}
      render={() => (
        <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
          <EquipmentEditForm
            initialValues={{
              equipmentIsToxic: false,
              equipmentPlan: [],
              equipmentPlanDetail: [],
            }}
            onSubmit={(v) => {
              editEquipment(v).then(() => push(url));
            }}
            baseUrl={url}
            clientId={clientId}
          />
        </Modal>
      )}
    />
    <Route
      exact
      path={`${url}/:equipmentId/edit`}
      render={(subProps) =>
        get(equipments, subProps.match.params.equipmentId, null) && (
          <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
            <EquipmentEditForm
              initialValues={{
                equipmentIsToxic: false,
                equipmentPlan: [],
                equipmentPlanDetail: [],
                ...equipments[subProps.match.params.equipmentId],
              }}
              onSubmit={(v) => {
                editEquipment(v).then(() => push(url));
              }}
              baseUrl={url}
              clientId={clientId}
            />
          </Modal>
        )
      }
    />
    <Route
      exact
      path={`${url}/:equipmentId`}
      render={(subProps) =>
        get(equipments, subProps.match.params.equipmentId, null) && (
          <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
            <EquipmentDetailView
              equipment={equipments[subProps.match.params.equipmentId]}
              baseUrl={url}
              onClickDelete={() => {
                delEquipment(subProps.match.params.equipmentId).then(() => push(url));
              }}
              clientId={clientId}
            />
          </Modal>
        )
      }
    />
  </Switch>
);

RenderEditDetailsModalsRouter.propTypes = {
  url: PropTypes.string.isRequired,
  editEquipment: PropTypes.func.isRequired,
  delEquipment: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  equipments: PropTypes.objectOf(PropTypes.shape()).isRequired,
  clientId: PropTypes.number.isRequired,
};

const EquipmentsContainer = ({
  clientId,
  match: { url },
  history: { push },
  equipments,
  ...props
}) => {
  equipments = pickBy(equipments, (eq) => eq.equipmentType !== "remarqueLibre");
  return (
    <Fragment>
      <EquipmentsTableListView
        equipments={equipments}
        baseUrl={url}
        onClickDelete={(equipmentId) => {
          props.deleteEquipment(equipmentId).then(() => push(url));
        }}
        clientId={clientId}
      />
      <RenderEditDetailsModalsRouter
        url={url}
        editEquipment={props.createOrUpdateEquipment}
        delEquipment={props.deleteEquipment}
        push={push}
        equipments={equipments}
        clientId={clientId}
      />
    </Fragment>
  );
};

EquipmentsContainer.propTypes = {
  clientId: PropTypes.number.isRequired,

  createOrUpdateEquipment: PropTypes.func.isRequired,
  deleteEquipment: PropTypes.func.isRequired,

  equipments: PropTypes.objectOf(PropTypes.shape()).isRequired,

  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect(
  (state, { clientId }) => ({
    equipments: clientSubEntitiesSelector(
      state.data.entities.equipments,
      clientId,
      "equipmentContract",
      state.data.entities.contracts,
      "contractClient"
    ),
  }),
  { createOrUpdateEquipment, deleteEquipment }
)(withRouter(EquipmentsContainer));
