import { useState, useEffect } from 'react';

const useDrop = ({ ref, onDrop }) => {
  const [dropState, updateDropState] = useState('droppable');
  const dropOverCb = (ev) => {
    ev.preventDefault();
    updateDropState('dragging over');
  };

  const dropCb = (ev) => {
    ev.preventDefault();
    if (ev.dataTransfer) {
      onDrop(ev.dataTransfer.getData('source'), ev.layerX, ev.layerY);
      updateDropState('dropped');
    }
  };
  const mouseUpCb = (ev) => {
    ev.preventDefault();
    updateDropState('mouse up');
    if (ev.dataTransfer) {
      onDrop(ev.dataTransfer.getData('source'), ev.layerX, ev.layerY);
      updateDropState('dropped');
    }
  };

  useEffect(() => {
    const elem = ref.current;
    if (elem) {
      elem.addEventListener('dragover', dropOverCb);
      elem.addEventListener('drop', dropCb);
      elem.addEventListener('mouseup', mouseUpCb);
      return () => {
        elem.removeEventListener('dragover', dropOverCb);
        elem.removeEventListener('drop', dropCb);
        elem.removeEventListener('mouseup', mouseUpCb);
      };
    }
    return null;
  });
  return {
    dropState,
  };
};

export default useDrop;
