import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import 'chartjs-plugin-colorschemes';

const ChartSinglePie = ({ labels, data }) => (
  <Doughnut
    data={{
      labels,
      datasets: [{
        data,
      }],
    }}
    options={{
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        },
      },
      legend: {
        // display: false,
        position: 'right',
        labels: {
          usePointStyle: true,
          boxWidth: 10,
        },
      },
      plugins: {
        colorschemes: {
          scheme: 'tableau.SunsetSunrise7',
        },
      },
    }}
  />
);

ChartSinglePie.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default ChartSinglePie;
