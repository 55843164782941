import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { NavLink } from 'react-router-dom';
import { ButtonConfirmModal } from 'react-components-linaia';

import { UPLOADS_URL } from '../../constants/Config';

const SiteDetailView = ({
  site = {}, baseUrl, onClickDelete, clients, technicians,
}) => (
  <div>
    <div>
      Site Id:&nbsp;
      {site.site_id}
    </div>
    <div>
      Site Name:&nbsp;
      {site.siteName}
    </div>
    <div>
      Site Phone1:&nbsp;
      {site.sitePhone1}
    </div>
    <div>
      Site Phone2:&nbsp;
      {site.sitePhone2}
    </div>
    <div>
      Site Email:&nbsp;
      {site.siteEmail}
    </div>
    <div>
      Site Address1:&nbsp;
      {site.siteAddress1}
    </div>
    <div>
      Site Address2:&nbsp;
      {site.siteAddress2}
    </div>
    <div>
      Site Address3:&nbsp;
      {site.siteAddress3}
    </div>
    <div>
      Site Postal Code:&nbsp;
      {site.sitePostalCode}
    </div>
    <div>
      Site City:&nbsp;
      {site.siteCity}
    </div>
    <div>
      Site Photo Url:&nbsp;
      <img src={`${UPLOADS_URL}/${site.sitePhotoUrl}`} alt={site.sitePhotoUrl} />
    </div>
    <div>
      Site Client:&nbsp;
      {get(clients, `${site.siteClient}.clientName`)}
    </div>
    <div>
      Site Technician:&nbsp;
      {get(technicians, `${site.siteTechnician}.technicianFirstname`)}
    </div>

    <div className="btn-group right">
      <NavLink className="btn" to={`${baseUrl}/${site.site_id}/edit`}>
        Modifier
      </NavLink>
      {onClickDelete && (
        <ButtonConfirmModal
          onConfirmationButton={() => onClickDelete()}
          message="Voulez-vous vraiment supprimer ce site ?"
        >
          <button className="btn" type="button">Supprimer</button>
        </ButtonConfirmModal>
      )}
    </div>
  </div>
);

SiteDetailView.propTypes = {
  site: PropTypes.shape({
    site_id: PropTypes.number.isRequired,
    siteName: PropTypes.string.isRequired,
    sitePhone1: PropTypes.string.isRequired,
    sitePhone2: PropTypes.string,
    siteEmail: PropTypes.string.isRequired,
    siteAddress1: PropTypes.string.isRequired,
    siteAddress2: PropTypes.string,
    siteAddress3: PropTypes.string,
    sitePostalCode: PropTypes.string.isRequired,
    siteCity: PropTypes.string.isRequired,
    sitePhotoUrl: PropTypes.string,
    siteClient: PropTypes.number.isRequired,
    siteTechnician: PropTypes.number.isRequired,
  }).isRequired,

  clients: PropTypes.objectOf(PropTypes.shape()).isRequired,
  technicians: PropTypes.objectOf(PropTypes.shape()).isRequired,

  baseUrl: PropTypes.string.isRequired,
  onClickDelete: PropTypes.func,
};

export default connect(
  state => ({
    clients: state.data.entities.clients,
    technicians: state.data.entities.technicians,
  }),
)(SiteDetailView);
