import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { NavLink } from 'react-router-dom';
import { ButtonConfirmModal } from 'react-components-linaia';

import createSelector from '../../selectors/ClientSubEntitiesSelector';

const clientSubEntitiesSelector = createSelector();

const ContactDetailView = ({
  contact = {}, baseUrl, onClickDelete, clients, accounts, sites, categorys,
}) => (
  <div>
    <div>
      Contact Id:&nbsp;
      {contact.contact_id}
    </div>
    <div>
      Contact Client:&nbsp;
      {get(clients, `${contact.contactClient}.clientName`)}
    </div>
    <div>
      Contact Account:&nbsp;
      {get(accounts, `${contact.contactAccount}.email`)}
    </div>
    <div>
      Contact Firstname:&nbsp;
      {contact.contactFirstname}
    </div>
    <div>
      Contact Lastname:&nbsp;
      {contact.contactLastname}
    </div>
    <div>
      Contact Sites:&nbsp;
      {get(sites, `${contact.contactSites}.siteName`)}
    </div>

    <div>
      Contact Lastname:&nbsp;
      {contact.contactPhone}
    </div>

    <div>
      Contact Categories:&nbsp;
      {get(categorys, `${contact.contactCategories}.categoryName`)}
    </div>
    <div>
      Contact Billing Access:&nbsp;
      {contact.contactBillingAccess}
    </div>

    <div className="btn-group right">
      <NavLink className="btn" to={`${baseUrl}/${contact.contact_id}/edit`}>
        Modifier
      </NavLink>
      {onClickDelete && (
        <ButtonConfirmModal
          onConfirmationButton={() => onClickDelete()}
          message="Voulez-vous vraiment supprimer ce contact ?"
        >
          <button className="btn" type="button">Supprimer</button>
        </ButtonConfirmModal>
      )}
    </div>
  </div>
);

ContactDetailView.propTypes = {
  contact: PropTypes.shape({
    contact_id: PropTypes.number.isRequired,
    contactClient: PropTypes.number.isRequired,
    contactAccount: PropTypes.number.isRequired,
    contactFirstname: PropTypes.string.isRequired,
    contactLastname: PropTypes.string.isRequired,
    contactSites: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
    contactCategories: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
    contactPhone: PropTypes.string.isRequired,
    contactBillingAccess: PropTypes.bool.isRequired,
  }).isRequired,

  clients: PropTypes.objectOf(PropTypes.shape()).isRequired,
  accounts: PropTypes.objectOf(PropTypes.shape()).isRequired,
  sites: PropTypes.objectOf(PropTypes.shape()).isRequired,
  categorys: PropTypes.objectOf(PropTypes.shape()).isRequired,

  baseUrl: PropTypes.string.isRequired,
  onClickDelete: PropTypes.func,
};

export default connect(
  (state, { clientId }) => ({
    clients: state.data.entities.clients,
    accounts: state.data.entities.accounts,
    sites: clientSubEntitiesSelector(state.data.entities.sites, clientId, 'siteClient'),
    categorys: state.data.entities.categorys,
  }),
)(ContactDetailView);
