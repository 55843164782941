import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { withRouter, Switch, Route } from 'react-router-dom';
import { get, pickBy, isNumber } from 'lodash';

import createSelector from '../../selectors/ClientSubEntitiesSelector';

import { createOrUpdateDocument, deleteDocument } from '../../store/modules/documents';

import DocumentDetailView from '../../components/documents/DocumentDetailView';
import DocumentEditForm from '../../components/documents/DocumentEditForm';
import DocumentsTableListView from '../../components/documents/DocumentsTableListView';

import modalDefaultClass from '../../utils/ModalDefaultClass';

const clientSubEntitiesSelector = createSelector();

const RenderEditDetailsModalsRouter = ({
  clientId, url, editDocument, delDocument, push, documents,
}) => (
  <Switch>
    <Route
      exact
      path={`${url}/new`}
      render={() => (
        <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
          <DocumentEditForm
            initialValues={{}}
            onSubmit={(v) => {
              editDocument(v)
              .then(() => push(url));
            }}
            baseUrl={url}
            clientId={clientId}
          />
        </Modal>
      )}
    />
    <Route
      exact
      path={`${url}/:documentId/edit`}
      render={subProps => get(documents, subProps.match.params.documentId, null) && (
        <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
          <DocumentEditForm
            initialValues={documents[subProps.match.params.documentId]}
            onSubmit={(v) => {
              editDocument(v)
              .then(() => push(url));
            }}
            baseUrl={url}
            clientId={clientId}
          />
        </Modal>
      )}
    />
    <Route
      exact
      path={`${url}/:documentId`}
      render={subProps => get(documents, subProps.match.params.documentId, null) && (
        <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
          <DocumentDetailView
            document={documents[subProps.match.params.documentId]}
            baseUrl={url}
            onClickDelete={() => {
              delDocument(subProps.match.params.documentId)
              .then(() => push(url));
            }}
            clientId={clientId}
          />
        </Modal>
      )}
    />
  </Switch>
);

RenderEditDetailsModalsRouter.propTypes = {
  url: PropTypes.string.isRequired,
  editDocument: PropTypes.func.isRequired,
  delDocument: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  documents: PropTypes.objectOf(PropTypes.shape()).isRequired,
  clientId: PropTypes.number.isRequired,
};

const DocumentsContainer = ({
  clientId, isAdmin, documentCategory, match: { url }, history: { push }, documents, ...props
}) => (
  <Fragment>
    <DocumentsTableListView
      documents={documents}
      baseUrl={url}
      onClickDelete={(documentId) => {
        props.deleteDocument(documentId)
        .then(() => push(url));
      }}
      isAdmin={isAdmin}
      clientId={clientId}
      documentCategory={documentCategory}
    />
    <RenderEditDetailsModalsRouter
      url={url}
      editDocument={props.createOrUpdateDocument}
      delDocument={props.deleteDocument}
      push={push}
      documents={documents}
      clientId={clientId}
    />
  </Fragment>
);

DocumentsContainer.propTypes = {
  clientId: PropTypes.number.isRequired,
  isAdmin: PropTypes.bool,
  documentCategory: PropTypes.number,

  createOrUpdateDocument: PropTypes.func.isRequired,
  deleteDocument: PropTypes.func.isRequired,

  documents: PropTypes.objectOf(PropTypes.shape()).isRequired,

  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect(
  (state, { clientId, documentCategory }) => ({
    documents: clientSubEntitiesSelector(
      isNumber(documentCategory)
        ? pickBy(state.data.entities.documents, d => d.documentCategory === documentCategory)
        : state.data.entities.documents,
      clientId,
      'documentSite',
      state.data.entities.sites,
      'siteClient',
    ),
  }),
  { createOrUpdateDocument, deleteDocument },
)(withRouter(DocumentsContainer));
