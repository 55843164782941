import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { withRouter, Switch, Route } from 'react-router-dom';
import { get } from 'lodash';

import { createOrUpdateStructure, deleteStructure } from '../../store/modules/structures';

import BaseLayout from '../shared/BaseLayout';

import StructureDetailView from '../../components/structures/StructureDetailView';
import StructureEditForm from '../../components/structures/StructureEditForm';
import StructuresCardListView from '../../components/structures/StructuresCardListView';

import modalDefaultClass from '../../utils/ModalDefaultClass';

const RenderEditDetailsModalsRouter = ({
  url, editStructure, delStructure, push, structures,
}) => (
  <Switch>
    <Route
      exact
      path={`${url}/new`}
      render={() => (
        <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
          <StructureEditForm
            initialValues={{}}
            onSubmit={(v) => {
              editStructure(v)
              .then(() => push(url));
            }}
            baseUrl={url}
          />
        </Modal>
      )}
    />
    <Route
      exact
      path={`${url}/:structureId/edit`}
      render={subProps => get(structures, subProps.match.params.structureId, null) && (
        <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
          <StructureEditForm
            initialValues={structures[subProps.match.params.structureId]}
            onSubmit={(v) => {
              editStructure(v)
              .then(() => push(url));
            }}
            baseUrl={url}
          />
        </Modal>
      )}
    />
    <Route
      exact
      path={`${url}/:structureId`}
      render={subProps => structures(structures, subProps.match.structuresId, null) && (
        <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
          <StructureDetailView
            structure={structures[subProps.match.params.structureId]}
            baseUrl={url}
            onClickDelete={() => {
              delStructure(subProps.match.params.structureId)
              .then(() => push(url));
            }}
          />
        </Modal>
      )}
    />
  </Switch>
);

RenderEditDetailsModalsRouter.propTypes = {
  url: PropTypes.string.isRequired,
  editStructure: PropTypes.func.isRequired,
  delStructure: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  structures: PropTypes.objectOf(PropTypes.shape()).isRequired,
};

const StructuresContainer = ({
  match: { url }, history: { push }, structures, ...props
}) => (
  <BaseLayout title="Structures">
    <Route
      path={url}
      render={({ match }) => (
        <Fragment>
          <StructuresCardListView
            structures={structures}
            baseUrl={match.url}
            onClickDelete={(structureId) => {
              props.deleteStructure(structureId)
              .then(() => push(match.url));
            }}
          />
          <RenderEditDetailsModalsRouter
            url={match.url}
            editStructure={props.createOrUpdateStructure}
            delStructure={props.deleteStructure}
            push={push}
            structures={structures}
          />
        </Fragment>
      )}
    />
  </BaseLayout>
);

StructuresContainer.propTypes = {
  createOrUpdateStructure: PropTypes.func.isRequired,
  deleteStructure: PropTypes.func.isRequired,

  structures: PropTypes.objectOf(PropTypes.shape()).isRequired,

  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect(
  state => ({
    structures: state.data.entities.structures,
  }),
  { createOrUpdateStructure, deleteStructure },
)(withRouter(StructuresContainer));
