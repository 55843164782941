import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { NavLink } from 'react-router-dom';
import { isNumber, get } from 'lodash';
import {
  Field, DateField, FileField, InputField, SelectField,
} from 'react-components-linaia';

import createSelector from '../../selectors/ClientSubEntitiesSelector';

import { uploadFiles, removeFile } from '../../store/modules/uploads';

import { UPLOADS_URL, DOCUMENT_OPTIONS } from '../../constants/Config';

const clientSubEntitiesSelector = createSelector();

const DocumentEditForm = ({
  handleSubmit, baseUrl, initialValues, upFiles, rmFile, categorys, sites, documentType,
}) => (
  <Fragment>
    <div className="modal-title">
      <div className="modal-title-content">
        {initialValues.document_id >= 0 ? 'Modifier le document' : 'Ajouter un document'}
        <NavLink className="btn" to={baseUrl}>
          <i className="fal fa-times" />
        </NavLink>
      </div>
    </div>
    <form onSubmit={handleSubmit}>
      <div className="modal-body">
        <div className="grid">
          <Field
            name="documentCategory"
            component={SelectField}
            options={categorys}
            optionsKeys={{ value: 'category_id', label: 'categoryName' }}
            label="Catégorie"
            className="col-12"
          />
          <Field
            name="documentName"
            component={InputField}
            label="Nom du document"
            className="col-12"
          />
          <Field
            name="documentType"
            component={SelectField}
            label="Type de document"
            className="col-12"
            options={DOCUMENT_OPTIONS}
          />
          {documentType === 'fichier' && (
            <Field
              name="documentURL"
              component={FileField}
              uploadFiles={upFiles}
              removeFile={rmFile}
              getUrl={item => `${UPLOADS_URL}/${item}`}
              buttonLabel="Choisir un Fichier"
              className="col-12"
            />
          )}
          {documentType === 'lien' && (
            <Field
              name="documentURL"
              component={InputField}
              uploadFiles={upFiles}
              removeFile={rmFile}
              getUrl={item => `${UPLOADS_URL}/${item}`}
              label="Choisir un URL"
              className="col-12"
            />
          )}
          <Field
            name="documentStartDate"
            component={DateField}
            label="Début de publication"
            className="col-6"
            displayFormat="dd/MM/yyyy"
          />
          <Field
            name="documentEndDate"
            component={DateField}
            label="Fin de publication"
            className="col-6"
            displayFormat="dd/MM/yyyy"
          />
          <Field
            name="documentSite"
            component={SelectField}
            options={sites}
            optionsKeys={{ value: 'site_id', label: 'siteName' }}
            label="Site"
            className="col-12"
          />
        </div>
      </div>
      <div className="modal-footer">
        <div className="btn-group center">
          <button className="btn btn-primary" type="submit">
            Enregistrer
          </button>
        </div>
      </div>
    </form>
  </Fragment>
);

DocumentEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
  initialValues: PropTypes.shape().isRequired,
  documentType: PropTypes.string.isRequired,

  categorys: PropTypes.objectOf(PropTypes.shape()).isRequired,
  sites: PropTypes.objectOf(PropTypes.shape()).isRequired,

  upFiles: PropTypes.func.isRequired,
  rmFile: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'DocumentEditForm',
  validate: (values = {}) => {
    const errors = {};

    if (!values.documentName) errors.documentName = 'Merci de renseigner le nom du document';
    if (!values.documentType) errors.documentType = 'Merci de renseigner le type de document';
    if (!values.documentURL) errors.documentURL = 'Merci de renseigner un lien / fichier ';
    if (!isNumber(values.documentCategory)) errors.documentCategory = 'Merci de renseigner la catégorie du document';
    if (!isNumber(values.documentSite)) errors.documentSite = 'Merci de choisir un site';

    return errors;
  },
})(connect(
  (state, { clientId }) => ({
    categorys: state.data.entities.categorys,
    sites: clientSubEntitiesSelector(state.data.entities.sites, clientId, 'siteClient'),
    documentType: get(state, 'form.DocumentEditForm.values.documentType'),
  }),
  { upFiles: uploadFiles, rmFile: removeFile },
)(DocumentEditForm));
