import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { NavLink } from 'react-router-dom';
import { ButtonConfirmModal } from 'react-components-linaia';

import createSelector from '../../selectors/ClientSubEntitiesSelector';

import { UPLOADS_URL } from '../../constants/Config';

const clientSubEntitiesSelector = createSelector();

const DocumentDetailView = ({
  document = {}, baseUrl, onClickDelete, categorys, sites,
}) => (
  <div>
    <div>
      Document Id:&nbsp;
      {document.document_id}
    </div>
    <div>
      Document Name:&nbsp;
      {document.documentName}
    </div>
    <div>
      Document Type:&nbsp;
      {document.documentType}
    </div>
    <div>
      Document UR L:&nbsp;
      <a href={`${UPLOADS_URL}/${document.documentURL}`} target="_blank" rel="noopener noreferrer">{document.documentURL}</a>
    </div>
    <div>
      Document Category:&nbsp;
      {get(categorys, `${document.documentCategory}.categoryName`)}
    </div>
    <div>
      Document Start Date:&nbsp;
      {document.documentStartDate}
    </div>
    <div>
      Document End Date:&nbsp;
      {document.documentEndDate}
    </div>
    <div>
      Document Site:&nbsp;
      {get(sites, `${document.documentSite}.siteName`)}
    </div>

    <div className="btn-group right">
      <NavLink className="btn" to={`${baseUrl}/${document.document_id}/edit`}>
        Modifier
      </NavLink>
      {onClickDelete && (
        <ButtonConfirmModal
          onConfirmationButton={() => onClickDelete()}
          message="Voulez-vous vraiment supprimer ce document ?"
        >
          <button className="btn" type="button">Supprimer</button>
        </ButtonConfirmModal>
      )}
    </div>
  </div>
);

DocumentDetailView.propTypes = {
  document: PropTypes.shape({
    document_id: PropTypes.number.isRequired,
    documentName: PropTypes.string.isRequired,
    documentType: PropTypes.string.isRequired,
    documentURL: PropTypes.string.isRequired,
    documentCategory: PropTypes.number.isRequired,
    documentStartDate: PropTypes.string,
    documentEndDate: PropTypes.string,
    documentSite: PropTypes.number.isRequired,
  }).isRequired,

  categorys: PropTypes.objectOf(PropTypes.shape()).isRequired,
  sites: PropTypes.objectOf(PropTypes.shape()).isRequired,

  baseUrl: PropTypes.string.isRequired,
  onClickDelete: PropTypes.func,
};

export default connect(
  (state, { clientId }) => ({
    categorys: state.data.entities.categorys,
    sites: clientSubEntitiesSelector(state.data.entities.sites, clientId, 'siteClient'),
  }),
)(DocumentDetailView);
