import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Switch, Route } from 'react-router-dom';

import BaseLayout from '../shared/BaseLayout';

import ClientHomeContainer from '../home/ClientHomeContainer';
import BillsContainer from '../bills/BillsContainer';
import DocumentsContainer from '../documents/DocumentsContainer';
import ContractsContainer from '../contracts/ContractsContainer';
import SitesClientContainer from '../sites/SitesClientContainer';

const ClientsUserContainer = ({
  client, match: { url },
}) => (client && (
  <BaseLayout hideHeader>
    <div className="container-wrapper">
      <Switch>
        <Route exact path={`${url}/bills`} render={() => <BillsContainer clientId={client.client_id} />} />
        <Route exact path={`${url}/quotations`} render={() => <DocumentsContainer clientId={client.client_id} documentCategory={0} />} />
        <Route exact path={`${url}/contracts`} render={() => <ContractsContainer clientId={client.client_id} />} />
        <Route path={`${url}/sites/:siteId`} component={SitesClientContainer} />
        <Route path={url} component={ClientHomeContainer} />
      </Switch>
    </div>
  </BaseLayout>
)) || null;

ClientsUserContainer.propTypes = {
  client: PropTypes.shape(),

  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default connect(
  state => ({
    client: state.data.entities.clients[state.auth.user.contact.contactClient],
  }),
)(withRouter(ClientsUserContainer));
