import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter, NavLink } from 'react-router-dom';

const BONavLink = ({ url, title, setRef }) => (
  <li ref={ref => setRef(ref)}>
    <NavLink
      to={url}
      exact
      activeClassName="active"
    >
      <span>{title}</span>
    </NavLink>
  </li>
);

BONavLink.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  setRef: PropTypes.func.isRequired,
};

const BOHeader = ({ baseUrl, location: { pathname } }) => {
  const [nav, setNavref] = useState();
  const [sites, setSitesRef] = useState();
  const [contacts, setContactsRef] = useState();
  const [contracts, setContractsRef] = useState();
  const [documents, setDocumentsRef] = useState();
  const [bills, setBillsRef] = useState();
  const [equipments, setEquipmentsRef] = useState();
  const [passages, setPassagesRef] = useState();

  const activeName = pathname.replace(`${baseUrl}/`, '');
  switch (activeName) {
  case 'sites':
    if (sites && sites.offsetLeft && nav) nav.scrollTo({ top: 0, left: (sites.offsetLeft - (nav.offsetWidth / 2) + (sites.offsetWidth / 2)), behavior: 'smooth' });
    break;
  case 'contacts':
    if (contacts && contacts.offsetLeft && nav) nav.scrollTo({ top: 0, left: (contacts.offsetLeft - (nav.offsetWidth / 2) + (contacts.offsetWidth / 2)), behavior: 'smooth' });
    break;
  case 'contracts':
    if (contracts && contracts.offsetLeft && nav) nav.scrollTo({ top: 0, left: (contracts.offsetLeft - (nav.offsetWidth / 2) + (contracts.offsetWidth / 2)), behavior: 'smooth' });
    break;
  case 'documents':
    if (documents && documents.offsetLeft && nav) nav.scrollTo({ top: 0, left: (documents.offsetLeft - (nav.offsetWidth / 2) + (documents.offsetWidth / 2)), behavior: 'smooth' });
    break;
  case 'bills':
    if (bills && bills.offsetLeft && nav) nav.scrollTo({ top: 0, left: (bills.offsetLeft - (nav.offsetWidth / 2) + (bills.offsetWidth / 2)), behavior: 'smooth' });
    break;
  case 'equipments':
    if (equipments && equipments.offsetLeft && nav) nav.scrollTo({ top: 0, left: (equipments.offsetLeft - (nav.offsetWidth / 2) + (equipments.offsetWidth / 2)), behavior: 'smooth' });
    break;
  case 'passages':
    if (passages && passages.offsetLeft && nav) nav.scrollTo({ top: 0, left: (passages.offsetLeft - (nav.offsetWidth / 2) + (passages.offsetWidth / 2)), behavior: 'smooth' });
    break;
  default:
    break;
  }

  return (
    <div className="submenu-wrapper">
      <nav ref={ref => setNavref(ref)} className="submenu">
        <ul>
          <BONavLink setRef={setSitesRef} url={`${baseUrl}/sites`} title="Sites" />
          <BONavLink setRef={setContactsRef} url={`${baseUrl}/contacts`} title="Contacts" />
          <BONavLink setRef={setContractsRef} url={`${baseUrl}/contracts`} title="Contrats" />
          <BONavLink setRef={setDocumentsRef} url={`${baseUrl}/documents`} title="Documents" />
          <BONavLink setRef={setBillsRef} url={`${baseUrl}/bills`} title="Factures" />
          <BONavLink setRef={setEquipmentsRef} url={`${baseUrl}/equipments`} title="Equipements" />
          <BONavLink setRef={setPassagesRef} url={`${baseUrl}/passages`} title="Passages" />
        </ul>
      </nav>
    </div>
  );
};

BOHeader.propTypes = {
  baseUrl: PropTypes.string.isRequired,

  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(BOHeader);
