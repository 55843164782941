import React from "react";
import PropTypes from "prop-types";
import { withRouter, NavLink } from "react-router-dom";
import { get, pick, join, values } from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import { ButtonConfirmModal } from "react-components-linaia";

import { isSupport } from "../../utils/RolesUtils";

import { refreshPassages } from "../../store/modules/passages";

import createSelector from "../../selectors/ClientSubEntitiesSelector";

import TableListView from "../shared/TableListView";

const clientEquipmentsSelector = createSelector();

const PassagesTableListView = ({
  history: { push },
  passages,
  isAdmin,
  baseUrl,
  refreshPassages,
  onClickDelete,
  sites,
  client_id,
  user,
  contracts,
  technicians,
}) => {
  return (
    <TableListView
      className="table-boxed full-height"
      data={passages}
      cols={{
        passageDate: {
          label: "Date",
          className: "col-0_sm-12 col-date",
          render: (date) => date && moment(date).format("DD/MM/YYYY"),
        },
        passageSite: {
          label: "Site",
          className: "col_sm-12",
          value: (id) => get(sites, `${id}.siteName`),
        },
        passageContract: {
          label: "Contrat",
          className: "col_sm-12",
          value: (id) => get(contracts, `${id}.contractLabel`),
        },
        passageTechnicien: {
          label: "Technicien",
          className: "col_sm-12",
          render: (id) =>
            join(values(pick(technicians[id], ["technicianFirstname", "technicianLastname"])), " "),
        },
      }}
      search={["passageContract", "passageSite"]}
      searchLabel="Rechercher un passage"
      sortByDefault="passageDate"
      sortOrderDefault="desc"
      buttons={
        <div>
          <NavLink className="btn btn-main-mobile" to={`${baseUrl}/new`}>
            <span>Ajouter un passage</span>
            <i className="far fa-plus" />
          </NavLink>
        </div>
      }
      buttonsRefresh={
        isAdmin &&
        isSupport(user) && (
          <button
            type="button"
            className="btn btn-main-mobile"
            onClick={() => refreshPassages(client_id)}
          >
            <span>Refresh les passages</span>
            <i className="far fa-sync" />
          </button>
        )
      }
      rowButtons={(passage) => (
        <div className="col-2_sm-12">
          <div className="td td-buttons">
            {passage && (
              <NavLink className="btn" to={`${baseUrl}/${passage.passage_id}/edit`}>
                <i className="far fa-pencil" />
              </NavLink>
            )}
            {passage && (
              <ButtonConfirmModal
                onConfirmationButton={() => onClickDelete(passage.passage_id)}
                message="Voulez-vous vraiment supprimer ce passage ?"
              >
                <button className="btn" type="button">
                  <i className="far fa-trash" />
                </button>
              </ButtonConfirmModal>
            )}
          </div>
        </div>
      )}
    />
  );
};

PassagesTableListView.propTypes = {
  passages: PropTypes.objectOf(
    PropTypes.shape({
      passage_id: PropTypes.number.isRequired,
      passageDate: PropTypes.string.isRequired,
      passageSite: PropTypes.number.isRequired,
      passageContract: PropTypes.number.isRequired,
      passageTechnicien: PropTypes.number.isRequired,
      passageEquipments: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
    })
  ).isRequired,

  sites: PropTypes.objectOf(PropTypes.shape()).isRequired,
  contracts: PropTypes.objectOf(PropTypes.shape()).isRequired,
  technicians: PropTypes.objectOf(PropTypes.shape()).isRequired,

  isAdmin: PropTypes.bool,
  baseUrl: PropTypes.string.isRequired,
  onClickDelete: PropTypes.func,

  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect(
  (state, { clientId }) => ({
    sites: state.data.entities.sites,
    user: state.auth.user,
    contracts: state.data.entities.contracts,
    technicians: state.data.entities.technicians,
    equipments: clientEquipmentsSelector(
      state.data.entities.equipments,
      clientId,
      "equipmentContract",
      state.data.entities.contracts,
      "contractClient"
    ),
    client_id: clientId,
  }),
  {
    refreshPassages,
  }
)(withRouter(PassagesTableListView));
