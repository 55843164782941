import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Modal from "react-modal";
import { withRouter, Switch, Route } from "react-router-dom";
import { get, values } from "lodash";

import createSelector from "../../selectors/ClientSubEntitiesSelector";

import { createOrUpdatePassage, deletePassage } from "../../store/modules/passages";

import PassageDetailView from "../../components/passages/PassageDetailView";
import PassageEditForm from "../../components/passages/PassageEditForm";
import PassagesTableListView from "../../components/passages/PassagesTableListView";

import modalDefaultClass from "../../utils/ModalDefaultClass";

const clientPassagesSelector = createSelector();
const clientSitesSelector = createSelector();

const RenderEditDetailsModalsRouter = ({
  url,
  editPassage,
  delPassage,
  push,
  passages,
  sites,
  clientId,
}) => (
  <Switch>
    <Route
      exact
      path={`${url}/new`}
      render={() => (
        <Modal
          {...modalDefaultClass}
          isOpen
          onRequestClose={() => push(url)}
          className="modal-content big-modal"
        >
          <PassageEditForm
            initialValues={{
              passageSite: values(sites).length === 1 ? values(sites)[0].site_id : null,
            }}
            onSubmit={(v) => {
              editPassage(v).then(() => push(url));
            }}
            baseUrl={url}
            clientId={clientId}
          />
        </Modal>
      )}
    />
    <Route
      exact
      path={`${url}/:passageId/edit`}
      render={(subProps) =>
        get(passages, subProps.match.params.passageId, null) && (
          <Modal
            {...modalDefaultClass}
            isOpen
            onRequestClose={() => push(url)}
            className="modal-content big-modal"
          >
            <PassageEditForm
              initialValues={passages[subProps.match.params.passageId]}
              onSubmit={(v) => {
                editPassage(v).then(() => push(url));
              }}
              baseUrl={url}
              clientId={clientId}
            />
          </Modal>
        )
      }
    />
    <Route
      exact
      path={`${url}/:passageId`}
      render={(subProps) =>
        get(passages, subProps.match.params.passageId, null) && (
          <Modal
            {...modalDefaultClass}
            isOpen
            onRequestClose={() => push(url)}
            className="modal-content big-modal"
          >
            <PassageDetailView
              passage={passages[subProps.match.params.passageId]}
              baseUrl={url}
              onClickDelete={() => {
                delPassage(subProps.match.params.passageId).then(() => push(url));
              }}
              clientId={clientId}
            />
          </Modal>
        )
      }
    />
  </Switch>
);

RenderEditDetailsModalsRouter.propTypes = {
  url: PropTypes.string.isRequired,
  editPassage: PropTypes.func.isRequired,
  delPassage: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  passages: PropTypes.objectOf(PropTypes.shape()).isRequired,
  sites: PropTypes.objectOf(PropTypes.shape()).isRequired,
  clientId: PropTypes.number.isRequired,
};

const PassagesContainer = ({
  clientId,
  isAdmin,
  match: { url },
  history: { push },
  passages,
  sites,
  ...props
}) => (
  <Fragment>
    <PassagesTableListView
      passages={passages}
      isAdmin={isAdmin}
      clientId={clientId}
      baseUrl={url}
      onClickDelete={(passageId) => {
        props.deletePassage(passageId).then(() => push(url));
      }}
    />
    <RenderEditDetailsModalsRouter
      url={url}
      editPassage={props.createOrUpdatePassage}
      delPassage={props.deletePassage}
      push={push}
      passages={passages}
      sites={sites}
      clientId={clientId}
    />
  </Fragment>
);

PassagesContainer.propTypes = {
  clientId: PropTypes.number.isRequired,

  createOrUpdatePassage: PropTypes.func.isRequired,
  deletePassage: PropTypes.func.isRequired,

  passages: PropTypes.objectOf(PropTypes.shape()).isRequired,
  sites: PropTypes.objectOf(PropTypes.shape()).isRequired,
  isAdmin: PropTypes.bool,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect(
  (state, { clientId }) => ({
    passages: clientPassagesSelector(
      state.data.entities.passages,
      clientId,
      "passageContract",
      state.data.entities.contracts,
      "contractClient"
    ),
    sites: clientSitesSelector(state.data.entities.sites, clientId, "siteClient"),
  }),
  { createOrUpdatePassage, deletePassage }
)(withRouter(PassagesContainer));
