import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, NavLink } from 'react-router-dom';
import { capitalize } from 'lodash';
import { ButtonConfirmModal } from 'react-components-linaia';

import TableListView from '../shared/TableListView';

const SharedcategorysTableListView = ({
  history: { push }, sharedcategorys, baseUrl, onClickDelete,
}) => (
  <TableListView
    className="table-unboxed"
    data={sharedcategorys}
    cols={{
      sharedcategoryName: {
        label: 'Nom',
        className: 'col_sm-12',
        render: capitalize,
      },
    }}
    hideTableHeader
    title="Catégories documents partagés"
    buttons={(
      <NavLink className="btn" to={`${baseUrl}/new`}>
        <i className="fas fa-plus" />
      </NavLink>
    )}
    rowButtons={sharedcategory => (
      <div className="col-0_sm-12 cln-2btn">
        <div className="td td-buttons">
          {sharedcategory && (
            <NavLink className="btn" to={`${baseUrl}/${sharedcategory.sharedcategory_id}/edit`}>
              <i className="far fa-pencil" />
            </NavLink>
          )}
          {sharedcategory && (
            <ButtonConfirmModal
              onConfirmationButton={() => onClickDelete(sharedcategory.sharedcategory_id)}
              message="Voulez-vous vraiment supprimer cette catégorie ?"
            >
              <button className="btn" type="button">
                <i className="far fa-trash" />
              </button>
            </ButtonConfirmModal>
          )}
        </div>
      </div>
    )}
  />
);

SharedcategorysTableListView.propTypes = {
  sharedcategorys: PropTypes.objectOf(PropTypes.shape({
    sharedcategory_id: PropTypes.number.isRequired,
    sharedcategoryName: PropTypes.string.isRequired,
  })).isRequired,

  baseUrl: PropTypes.string.isRequired,
  onClickDelete: PropTypes.func,

  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(SharedcategorysTableListView);
