import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { NavLink } from 'react-router-dom';
import { isNumber, get } from 'lodash';
import {
  Field, DateField, FileField, InputField, SelectField,
} from 'react-components-linaia';

import { uploadFiles, removeFile } from '../../store/modules/uploads';

import { UPLOADS_URL, ACCEPTED_FILES, DOCUMENT_OPTIONS } from '../../constants/Config';

const ShareddocumentEditForm = ({
  handleSubmit, baseUrl, initialValues, upFiles, rmFile, sharedcategorys, shareddocumentType
}) => (
  <Fragment>
    <div className="modal-title">
      <div className="modal-title-content">
        {initialValues.shareddocument_id >= 0 ? 'Modifier le document' : 'Ajouter un document'}
        <NavLink className="btn" to={baseUrl}>
          <i className="fal fa-times" />
        </NavLink>
      </div>
    </div>
    <form onSubmit={handleSubmit}>
      <div className="modal-body">
        <div className="grid">
          <Field name="shareddocumentName" component={InputField} label="Nom du document" className="col-12" />
          <Field
            name="shareddocumentType"
            component={SelectField}
            label="Type de document"
            className="col-12"
            options={DOCUMENT_OPTIONS}
          />
          {shareddocumentType === 'fichier' && (
            <Field
              name="shareddocumentFile"
              component={FileField}
              uploadFiles={upFiles}
              removeFile={rmFile}
              getUrl={item => `${UPLOADS_URL}/${item}`}
              buttonLabel="Choisir un Fichier"
              className="col-12"
              acceptedFiles={ACCEPTED_FILES}
            />
          )}
          {shareddocumentType === 'lien' && (
            <Field
              name="shareddocumentFile"
              component={InputField}
              uploadFiles={upFiles}
              removeFile={rmFile}
              getUrl={item => `${item}`}
              label="Choisir un URL"
              className="col-12"
            />
          )}
          <Field
            name="shareddocumentCategory"
            component={SelectField}
            options={sharedcategorys}
            optionsKeys={{ value: 'sharedcategory_id', label: 'sharedcategoryName' }}
            label="Catégorie"
            className="col-12"
          />
          <Field
            name="shareddocumentStartDate"
            component={DateField}
            label="Début"
            className="col-6"
            displayFormat="dd/MM/yyyy"
          />
          <Field
            name="shareddocumentEndDate"
            component={DateField}
            label="Fin"
            className="col-6"
            displayFormat="dd/MM/yyyy"
          />
        </div>
      </div>
      <div className="modal-footer">
        <div className="btn-group center">
          <button className="btn btn-primary" type="submit">
            Enregistrer
          </button>
        </div>
      </div>
    </form>
  </Fragment>
);

ShareddocumentEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
  initialValues: PropTypes.shape().isRequired,
  shareddocumentType: PropTypes.string.isRequired,

  sharedcategorys: PropTypes.objectOf(PropTypes.shape()).isRequired,

  upFiles: PropTypes.func.isRequired,
  rmFile: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'ShareddocumentEditForm',
  validate: (values = {}) => {
    const errors = {};

    if (!values.shareddocumentName) errors.shareddocumentName = 'Merci de renseigner le nom du document';
    if (!values.shareddocumentType) errors.shareddocumentType = 'Merci de renseigner le type de document';
    if (!isNumber(values.shareddocumentCategory)) errors.shareddocumentCategory = 'Merci de renseigner la catégorie du document';
    if (!values.shareddocumentFile) errors.shareddocumentFile = 'Merci de choisir un document';

    return errors;
  },
})(connect(
  state => ({
    sharedcategorys: state.data.entities.sharedcategorys,
    shareddocumentType: get(state, 'form.ShareddocumentEditForm.values.shareddocumentType'),
  }),
  { upFiles: uploadFiles, rmFile: removeFile },
)(ShareddocumentEditForm));
