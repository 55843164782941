import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { map, indexOf, sortBy, pickBy, values, sum } from "lodash";
import moment from "moment";

import Chart3Lines from "../../charts/Chart3Lines";
import ChartSingleBar from "../../charts/ChartSingleBar";
import ChartSinglePie from "../../charts/ChartSinglePie";

const ReportsGraphView = ({ contract, passages, equipments }) => {
  equipments = pickBy(equipments, (eq) => eq.equipmentType !== "remarqueLibre");

  if (!contract || !contract.contractType) return null;

  const sortedPassages = sortBy(passages, (pa) => pa.passageDate);

  if (contract.contractType === "deratization") {
    const passagesLabels = [];
    const eqConsumed = [];
    const eqUnconsumed = [];
    const eqInaccessible = [];
    // const eqDeterioratedRenewed = [];
    //const eqDisappearedReplaced = [];

    let locationsLastPassage = null;
    let locationsLastLabels = [];
    let locationsLastConsumed = [];
    let locationsAllLabels = [];
    let locationsAllConsumed = [];
    map(sortedPassages, (p) => {
      if (
        p.passageContract === contract.contract_id &&
        moment(p.passageDate).diff(moment(), "year") === 0
      ) {
        passagesLabels.push(moment(p.passageDate).format("D MMM"));
        const index = passagesLabels.length - 1;
        eqConsumed[index] = 0;
        eqUnconsumed[index] = 0;
        eqInaccessible[index] = 0;
        if (!locationsLastPassage || moment(p.passageDate).isAfter(locationsLastPassage)) {
          locationsLastPassage = moment(p.passageDate);
          locationsLastLabels = [];
          locationsLastConsumed = [];
        }
        map(p.passageEquipments, (pe) => {
          if (
            !equipments[pe.equipment] ||
            equipments[pe.equipment].equipmentType !== "baitStation"
          ) {
            return null;
          }
          if (pe.equipmentState === "consumedRenewed") {
            eqConsumed[index] += 1;
            if (indexOf(locationsAllLabels, equipments[pe.equipment].equipmentLocation) === -1) {
              locationsAllLabels.push(equipments[pe.equipment].equipmentLocation);
              locationsAllConsumed[
                indexOf(locationsAllLabels, equipments[pe.equipment].equipmentLocation)
              ] = 0;
            }
            locationsAllConsumed[
              indexOf(locationsAllLabels, equipments[pe.equipment].equipmentLocation)
            ] += 1;
            if (moment(p.passageDate).isSame(locationsLastPassage)) {
              if (indexOf(locationsLastLabels, equipments[pe.equipment].equipmentLocation) === -1) {
                locationsLastLabels.push(equipments[pe.equipment].equipmentLocation);
                locationsLastConsumed[
                  indexOf(locationsLastLabels, equipments[pe.equipment].equipmentLocation)
                ] = 0;
              }
              locationsLastConsumed[
                indexOf(locationsLastLabels, equipments[pe.equipment].equipmentLocation)
              ] += 1;
            }
          } else if (pe.equipmentState === "unconsumed") {
            eqUnconsumed[index] += 1;
          } else if (pe.equipmentState === "deterioratedRenewed") {
            eqUnconsumed[index] += 1;
          } else if (pe.equipmentState === "inaccessible") {
            eqInaccessible[index] += 1;
          } else if (pe.equipmentState === "disappearedReplaced") {
            eqInaccessible[index] += 1;
          }
          return null;
        });
      }
    });
    // Code permettant de trier en reverse sur les équipements avec les 10 premiers équipements (slice)
    locationsLastConsumed = sortBy(locationsLastConsumed).reverse().slice(0, 8);
    locationsAllConsumed = sortBy(locationsAllConsumed).reverse().slice(0, 8);
    locationsLastLabels = sortBy(locationsLastLabels).reverse().slice(0, 8);
    locationsAllLabels = sortBy(locationsAllLabels).reverse().slice(0, 8);

    return (
      <div className="col-9_sm-12">
        <div className="grid-equalHeight">
          <div className="col-12">
            <div className="box graph-box">
              <div className="box-header">
                <div className="box-title">Évolution du nombre de postes consommés / de captures</div>
              </div>
              <div className="box-content">
                <Chart3Lines
                  labels={passagesLabels}
                  lineLabels={["Postes consommés", "Postes non consommés", "Postes inaccessibles"]}
                  data={[eqConsumed, eqUnconsumed, eqInaccessible]}
                  colors={[
                    {
                      background: "rgba(200,50,50,0.2)",
                      border: "rgba(200,50,50,0.8)",
                    },
                    {
                      background: "rgba(50,200,50,0.2)",
                      border: "rgba(50,200,50,0.8)",
                    },
                    {
                      background: "rgba(50,50,200,0.2)",
                      border: "rgba(50,50,200,0.8)",
                    },
                  ]}
                />
              </div>
            </div>
          </div>
          <div className="col-6_sm-12">
            <div className="box graph-box graph-pie-box">
              <div className="box-header">
                <div className="box-title">
                  Répartition par zone du nombre de postes consommés / de captures au dernier passage
                </div>
              </div>
              <div className="box-content">
                <ChartSinglePie labels={locationsLastLabels} data={locationsLastConsumed} />
              </div>
            </div>
          </div>
          <div className="col-6_sm-12">
            <div className="box graph-box graph-pie-box">
              <div className="box-header">
                <div className="box-title">
                  Répartition par zone du nombre de postes consommés / de captures sur 1 an glissant
                </div>
              </div>
              <div className="box-content">
                <ChartSinglePie labels={locationsAllLabels} data={locationsAllConsumed} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (contract.contractType === "DEIV") {
    const passagesLabels = [];
    const eqNumber = [];
    const equipmentsGraphs = {};
    map(sortedPassages, (p) => {
      if (
        p.passageContract === contract.contract_id &&
        moment(p.passageDate).diff(moment(), "year") === 0
      ) {
        passagesLabels.push(moment(p.passageDate).format("D MMM"));
        const index = passagesLabels.length - 1;
        eqNumber[index] = 0;
        map(p.passageEquipments, (pe) => {
          if (!equipments[pe.equipment] || equipments[pe.equipment].equipmentType !== "DEIV") {
            return null;
          }
          eqNumber[index] += sum(map(values(pe.equipmentState), (v) => parseInt(v, 10)));
          if (!equipmentsGraphs[pe.equipment]) {
            equipmentsGraphs[pe.equipment] = {
              labels: [],
              eqNumber: [],
            };
          }
          equipmentsGraphs[pe.equipment].labels.push(passagesLabels[index]);
          equipmentsGraphs[pe.equipment].eqNumber.push(
            sum(map(values(pe.equipmentState), (v) => parseInt(v, 10)))
          );
          return null;
        });
      }
    });
    return (
      <div className="col-9_sm-12">
        <div className="grid">
          <div className="col-12">
            <div className="box graph-box">
              <div className="box-header">
                <div className="box-title">Évolution du nombre d'insectes capturés</div>
              </div>
              <div className="box-content">
                <ChartSingleBar labels={passagesLabels} data={eqNumber} />
              </div>
            </div>
          </div>
          {map(equipmentsGraphs, (eqGraph, eq) => (
            <div key={eq} className="col-6_sm-12">
              <div className="box graph-box">
                <div className="box-header">
                  <div className="box-title">
                    Nombre d'insectes capturés
                    <br />
                    <div className="subtitle">
                      {equipments[eq].equipmentPostNumber}
                      {equipments[eq].equipmentName}
                      &nbsp;(
                      <span className="lowercase">{equipments[eq].equipmentLocation}</span>)
                    </div>
                  </div>
                </div>
                <div className="box-content">
                  <ChartSingleBar labels={eqGraph.labels} data={eqGraph.eqNumber} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (contract.contractType === "mothsTrap") {
    const passagesLabels = [];
    const eqNumber = [];
    let equipmentsLastPassage = null;
    let equipmentsLastLabels = [];
    let equipmentsLastNumber = [];
    const equipmentsAllLabels = [];
    const equipmentsAllNumber = [];
    map(sortedPassages, (p) => {
      if (
        p.passageContract === contract.contract_id &&
        moment(p.passageDate).diff(moment(), "year") === 0
      ) {
        passagesLabels.push(moment(p.passageDate).format("D MMM"));
        const index = passagesLabels.length - 1;
        eqNumber[index] = 0;
        if (!equipmentsLastPassage || moment(p.passageDate).isAfter(equipmentsLastPassage)) {
          equipmentsLastPassage = moment(p.passageDate);
          equipmentsLastLabels = [];
          equipmentsLastNumber = [];
        }
        map(p.passageEquipments, (pe) => {
          if (!equipments[pe.equipment] || equipments[pe.equipment].equipmentType !== "mothsTrap") {
            return null;
          }
          eqNumber[index] += sum(map(values(pe.equipmentState), (v) => parseInt(v, 10)));
          if (indexOf(equipmentsAllLabels, equipments[pe.equipment].equipmentName) === -1) {
            equipmentsAllLabels.push(equipments[pe.equipment].equipmentName);
            equipmentsAllNumber[
              indexOf(equipmentsAllLabels, equipments[pe.equipment].equipmentName)
            ] = 0;
          }
          equipmentsAllNumber[
            indexOf(equipmentsAllLabels, equipments[pe.equipment].equipmentName)
          ] += sum(map(values(pe.equipmentState), (v) => parseInt(v, 10)));
          if (moment(p.passageDate).isSame(equipmentsLastPassage)) {
            if (indexOf(equipmentsLastLabels, equipments[pe.equipment].equipmentName) === -1) {
              equipmentsLastLabels.push(equipments[pe.equipment].equipmentName);
              equipmentsLastNumber[
                indexOf(equipmentsLastLabels, equipments[pe.equipment].equipmentName)
              ] = 0;
            }
            equipmentsLastNumber[
              indexOf(equipmentsLastLabels, equipments[pe.equipment].equipmentName)
            ] += sum(map(values(pe.equipmentState), (v) => parseInt(v, 10)));
          }
          return null;
        });
      }
    });
    return (
      <div className="col-9_sm-12">
        <div className="grid">
          <div className="col-12">
            <div className="box graph-box">
              <div className="box-header">
                <div className="box-title">Évolution du nombre de teignes capturées</div>
              </div>
              <div className="box-content">
                <ChartSingleBar labels={passagesLabels} data={eqNumber} />
              </div>
            </div>
          </div>
          <div className="col-6_sm-12">
            <div className="box graph-box graph-pie-box">
              <div className="box-header">
                <div className="box-title">Teignes capturées dernier passage</div>
              </div>

              {equipmentsLastNumber.length > 1 && (
                <div className="box-content">
                  <ChartSinglePie labels={equipmentsLastLabels} data={equipmentsLastNumber} />
                </div>
              )}
              <div className="graph-box-numberdata">
                {equipmentsLastNumber.length === 1 && equipmentsLastNumber[0]}
              </div>
            </div>
          </div>
          <div className="col-6_sm-12">
            <div className="box graph-box graph-pie-box">
              <div className="box-header">
                <div className="box-title">Teignes capturées sur 1 an</div>
              </div>

              {equipmentsAllNumber.length > 1 && (
                <div className="box-content">
                  <ChartSinglePie labels={equipmentsAllLabels} data={equipmentsAllNumber} />
                </div>
              )}

              <div className="graph-box-numberdata">
                {equipmentsAllNumber.length === 1 && equipmentsAllNumber[0]}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
};

ReportsGraphView.propTypes = {
  contract: PropTypes.shape(),
  passages: PropTypes.objectOf(PropTypes.shape()),
  equipments: PropTypes.objectOf(PropTypes.shape()),
};

export default connect((state) => ({
  passages: state.data.entities.passages,
  equipments: state.data.entities.equipments,
}))(ReportsGraphView);
