import React from "react";
import PropTypes from "prop-types";
import { Line } from "react-chartjs-2";
import "chartjs-plugin-colorschemes";

const ChartSingleLine = ({ labels, data, height }) => (
  <Line
    height={height}
    data={{
      labels,
      datasets: [
        {
          data,
          borderWidth: 2,
          pointRadius: 6,
          lineTension: 0.5,
        },
      ],
    }}
    options={{
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 10,
          bottom: 0,
        },
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
              drawBorder: false,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              beginAtZero: true,
              display: false,
            },
          },
        ],
      },
      legend: {
        display: false,
      },
      plugins: {
        colorschemes: {
          scheme: "office.Twilight6",
        },
      },
    }}
  />
);

ChartSingleLine.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default ChartSingleLine;
