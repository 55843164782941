import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { withRouter, Switch, Route } from 'react-router-dom';
import { get } from 'lodash';

import createSelector from '../../selectors/ClientSubEntitiesSelector';

import { createOrUpdateSite, deleteSite } from '../../store/modules/sites';

import SiteDetailView from '../../components/sites/SiteDetailView';
import SiteEditForm from '../../components/sites/SiteEditForm';
import SitesCardListView from '../../components/sites/SitesCardListView';

import modalDefaultClass from '../../utils/ModalDefaultClass';

const clientSubEntitiesSelector = createSelector();

const RenderEditDetailsModalsRouter = ({
  url, editSite, delSite, push, sites, clientId,
}) => (
  <Switch>
    <Route
      exact
      path={`${url}/new`}
      render={() => (
        <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)} className="modal-content medium-modal">
          <SiteEditForm
            initialValues={{
              siteClient: clientId,
            }}
            onSubmit={(v) => {
              editSite(v)
              .then(() => push(url));
            }}
            baseUrl={url}
          />
        </Modal>
      )}
    />
    <Route
      exact
      path={`${url}/:siteId/edit`}
      render={subProps => get(sites, subProps.match.params.siteId, null) && (
        <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)} className="modal-content medium-modal">
          <SiteEditForm
            initialValues={sites[subProps.match.params.siteId]}
            onSubmit={(v) => {
              editSite(v)
              .then(() => push(url));
            }}
            baseUrl={url}
          />
        </Modal>
      )}
    />
    <Route
      exact
      path={`${url}/:siteId`}
      render={subProps => get(sites, subProps.match.params.siteId, null) && (
        <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
          <SiteDetailView
            site={sites[subProps.match.params.siteId]}
            baseUrl={url}
            onClickDelete={() => {
              delSite(subProps.match.params.siteId)
              .then(() => push(url));
            }}
          />
        </Modal>
      )}
    />
  </Switch>
);

RenderEditDetailsModalsRouter.propTypes = {
  url: PropTypes.string.isRequired,
  editSite: PropTypes.func.isRequired,
  delSite: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  sites: PropTypes.objectOf(PropTypes.shape()).isRequired,
  clientId: PropTypes.number.isRequired,
};

const SitesAdminContainer = ({
  clientId, match: { url }, history: { push }, sites, ...props
}) => (
  <Fragment>
    <SitesCardListView
      sites={sites}
      baseUrl={url}
      onClickDelete={(siteId) => {
        props.deleteSite(siteId)
        .then(() => push(url));
      }}
    />
    <RenderEditDetailsModalsRouter
      url={url}
      editSite={props.createOrUpdateSite}
      delSite={props.deleteSite}
      push={push}
      sites={sites}
      clientId={clientId}
    />
  </Fragment>
);

SitesAdminContainer.propTypes = {
  clientId: PropTypes.number.isRequired,

  createOrUpdateSite: PropTypes.func.isRequired,
  deleteSite: PropTypes.func.isRequired,

  sites: PropTypes.objectOf(PropTypes.shape()).isRequired,

  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect(
  (state, { clientId }) => ({
    sites: clientSubEntitiesSelector(state.data.entities.sites, clientId, 'siteClient'),
  }),
  { createOrUpdateSite, deleteSite },
)(withRouter(SitesAdminContainer));
