import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { ButtonConfirmModal } from "react-components-linaia";

import CardListView from "../shared/CardListView";

const ClientsCardListView = ({ clients, baseUrl, onClickDelete }) => (
  <CardListView
    className="card-list-clients linked-cards"
    data={clients}
    cardComponent={client => (
      <div className="col-3_xs-12_sm-6_md-4">
        <div className="box card simple-card">
          <div className="box-content">
            <NavLink
              to={`${baseUrl}/${client.client_id}/manage/sites`}
              className="grid-noBottom"
            >
              <div className="col-12">
                <div className="card-title">{client.clientName}</div>
              </div>
              {client.clientReference && (
                <div className="col-12">{client.clientReference}</div>
              )}
              {client.clientOutstanding > 0 && (
                <div className="col-12">{client.clientOutstanding}€</div>
              )}
            </NavLink>
            <div className="card-actions btn-group">
              <NavLink
                to={`${baseUrl}/${client.client_id}/edit`}
                className="btn"
              >
                <i className="far fa-pencil" />
                <span>Modifier</span>
              </NavLink>
              {onClickDelete && (
                <ButtonConfirmModal
                  onConfirmationButton={() => onClickDelete(client.client_id)}
                  message="Voulez-vous vraiment supprimer ce client ?"
                >
                  <button className="btn" type="button">
                    <i className="far fa-trash" />
                    <span>Supprimer</span>
                  </button>
                </ButtonConfirmModal>
              )}
            </div>
          </div>
        </div>
      </div>
    )}
    search={["clientName", "clientReference"]}
    searchLabel="Rechercher un client"
    sortByDefault="clientName"
    buttons={
      <div>
        <NavLink className="btn btn-main-mobile" to={`${baseUrl}/new`}>
          <span>Ajouter un client</span>
          <i className="far fa-plus" />
        </NavLink>
      </div>
    }
  />
);

ClientsCardListView.propTypes = {
  clients: PropTypes.objectOf(
    PropTypes.shape({
      client_id: PropTypes.number.isRequired,
      clientName: PropTypes.string.isRequired,
      clientOutstanding: PropTypes.number
    })
  ).isRequired,

  baseUrl: PropTypes.string.isRequired,
  onClickDelete: PropTypes.func
};

export default ClientsCardListView;
