import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { NavLink } from 'react-router-dom';
import {
  Field, DateField, FileField, InputField, TextareaField, ArrayField,
} from 'react-components-linaia';

import { uploadFiles, removeFile } from '../../store/modules/uploads';

import { UPLOADS_URL } from '../../constants/Config';

const ActualityEditForm = ({
  handleSubmit, baseUrl, initialValues, upFiles, rmFile,
}) => (
  <Fragment>
    <div className="modal-title">
      <div className="modal-title-content">
        {initialValues && initialValues.actuality_id >= 0 ? 'Modifier l\'actualité' : 'Ajouter une actualité'}
        <NavLink className="btn" to={baseUrl}>
          <i className="fal fa-times" />
        </NavLink>
      </div>
    </div>
    <form onSubmit={handleSubmit}>
      <div className="modal-body">
        <div className="grid-noBottom">
          <div className="col-9_xs-12">
            <div className="grid">
              <Field name="actualityTitle" component={InputField} label="Titre" className="col-12" />
              <Field name="actualitySummary" component={TextareaField} label="Résumé" className="col-12" />
              <Field name="actualityContent" component={TextareaField} label="Contenu" className="col-12" isWysiwyg />
            </div>
          </div>
          <div className="col-3_xs-12">
            <div className="grid">
              <Field name="actualityStartDate" component={DateField} label="Début" className="col-date" displayFormat="dd/MM/yyyy" />
              <Field name="actualityEndDate" component={DateField} label="Fin" className="col-date" displayFormat="dd/MM/yyyy" />
              <Field
                name="actualityThumbnail"
                component={FileField}
                uploadFiles={upFiles}
                removeFile={rmFile}
                getUrl={item => `${UPLOADS_URL}/${item}`}
                label="Miniature"
                className="col-12"
              />
              <Field
                name="actualityFiles"
                component={FileField}
                uploadFiles={upFiles}
                removeFile={rmFile}
                getUrl={item => `${UPLOADS_URL}/${item}`}
                label="Pièces-jointes"
                buttonLabel="Importer une pièce-jointe"
                className="col-12"
                isMulti
              />
              <ArrayField
                name="actualityLinks"
                label="Liens externes"
                className="col-12"
                addLabel="Ajouter un lien (+)"
                removable
                component={({ field, onClickRemove }) => (
                  <div className="grid-equalHeight">
                    <Field name={field} component={InputField} placeholder="URL" className="col" />
                    <div className="col-0">
                      <button type="button" className="btn btn-primary" onClick={onClickRemove}><i className="far fa-times" /></button>
                    </div>
                  </div>
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <div className="btn-group center">
          <button className="btn btn-primary" type="submit">
            Enregistrer
          </button>
        </div>
      </div>
    </form>
  </Fragment>
);

ActualityEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
  initialValues: PropTypes.shape().isRequired,

  upFiles: PropTypes.func.isRequired,
  rmFile: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'ActualityEditForm',
  validate: (values = {}) => {
    const errors = {};

    if (!values.actualityStartDate) errors.actualityStartDate = 'Merci de renseigner la date de début de publication';
    if (!values.actualityTitle) errors.actualityTitle = 'Merci de renseigner le titre';
    if (!values.actualitySummary) errors.actualitySummary = 'Merci de renseigner le chapô';
    if (!values.actualityContent) errors.actualityContent = 'Merci de renseigner le contenu';

    return errors;
  },
})(connect(
  null,
  { upFiles: uploadFiles, rmFile: removeFile },
)(ActualityEditForm));
