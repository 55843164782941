import React, { forwardRef } from "react";
// import "./styles.css

export default forwardRef(({ children, heading }, ref) => {
  return (
    <div ref={ref}>
      {children}
    </div>
  );
});
