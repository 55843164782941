import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { pickBy } from 'lodash';

import ContractsCardListView from '../../components/contracts/ContractsCardListView';

const ContractsSiteContainer = ({
  match: { url }, contracts,
}) => (
  <Fragment>
    <ContractsCardListView
      contracts={contracts}
      baseUrl={url}
    />
  </Fragment>
);

ContractsSiteContainer.propTypes = {
  contracts: PropTypes.objectOf(PropTypes.shape()).isRequired,

  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default connect(
  (state, { siteId }) => ({
    contracts: pickBy(
      state.data.entities.contracts,
      d => d.contractAffectedSite === siteId,
    ),
  }),
)(withRouter(ContractsSiteContainer));
