import { flow, set } from 'lodash/fp';
import { omit } from 'lodash';

import { CALL_API, Schemas } from '../Schemas';

const FETCH_ALL_CATEGORYS_REQUEST = 'fgwa/categorys/FETCH_ALL_CATEGORYS_REQUEST';
export const FETCH_ALL_CATEGORYS_SUCCESS = 'fgwa/categorys/FETCH_ALL_CATEGORYS_SUCCESS';
const FETCH_ALL_CATEGORYS_FAILURE = 'fgwa/categorys/FETCH_ALL_CATEGORYS_FAILURE';
const FETCH_CATEGORY_REQUEST = 'fgwa/categorys/FETCH_CATEGORY_REQUEST';
const FETCH_CATEGORY_SUCCESS = 'fgwa/categorys/FETCH_CATEGORY_SUCCESS';
const FETCH_CATEGORY_FAILURE = 'fgwa/categorys/FETCH_CATEGORY_FAILURE';
const SET_CATEGORY_REQUEST = 'fgwa/categorys/SET_CATEGORY_REQUEST';
const SET_CATEGORY_SUCCESS = 'fgwa/categorys/SET_CATEGORY_SUCCESS';
const SET_CATEGORY_FAILURE = 'fgwa/categorys/SET_CATEGORY_FAILURE';
const DELETE_CATEGORY_REQUEST = 'fgwa/categorys/DELETE_CATEGORY_REQUEST';
const DELETE_CATEGORY_SUCCESS = 'fgwa/categorys/DELETE_CATEGORY_SUCCESS';
const DELETE_CATEGORY_FAILURE = 'fgwa/categorys/DELETE_CATEGORY_FAILURE';

export const categorysActionsHandlers = {
  [FETCH_ALL_CATEGORYS_REQUEST]: state => flow(
    set('loaded.categorys', false),
    set('loading.categorys', true),
  )(state),
  [FETCH_ALL_CATEGORYS_SUCCESS]: (state, action) => flow(
    set('entities.categorys', action.response.entities.categorys || {}),
    set('loaded.categorys', true),
    set('loading.categorys', false),
  )(state),
  [FETCH_ALL_CATEGORYS_FAILURE]: state => flow(
    set('loaded.categorys', false),
    set('loading.categorys', false),
  )(state),
  [FETCH_CATEGORY_SUCCESS]: (state, action) => flow(
    set(`entities.categorys.${action.id}`, action.response.entities.categorys),
  )(state),
  [SET_CATEGORY_SUCCESS]: (state, action) => flow(
    set('entities.categorys', {
      ...state.entities.categorys,
      ...action.response.entities.categorys,
    }),
  )(state),
  [DELETE_CATEGORY_SUCCESS]: (state, action) => flow(
    set('entities.categorys', omit(state.entities.categorys, action.id)),
  )(state),
};

export function loadCategory (id) {
  return {
    id,
    [CALL_API]: {
      types: [
        FETCH_CATEGORY_REQUEST,
        FETCH_CATEGORY_SUCCESS,
        FETCH_CATEGORY_FAILURE,
      ],
      method: 'GET',
      endpoint: `/categorys/${id}`,
      schema: Schemas.CATEGORY,
    },
  };
}

export function loadCategorys () {
  return {
    [CALL_API]: {
      types: [
        FETCH_ALL_CATEGORYS_REQUEST,
        FETCH_ALL_CATEGORYS_SUCCESS,
        FETCH_ALL_CATEGORYS_FAILURE,
      ],
      method: 'GET',
      endpoint: '/categorys',
      schema: Schemas.CATEGORY_ARRAY,
    },
  };
}

export function createOrUpdateCategory (category) {
  return {
    [CALL_API]: {
      types: [
        SET_CATEGORY_REQUEST,
        SET_CATEGORY_SUCCESS,
        SET_CATEGORY_FAILURE,
      ],
      method: !category || !Number.isInteger(category.category_id) ? 'POST' : 'PUT',
      endpoint: !category || !Number.isInteger(category.category_id) ? '/categorys' : `/categorys/${category.category_id}`,
      schema: Schemas.CATEGORY,
      body: category,
      successMessage: 'Catégorie enregistrée avec succès',
    },
  };
}

export function deleteCategory (id) {
  return {
    id,
    [CALL_API]: {
      types: [
        DELETE_CATEGORY_REQUEST,
        DELETE_CATEGORY_SUCCESS,
        DELETE_CATEGORY_FAILURE,
      ],
      method: 'DELETE',
      endpoint: `/categorys/${id}`,
      successMessage: 'Catégorie supprimée avec succès',
    },
  };
}
