import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { NavLink } from "react-router-dom";
import { isNumber } from "lodash";
import { Field, FileField, InputField } from "react-components-linaia";

import { uploadFiles, removeFile } from "../../store/modules/uploads";

import { UPLOADS_URL } from "../../constants/Config";

const BillEditForm = ({
  handleSubmit,
  baseUrl,
  initialValues,
  upFiles,
  rmFile
}) => (
  <Fragment>
    <div className="modal-title">
      <div className="modal-title-content">
        {initialValues.bill_id >= 0
          ? "Modifier la facture"
          : "Ajouter une facture"}
        <NavLink className="btn" to={baseUrl}>
          <i className="fal fa-times" />
        </NavLink>
      </div>
    </div>
    <form onSubmit={handleSubmit}>
      <div className="modal-body">
        <div className="grid">
          <Field
            name="billName"
            component={InputField}
            label="Numéro de facture"
            className="col-12"
          />
          <Field
            name="billUrl"
            component={FileField}
            uploadFiles={upFiles}
            removeFile={rmFile}
            getUrl={item => `${UPLOADS_URL}/${item}`}
            buttonLabel="Choisir un fichier"
            label="Facture"
            className="col-12"
          />
        </div>
      </div>
      <div className="modal-footer">
        <div className="btn-group center">
          <button className="btn btn-primary" type="submit">
            Enregistrer
          </button>
        </div>
      </div>
    </form>
  </Fragment>
);

BillEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
  initialValues: PropTypes.shape().isRequired,

  upFiles: PropTypes.func.isRequired,
  rmFile: PropTypes.func.isRequired
};

export default reduxForm({
  form: "BillEditForm",
  validate: (values = {}) => {
    const errors = {};

    if (!values.billName)
      errors.billName = "Merci de renseigner le nom de la facture";
    if (!values.billUrl)
      errors.billUrl = "Merci de renseigner l'URL de la facture";
    if (!isNumber(values.factureClient))
      errors.factureClient = "Merci de renseigner le client de la facture";

    return errors;
  }
})(
  connect(
    null,
    { upFiles: uploadFiles, rmFile: removeFile }
  )(BillEditForm)
);
