import { flow, set } from 'lodash/fp';
import { omit } from 'lodash';

import { CALL_API, Schemas } from '../Schemas';

const FETCH_ALL_SHAREDCATEGORYS_REQUEST = 'fgwa/sharedcategorys/FETCH_ALL_SHAREDCATEGORYS_REQUEST';
export const FETCH_ALL_SHAREDCATEGORYS_SUCCESS = 'fgwa/sharedcategorys/FETCH_ALL_SHAREDCATEGORYS_SUCCESS';
const FETCH_ALL_SHAREDCATEGORYS_FAILURE = 'fgwa/sharedcategorys/FETCH_ALL_SHAREDCATEGORYS_FAILURE';
const FETCH_SHAREDCATEGORY_REQUEST = 'fgwa/sharedcategorys/FETCH_SHAREDCATEGORY_REQUEST';
const FETCH_SHAREDCATEGORY_SUCCESS = 'fgwa/sharedcategorys/FETCH_SHAREDCATEGORY_SUCCESS';
const FETCH_SHAREDCATEGORY_FAILURE = 'fgwa/sharedcategorys/FETCH_SHAREDCATEGORY_FAILURE';
const SET_SHAREDCATEGORY_REQUEST = 'fgwa/sharedcategorys/SET_SHAREDCATEGORY_REQUEST';
const SET_SHAREDCATEGORY_SUCCESS = 'fgwa/sharedcategorys/SET_SHAREDCATEGORY_SUCCESS';
const SET_SHAREDCATEGORY_FAILURE = 'fgwa/sharedcategorys/SET_SHAREDCATEGORY_FAILURE';
const DELETE_SHAREDCATEGORY_REQUEST = 'fgwa/sharedcategorys/DELETE_SHAREDCATEGORY_REQUEST';
const DELETE_SHAREDCATEGORY_SUCCESS = 'fgwa/sharedcategorys/DELETE_SHAREDCATEGORY_SUCCESS';
const DELETE_SHAREDCATEGORY_FAILURE = 'fgwa/sharedcategorys/DELETE_SHAREDCATEGORY_FAILURE';

export const sharedcategorysActionsHandlers = {
  [FETCH_ALL_SHAREDCATEGORYS_REQUEST]: state => flow(
    set('loaded.sharedcategorys', false),
    set('loading.sharedcategorys', true),
  )(state),
  [FETCH_ALL_SHAREDCATEGORYS_SUCCESS]: (state, action) => flow(
    set('entities.sharedcategorys', action.response.entities.sharedcategorys || {}),
    set('loaded.sharedcategorys', true),
    set('loading.sharedcategorys', false),
  )(state),
  [FETCH_ALL_SHAREDCATEGORYS_FAILURE]: state => flow(
    set('loaded.sharedcategorys', false),
    set('loading.sharedcategorys', false),
  )(state),
  [FETCH_SHAREDCATEGORY_SUCCESS]: (state, action) => flow(
    set(`entities.sharedcategorys.${action.id}`, action.response.entities.sharedcategorys),
  )(state),
  [SET_SHAREDCATEGORY_SUCCESS]: (state, action) => flow(
    set('entities.sharedcategorys', {
      ...state.entities.sharedcategorys,
      ...action.response.entities.sharedcategorys,
    }),
  )(state),
  [DELETE_SHAREDCATEGORY_SUCCESS]: (state, action) => flow(
    set('entities.sharedcategorys', omit(state.entities.sharedcategorys, action.id)),
  )(state),
};

export function loadSharedcategory (id) {
  return {
    id,
    [CALL_API]: {
      types: [
        FETCH_SHAREDCATEGORY_REQUEST,
        FETCH_SHAREDCATEGORY_SUCCESS,
        FETCH_SHAREDCATEGORY_FAILURE,
      ],
      method: 'GET',
      endpoint: `/sharedcategorys/${id}`,
      schema: Schemas.SHAREDCATEGORY,
    },
  };
}

export function loadSharedcategorys () {
  return {
    [CALL_API]: {
      types: [
        FETCH_ALL_SHAREDCATEGORYS_REQUEST,
        FETCH_ALL_SHAREDCATEGORYS_SUCCESS,
        FETCH_ALL_SHAREDCATEGORYS_FAILURE,
      ],
      method: 'GET',
      endpoint: '/sharedcategorys',
      schema: Schemas.SHAREDCATEGORY_ARRAY,
    },
  };
}

export function createOrUpdateSharedcategory (sharedcategory) {
  return {
    [CALL_API]: {
      types: [
        SET_SHAREDCATEGORY_REQUEST,
        SET_SHAREDCATEGORY_SUCCESS,
        SET_SHAREDCATEGORY_FAILURE,
      ],
      method: !sharedcategory || !Number.isInteger(sharedcategory.sharedcategory_id) ? 'POST' : 'PUT',
      endpoint: !sharedcategory || !Number.isInteger(sharedcategory.sharedcategory_id) ? '/sharedcategorys' : `/sharedcategorys/${sharedcategory.sharedcategory_id}`,
      schema: Schemas.SHAREDCATEGORY,
      body: sharedcategory,
      successMessage: 'Catégorie partagée enregistré avec succès',
    },
  };
}

export function deleteSharedcategory (id) {
  return {
    id,
    [CALL_API]: {
      types: [
        DELETE_SHAREDCATEGORY_REQUEST,
        DELETE_SHAREDCATEGORY_SUCCESS,
        DELETE_SHAREDCATEGORY_FAILURE,
      ],
      method: 'DELETE',
      endpoint: `/sharedcategorys/${id}`,
      successMessage: 'Catégorie partagée supprimé avec succès',
    },
  };
}
