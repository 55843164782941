import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { NavLink } from 'react-router-dom';
import { ButtonConfirmModal } from 'react-components-linaia';

import { UPLOADS_URL } from '../../constants/Config';

const ShareddocumentDetailView = ({
  shareddocument = {}, baseUrl, onClickDelete, sharedcategorys,
}) => (
  <div>
    <div>
      Shareddocument Id:&nbsp;
      {shareddocument.shareddocument_id}
    </div>
    <div>
      Shareddocument Name:&nbsp;
      {shareddocument.shareddocumentName}
    </div>
    <div>
      Shareddocument Category:&nbsp;
      {get(sharedcategorys, `${shareddocument.shareddocumentCategory}.sharedcategoryName`)}
    </div>
    <div>
      Shareddocument File:&nbsp;
      <a href={`${UPLOADS_URL}/${shareddocument.shareddocumentFile}`} target="_blank" rel="noopener noreferrer">{shareddocument.shareddocumentFile}</a>
    </div>
    <div>
      Shareddocument Start Date:&nbsp;
      {shareddocument.shareddocumentStartDate}
    </div>
    <div>
      Shareddocument End Date:&nbsp;
      {shareddocument.shareddocumentEndDate}
    </div>

    <div className="btn-group right">
      <NavLink className="btn" to={`${baseUrl}/${shareddocument.shareddocument_id}/edit`}>
        Modifier
      </NavLink>
      {onClickDelete && (
        <ButtonConfirmModal
          onConfirmationButton={() => onClickDelete()}
          message="Voulez-vous vraiment supprimer ce document ?"
        >
          <button className="btn" type="button">Supprimer</button>
        </ButtonConfirmModal>
      )}
    </div>
  </div>
);

ShareddocumentDetailView.propTypes = {
  shareddocument: PropTypes.shape({
    shareddocument_id: PropTypes.number.isRequired,
    shareddocumentName: PropTypes.string.isRequired,
    shareddocumentCategory: PropTypes.number.isRequired,
    shareddocumentFile: PropTypes.string.isRequired,
    shareddocumentStartDate: PropTypes.string,
    shareddocumentEndDate: PropTypes.string,
  }).isRequired,

  sharedcategorys: PropTypes.objectOf(PropTypes.shape()).isRequired,

  baseUrl: PropTypes.string.isRequired,
  onClickDelete: PropTypes.func,
};

export default connect(
  state => ({
    sharedcategorys: state.data.entities.sharedcategorys,
  }),
)(ShareddocumentDetailView);
