import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, NavLink } from 'react-router-dom';
import { capitalize, get } from 'lodash';
import { connect } from 'react-redux';
import { ButtonConfirmModal } from 'react-components-linaia';

import { EQUIPMENT_TYPES } from '../../constants/Properties';

import createSelector from '../../selectors/ClientSubEntitiesSelector';

import TableListView from '../shared/TableListView';

const clientSubEntitiesSelector = createSelector();

const EquipmentsTableListView = ({
  history: { push },
  equipments,
  baseUrl,
  onClickDelete,
  contracts,
  sites,
}) => (
  <TableListView
    className="table-boxed full-height"
    data={equipments}
    cols={{
      equipmentName: {
        label: 'Libellé',
        className: 'col_sm-12',
        render: capitalize,
      },
      equipmentLocation: {
        label: 'Localisation',
        className: 'col_sm-12',
        render: capitalize,
      },
      equipmentPostNumber: {
        label: 'N° Poste',
        className: 'col_sm-12',
        render: capitalize,
      },
      equipmentType: {
        label: 'Type',
        className: 'col_sm-12',
        value: id => capitalize(EQUIPMENT_TYPES[id]),
      },
      equipmentBaitType: {
        label: "Type d'appât",
        className: 'col_sm-12',
        render: capitalize,
      },
      equipmentIsToxic: {
        label: 'Toxique',
        className: 'col_sm-12',
        value: value => (value ? 'Oui' : 'Non'),
      },
      equipmentUsedProduct: {
        label: 'Produit',
        className: 'col_sm-12',
        render: capitalize,
      },
      equipmentContract: {
        label: 'Contrat',
        className: 'col_sm-12',
        value: id => get(contracts, `${id}.contractNumber`),
      },
      equipmentSite: {
        label: 'Site',
        className: 'col_sm-12',
        value: (id, row) =>
          get(
            sites,
            `${get(
              contracts,
              `${row.equipmentContract}.contractAffectedSite`,
            )}.siteName`,
          ),
      },
    }}
    search={['equipmentName', 'equipmentSite']}
    sortByDefault="equipmentContract"
    searchLabel="Rechercher un équipement"
    buttons={
      <div>
        <NavLink className="btn btn-main-mobile" to={`${baseUrl}/new`}>
          <span>Ajouter un équipement</span>
          <i className="far fa-plus" />
        </NavLink>
      </div>
    }
    rowButtons={equipment => (
      <div className="col-0_sm-12 cln-2btn">
        <div className="td td-buttons">
          {equipment && !equipment.equipmentIdExterne && (
            <NavLink
              className="btn"
              to={`${baseUrl}/${equipment.equipment_id}/edit`}
            >
              <i className="far fa-pencil" />
            </NavLink>
          )}
          {equipment && !equipment.equipmentIdExterne && (
            <ButtonConfirmModal
              onConfirmationButton={() => onClickDelete(equipment.equipment_id)}
              message="Voulez-vous vraiment supprimer cet équipement ?"
            >
              <button className="btn" type="button">
                <i className="far fa-trash" />
              </button>
            </ButtonConfirmModal>
          )}
        </div>
      </div>
    )}
  />
);

EquipmentsTableListView.propTypes = {
  equipments: PropTypes.objectOf(
    PropTypes.shape({
      equipment_id: PropTypes.number.isRequired,
      equipmentName: PropTypes.string.isRequired,
      equipmentLocation: PropTypes.string.isRequired,
      equipmentPostNumber: PropTypes.string.isRequired,
      equipmentType: PropTypes.string.isRequired,
      equipmentBaitType: PropTypes.string.isRequired,
      equipmentIsToxic: PropTypes.bool.isRequired,
      equipmentUsedProduct: PropTypes.string.isRequired,
      equipmentContract: PropTypes.number.isRequired,
    }),
  ).isRequired,

  contracts: PropTypes.objectOf(PropTypes.shape()).isRequired,
  sites: PropTypes.objectOf(PropTypes.shape()).isRequired,

  baseUrl: PropTypes.string.isRequired,
  onClickDelete: PropTypes.func,

  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect((state, { clientId }) => ({
  contracts: clientSubEntitiesSelector(
    state.data.entities.contracts,
    clientId,
    'contractClient',
  ),
  sites: state.data.entities.sites,
}))(withRouter(EquipmentsTableListView));
