import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { withRouter, Switch, Route } from 'react-router-dom';
import { get, pickBy, find } from 'lodash';

import { createOrUpdateClient, deleteClient } from '../../store/modules/clients';

import BaseLayout from '../shared/BaseLayout';

import TechniciansAdminCardListView from '../../components/technicians/TechniciansAdminCardListView';
import SitesTechniciansAdminContainer from '../sites/SitesTechniciansAdminContainer';

// TO DO crer le bon url pour aller ouvir le site avec le contract et le plan selectionné
const TechniciansAdminContainer = ({
  match: { url }, history: { push }, clients, sites, documents, equipments, ...props
}) => (
  <Switch>
    <Route
      path={`${url}/manage/:siteId/sites/:contractId/contract/:planId/plan`}
      render={({ match }) => {
        const params = {
          siteId: parseInt(match.params.siteId, 10),
          contractId: parseInt(match.params.contractId, 10),
          planId: parseInt(match.params.planId, 10),
          pathName: props.location.pathname,
          documents: pickBy(documents, d => (d.documentSite === parseInt(match.params.siteId, 10))),
        };
        let siteClient;
        const client = find(sites, s => (s.site_id === params.siteId));
        if (client) {
          siteClient = client.siteClient;
        }
        return (
          <BaseLayout title={`${get(clients, `${siteClient}.clientName`, '')} / ${get(sites, `${match.params.siteId}.siteName`, '')}`}>
            <div className="container-wrapper">
              <Route path={match.url} render={() => <SitesTechniciansAdminContainer baseUrl={`${url}/manage`} {...params} />} />
            </div>
          </BaseLayout>
        );
      }}
    />
    <Route
      path={`${url}/manage/:siteId/sites/:contractId/contract`}
      render={({ match }) => {
        const params = {
          siteId: parseInt(match.params.siteId, 10),
          contractId: parseInt(match.params.contractId, 10),
          planId: null,
          pathName: props.location.pathname,
          documents: pickBy(documents, d => (d.documentSite === parseInt(match.params.siteId, 10))),
        };
        let siteClient;
        const client = find(sites, s => (s.site_id === params.siteId));
        if (client) {
          siteClient = client.siteClient;
        }
        console.log('value of params : ', params);
        return (
          <BaseLayout title={`${get(clients, `${siteClient}.clientName`, '')} / ${get(sites, `${match.params.siteId}.siteName`, '')}`}>
            <div className="container-wrapper">
              <Route path={match.url} render={() => <SitesTechniciansAdminContainer baseUrl={`${url}/manage`} {...params} />} />
            </div>
          </BaseLayout>
        );
      }}
    />
    <Route
      path={url}
      render={({ match }) => (
        <BaseLayout title="Sites">
          <TechniciansAdminCardListView
            clients={clients}
            sites={sites}
            baseUrl={match.url}
            documents={documents}
          />
        </BaseLayout>
      )}
    />
  </Switch>
);

TechniciansAdminContainer.propTypes = {
  clients: PropTypes.objectOf(PropTypes.shape()).isRequired,
  sites: PropTypes.objectOf(PropTypes.shape()).isRequired,
  documents: PropTypes.objectOf(PropTypes.shape()),
  equipments: PropTypes.objectOf(PropTypes.shape()),
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default connect(
  state => ({
    clients: state.data.entities.clients,
    sites: state.data.entities.sites,
    documents: state.data.entities.documents,
    equipments: state.data.entities.equipments,
  }),
)(withRouter(TechniciansAdminContainer));
