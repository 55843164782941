import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { get } from "lodash";
import { ButtonConfirmModal } from "react-components-linaia";

import CardListView from "../shared/CardListView";

import { UPLOADS_URL } from "../../constants/Config";

import defaultTechnicianPhoto from "../../../assets/images/ui/user-profil.png";

const TechniciansCardListView = ({
  technicians,
  baseUrl,
  onClickDelete,
  structures
}) => (
  <CardListView
    className="card-list-technicians"
    data={technicians}
    cardComponent={technician => (
      <div className="col-4_xs-12_sm-6_md-4">
        <div className="box card simple-card">
          <div className="box-content">
            <div className="grid">
              <div className="col-2">
                <div className="card-header-picture">
                  {technician.technicianPhotoUrl ? (
                    <img
                      src={`${UPLOADS_URL}/${technician.technicianPhotoUrl}`}
                      alt={`${technician.technicianFirstname} ${technician.technicianLastname}`}
                    />
                  ) : (
                    <img
                      src={defaultTechnicianPhoto}
                      alt={`${technician.technicianFirstname} ${technician.technicianLastname}`}
                    />
                  )}
                </div>
              </div>
              <div className="col">
                <div className="grid-noBottom">
                  <div className="col-12">
                    <div className="card-title">
                      {technician.technicianFirstname}{" "}
                      {technician.technicianLastname}
                    </div>
                  </div>
                  <div className="col-12">
                    {get(
                      structures,
                      `${technician.technicianStructure}.structureName`
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="grid-noBottom">
              <div className="col-12">
                <div className="grid-noBottom">
                  <div className="col-12">
                    <a href={`tel:${technician.technicianPhone}`}>
                      <i className="fas fa-phone" />
                      <span>{technician.technicianPhone}</span>
                    </a>
                  </div>
                  <div className="col-12">
                    <a href={`mailto:${technician.technicianEmail}`}>
                      <i className="fas fa-envelope" />
                      <span>{technician.technicianEmail}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-actions btn-group">
              <NavLink
                to={`${baseUrl}/${technician.technician_id}/edit`}
                className="btn"
              >
                <i className="far fa-pencil" />
                <span>Modifier</span>
              </NavLink>
              {onClickDelete && !technician.technicianIdExterne && (
                <ButtonConfirmModal
                  onConfirmationButton={() =>
                    onClickDelete(technician.technician_id)
                  }
                  message="Voulez-vous vraiment supprimer ce technicien ?"
                >
                  <button className="btn" type="button">
                    <i className="far fa-trash" />
                    <span>Supprimer</span>
                  </button>
                </ButtonConfirmModal>
              )}
            </div>
          </div>
        </div>
      </div>
    )}
    search={["technicianLastname", "technicianFirstname"]}
    searchLabel="Rechercher un technicien"
    sortByDefault="technicianLastname"
    buttons={
      <div>
        <NavLink className="btn btn-main-mobile" to={`${baseUrl}/new`}>
          <span>Ajouter un technicien</span>
          <i className="far fa-plus" />
        </NavLink>
      </div>
    }
  />
);

TechniciansCardListView.propTypes = {
  technicians: PropTypes.objectOf(
    PropTypes.shape({
      technician_id: PropTypes.number.isRequired,
      technicianFirstname: PropTypes.string.isRequired,
      technicianLastname: PropTypes.string.isRequired,
      technicianEmail: PropTypes.string.isRequired,
      technicianPhone: PropTypes.string.isRequired,
      technicianPhotoUrl: PropTypes.string,
      technicianStructure: PropTypes.number.isRequired
    })
  ).isRequired,

  structures: PropTypes.objectOf(PropTypes.shape()).isRequired,

  baseUrl: PropTypes.string.isRequired,
  onClickDelete: PropTypes.func
};

export default connect(state => ({
  structures: state.data.entities.structures
}))(TechniciansCardListView);
