import { flow, set } from 'lodash/fp';
import { omit } from 'lodash';

import { CALL_API, Schemas } from '../Schemas';

const FETCH_ALL_DOCUMENTS_REQUEST = 'fgwa/documents/FETCH_ALL_DOCUMENTS_REQUEST';
export const FETCH_ALL_DOCUMENTS_SUCCESS = 'fgwa/documents/FETCH_ALL_DOCUMENTS_SUCCESS';
const FETCH_ALL_DOCUMENTS_FAILURE = 'fgwa/documents/FETCH_ALL_DOCUMENTS_FAILURE';
const FETCH_DOCUMENT_REQUEST = 'fgwa/documents/FETCH_DOCUMENT_REQUEST';
const FETCH_DOCUMENT_SUCCESS = 'fgwa/documents/FETCH_DOCUMENT_SUCCESS';
const FETCH_DOCUMENT_FAILURE = 'fgwa/documents/FETCH_DOCUMENT_FAILURE';
const SET_DOCUMENT_REQUEST = 'fgwa/documents/SET_DOCUMENT_REQUEST';
const SET_DOCUMENT_SUCCESS = 'fgwa/documents/SET_DOCUMENT_SUCCESS';
const SET_DOCUMENT_FAILURE = 'fgwa/documents/SET_DOCUMENT_FAILURE';
const REFRESH_DOCUMENT_REQUEST = 'fgwa/documents/REFRESH_DOCUMENT_REQUEST';
const REFRESH_DOCUMENT_SUCCESS = 'fgwa/documents/REFRESH_DOCUMENT_SUCCESS';
const REFRESH_DOCUMENT_FAILURE = 'fgwa/documents/REFRESH_DOCUMENT_FAILURE';
const DELETE_DOCUMENT_REQUEST = 'fgwa/documents/DELETE_DOCUMENT_REQUEST';
const DELETE_DOCUMENT_SUCCESS = 'fgwa/documents/DELETE_DOCUMENT_SUCCESS';
const DELETE_DOCUMENT_FAILURE = 'fgwa/documents/DELETE_DOCUMENT_FAILURE';

export const documentsActionsHandlers = {
  [FETCH_ALL_DOCUMENTS_REQUEST]: state => flow(
    set('loaded.documents', false),
    set('loading.documents', true),
  )(state),
  [FETCH_ALL_DOCUMENTS_SUCCESS]: (state, action) => flow(
    set('entities.documents', action.response.entities.documents || {}),
    set('loaded.documents', true),
    set('loading.documents', false),
  )(state),
  [FETCH_ALL_DOCUMENTS_FAILURE]: state => flow(
    set('loaded.documents', false),
    set('loading.documents', false),
  )(state),
  [FETCH_DOCUMENT_SUCCESS]: (state, action) => flow(
    set(`entities.documents.${action.id}`, action.response.entities.documents),
  )(state),
  [SET_DOCUMENT_SUCCESS]: (state, action) => flow(
    set('entities.documents', {
      ...state.entities.documents,
      ...action.response.entities.documents,
    }),
  )(state),
  [REFRESH_DOCUMENT_SUCCESS]: (state, action) => flow(
    set('entities.documents', {
      ...state.entities.documents,
      ...action.response.entities.documents,
    }),
  )(state),
  [DELETE_DOCUMENT_SUCCESS]: (state, action) => flow(
    set('entities.documents', omit(state.entities.documents, action.id)),
  )(state),
};

export function loadDocument (id) {
  return {
    id,
    [CALL_API]: {
      types: [
        FETCH_DOCUMENT_REQUEST,
        FETCH_DOCUMENT_SUCCESS,
        FETCH_DOCUMENT_FAILURE,
      ],
      method: 'GET',
      endpoint: `/documents/${id}`,
      schema: Schemas.DOCUMENT,
    },
  };
}

export function loadDocuments () {
  return {
    [CALL_API]: {
      types: [
        FETCH_ALL_DOCUMENTS_REQUEST,
        FETCH_ALL_DOCUMENTS_SUCCESS,
        FETCH_ALL_DOCUMENTS_FAILURE,
      ],
      method: 'GET',
      endpoint: '/documents',
      schema: Schemas.DOCUMENT_ARRAY,
    },
  };
}

export function createOrUpdateDocument (document) {
  return {
    [CALL_API]: {
      types: [
        SET_DOCUMENT_REQUEST,
        SET_DOCUMENT_SUCCESS,
        SET_DOCUMENT_FAILURE,
      ],
      method: !document || !Number.isInteger(document.document_id) ? 'POST' : 'PUT',
      endpoint: !document || !Number.isInteger(document.document_id) ? '/documents' : `/documents/${document.document_id}`,
      schema: Schemas.DOCUMENT,
      body: document,
      successMessage: 'Document enregistré avec succès',
    },
  };
}

export function deleteDocument (id) {
  return {
    id,
    [CALL_API]: {
      types: [
        DELETE_DOCUMENT_REQUEST,
        DELETE_DOCUMENT_SUCCESS,
        DELETE_DOCUMENT_FAILURE,
      ],
      method: 'DELETE',
      endpoint: `/documents/${id}`,
      successMessage: 'Document supprimé avec succès',
    },
  };
}
