import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { pickBy } from 'lodash';

import DocumentsTableListView from '../../components/documents/DocumentsTableListView';

const DocumentsSiteContainer = ({
  documentCategory, match: { url }, documents,
}) => (
  <Fragment>
    <DocumentsTableListView
      documents={documents}
      baseUrl={url}
      documentCategory={documentCategory}
    />
  </Fragment>
);

DocumentsSiteContainer.propTypes = {
  documentCategory: PropTypes.number,

  documents: PropTypes.objectOf(PropTypes.shape()).isRequired,

  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default connect(
  (state, { siteId, documentCategory }) => ({
    documents: pickBy(
      state.data.entities.documents,
      d => d.documentSite === siteId && d.documentCategory === documentCategory,
    ),
  }),
)(withRouter(DocumentsSiteContainer));
