import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { withRouter, Switch, Route } from 'react-router-dom';
import { get } from 'lodash';

import { createOrUpdateAccount, deleteAccount } from '../../store/modules/accounts';

import BaseLayout from '../shared/BaseLayout';

import AccountDetailView from '../../components/accounts/AccountDetailView';
import AccountEditForm from '../../components/accounts/AccountEditForm';
import AccountsCardListView from '../../components/accounts/AccountsCardListView';

import modalDefaultClass from '../../utils/ModalDefaultClass';

const RenderEditDetailsModalsRouter = ({
  url, editAccount, delAccount, push, accounts,
}) => (
  <Switch>
    <Route
      exact
      path={`${url}/new`}
      render={() => (
        <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
          <AccountEditForm
            initialValues={{
              roles: ['ADMIN'],
            }}
            onSubmit={(v) => {
              editAccount(v)
              .then(() => push(url));
            }}
            baseUrl={url}
            createOnlyAdmin
          />
        </Modal>
      )}
    />
    <Route
      exact
      path={`${url}/:accountId/edit`}
      render={subProps => get(accounts, subProps.match.params.accountId, null) && (
        <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
          <AccountEditForm
            initialValues={accounts[subProps.match.params.accountId]}
            onSubmit={(v) => {
              editAccount(v)
              .then(() => push(url));
            }}
            baseUrl={url}
            createOnlyAdmin
          />
        </Modal>
      )}
    />
    <Route
      exact
      path={`${url}/:accountId`}
      render={subProps => get(accounts, subProps.match.params.accountId, null) && (
        <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
          <AccountDetailView
            account={accounts[subProps.match.params.accountId]}
            baseUrl={url}
            onClickDelete={() => {
              delAccount(subProps.match.params.accountId)
              .then(() => push(url));
            }}
          />
        </Modal>
      )}
    />
  </Switch>
);

RenderEditDetailsModalsRouter.propTypes = {
  url: PropTypes.string.isRequired,
  editAccount: PropTypes.func.isRequired,
  delAccount: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  accounts: PropTypes.objectOf(PropTypes.shape()).isRequired,
};

const AccountsContainer = ({
  match: { url }, history: { push }, accounts, ...props
}) => (
  <BaseLayout title="Administrateurs">
    <Route
      path={url}
      render={({ match }) => (
        <Fragment>
          <AccountsCardListView
            accounts={accounts}
            baseUrl={match.url}
            onClickDelete={(accountId) => {
              props.deleteAccount(accountId)
              .then(() => push(match.url));
            }}
          />
          <RenderEditDetailsModalsRouter
            url={match.url}
            editAccount={props.createOrUpdateAccount}
            delAccount={props.deleteAccount}
            push={push}
            accounts={accounts}
          />
        </Fragment>
      )}
    />
  </BaseLayout>
);

AccountsContainer.propTypes = {
  createOrUpdateAccount: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,

  accounts: PropTypes.objectOf(PropTypes.shape()).isRequired,

  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect(
  state => ({
    accounts: state.data.entities.accounts,
  }),
  { createOrUpdateAccount, deleteAccount },
)(withRouter(AccountsContainer));
