import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { NavLink } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';
import {
  Field,
  CleaveField,
  InputField,
  ToggleField,
  SelectField
} from 'react-components-linaia';

import createSelector from '../../selectors/ClientSubEntitiesSelector';

const clientSubEntitiesSelector = createSelector();

const ContactEditForm = ({
  handleSubmit,
  baseUrl,
  initialValues,
  sites,
  categorys
}) => (
  <Fragment>
    <div className="modal-title">
      <div className="modal-title-content">
        {initialValues.contact_id >= 0
          ? 'Modifier le contact'
          : 'Ajouter un contact'}
        <NavLink className="btn" to={baseUrl}>
          <i className="fal fa-times" />
        </NavLink>
      </div>
    </div>
    <form onSubmit={handleSubmit}>
      <div className="modal-body">
        <div className="grid">
          <Field
            name="contactFirstname"
            component={InputField}
            label="Prénom"
            className="col-6"
          />
          <Field
            name="contactLastname"
            component={InputField}
            label="Nom"
            className="col-6"
          />
          <Field
            name="contactSites"
            component={SelectField}
            options={sites}
            optionsKeys={{ value: 'site_id', label: 'siteName' }}
            label="Affectation aux sites"
            className="col-12"
            isMulti
          />
          <Field
            name="contactPhone"
            component={CleaveField}
            label="Téléphone"
            className="col-tel"
            cleaveOptions={{ phone: true, phoneRegionCode: 'FR' }}
          />
          <Field
            name="contactEmail"
            component={InputField}
            label="Email"
            type="email"
            className="col"
            disabled={false}
          />
          <Field
            name="contactCategories"
            component={SelectField}
            options={categorys}
            optionsKeys={{ value: 'category_id', label: 'categoryName' }}
            label="Affectation aux catégories de document client"
            className="col-12"
            isMulti
          />
          <Field
            name="contactBillingAccess"
            component={ToggleField}
            label="Accès aux factures"
            className="col-12"
            fieldClassName="checkbox"
          />
        </div>
      </div>
      <div className="modal-footer">
        <div className="btn-group center">
          <button className="btn btn-primary" type="submit">
            Enregistrer
          </button>
        </div>
      </div>
    </form>
  </Fragment>
);

ContactEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
  initialValues: PropTypes.shape().isRequired,

  sites: PropTypes.objectOf(PropTypes.shape()).isRequired,
  categorys: PropTypes.objectOf(PropTypes.shape()).isRequired
};

export default reduxForm({
  form: 'ContactEditForm',
  validate: (values = {}) => {
    const errors = {};

    if (!isEmail(values.contactEmail || ''))
      errors.contactEmail = 'Merci de renseigner une adresse email du contact';
    if (!values.contactFirstname)
      errors.contactFirstname = 'Merci de renseigner le prénom du contact';
    if (!values.contactLastname)
      errors.contactLastname = 'Merci de renseigner le nom du contact';
    if (!/^(\+33 |00 33 |0)[1-9]( \d\d){4}$/.test(values.contactPhone))
      errors.contactPhone =
        'Merci de renseigner le numéro de téléphone du contact';
    if (!Array.isArray(values.contactSites) || values.contactSites.length === 0)
      errors.contactSites = 'Merci de renseigner une affectation au site';
    if (
      !Array.isArray(values.contactCategories) ||
      values.contactCategories.length === 0
    )
      errors.contactCategories = 'Merci de renseigner une catégorie';
    if (
      !(
        values.contactBillingAccess === false ||
        values.contactBillingAccess === true
      )
    )
      errors.contactBillingAccess = 'Must be set';

    return errors;
  }
})(
  connect((state, { clientId }) => ({
    sites: clientSubEntitiesSelector(
      state.data.entities.sites,
      clientId,
      'siteClient'
    ),
    categorys: state.data.entities.categorys
  }))(ContactEditForm)
);
