import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';

const ChartSingleBar = ({ labels, data }) => (
  <Bar
    height={80}
    data={{
      labels,
      datasets: [{
        data,
        backgroundColor: 'rgba(51,96,139,0.3)',
        borderColor: 'rgba(51,96,139,0.8)',
        borderWidth: 2,
      }],
    }}
    options={{
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
          },
        }],
      },
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        },
      },
      legend: {
        display: false,
      },
    }}
  />
);

ChartSingleBar.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default ChartSingleBar;
