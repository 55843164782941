export const API_URL = process.env.NODE_ENV === 'development'
  ? `${window.location.protocol}//${window.location.hostname}:3333/api`
  : '/api';

export const UPLOADS_URL = `${API_URL}/uploads/`;

export const DISABLE_CONFIRMATION_MODAL = (process.env.NODE_ENV === 'development');

export const DATE_FORMAT = 'DD/MM/YYYY';

export const RESIZE_IMAGE_MAX_SIZE = 1137;

export const RESIZE_FILE_FTP = 1024;

export const AUTHORIZED_UPLOAD_IMAGES = [
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
];

export const AUTHORIZED_UPLOAD_FILES = [
  'text/plain',
  'application/pdf',
  'application/xml',
];

export const OPT_COURTESY_TITLE = {
  mr: { short: 'M.', long: 'Monsieur' },
  mme: { short: 'Mme', long: 'Madame' },
};

export const DOCUMENT_OPTIONS = {
  fichier: 'Fichier',
  lien: 'Lien',
};

export const DOCUMENT_TYPE = {
  TOUS: 'Tous',
  PDF: 'PDF',
  MICROSOFT_WORD: 'WORD',
  MICROSOFT_EXCEL: 'EXCEL',
  IMAGE: 'IMG',
};

export const DOCUMENT_TYPE_FILTER = {
  TOUS: [],
  PDF: ['pdf'],
  MICROSOFT_WORD: ['doc', 'docx'],
  MICROSOFT_EXCEL: ['xlsx', 'xml'],
  IMAGE: ['png', 'jpg', 'jpeg', 'gif'],
};

export const ACCEPTED_FILES = [
  'image/jpeg',
  'image/png',
  'image/tiff',
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
];

export const OPT_ACCOUNT_ROLES = {
  ADMIN: 'Administrateur',
  CLIENT_CONTACT: 'Contact Client',
  TECHNICIAN_ADMIN: 'Technicien',
};

export const OPT_PHONE_TYPE = {
  home: 'Fixe',
  mobile: 'Mobile',
  other: 'Autres',
};

export const OPT_EMAIL_TYPE = {
  personal: 'Personnel',
  work: 'Professionnel',
  other: 'Autres',
};
