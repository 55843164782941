import React, { Fragment, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { map, filter, find, sortBy, reverse, head } from "lodash";
import moment from "moment";
import { Stage, Layer, Rect, Text, Group } from "react-konva";

import KanvaImageClient from "./KonvaClientImage";

import URLImage from "../../dynamicplan/URLImage";

import IconDEIV from "../../../../assets/images/icons/icon-deiv.svg";
import IconDEIVSelected from "../../../../assets/images/icons/icon-deiv-selected.svg";
import IconDEIVLast from "../../../../assets/images/icons/icon-deiv-last.svg";
import IconDEIVLastSelected from "../../../../assets/images/icons/icon-deiv-last-selected.svg";
import IconTEIGNE from "../../../../assets/images/icons/icon-teigne.svg";
import IconTEIGNESelected from "../../../../assets/images/icons/icon-teigne-selected.svg";
import IconTEIGNELast from "../../../../assets/images/icons/icon-teigne-last.svg";
import IconTEIGNELastSelected from "../../../../assets/images/icons/icon-teigne-last-selected.svg";
import IconDERAT from "../../../../assets/images/icons/icon-derat.svg";
import IconDERATSelected from "../../../../assets/images/icons/icon-derat-selected.svg";
import IconDERATConsumed from "../../../../assets/images/icons/icon-derat-consumed.svg";
import IconDERATConsumedSelected from "../../../../assets/images/icons/icon-derat-consumed-selected.svg";

import IconRL from "../../../../assets/images/icons/icon-note.svg";

import { UPLOADS_URL, DATE_FORMAT } from "../../../constants/Config";
import {
  EQUIPMENT_TYPES,
  EQUIPMENT_LOCATION
} from "../../../constants/Properties";

function reducer(currentSrc, action) {
  if (action.type === "main image loaded") {
    return action.response;
  }
  if (!currentSrc) {
    return action.src;
  }
  return currentSrc;
}

function useProgressiveImage({ src }) {
  const [currentSrc, dispatch] = React.useReducer(reducer, null);
  useEffect(() => {
    const mainImage = new Image();
    mainImage.onload = e => {
      if (e.target) {
        dispatch({
          type: "main image loaded",
          response: {
            src,
            width: e.target.width,
            height: e.target.height
          }
        });
      }
      if (e.path[0] !== undefined) {
        dispatch({
          type: "main image loaded",
          response: {
            src,
            width: e.path[0].width,
            height: e.path[0].height
          }
        });
      }
    };

    mainImage.src = src;
  }, [src]);
  return currentSrc;
}

const PlansDetailsView = ({
  equipments,
  documentPlan,
  planId,
  passages,
  test
}) => {
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [detailVisible, setDetailVisible] = useState(false);
  const [equipmentDetail, setEquipmentDetail] = useState({});

  const selectEquipment = e => {
    setDetailVisible(true);
    const equipment = find(
      equipments,
      eq => eq.equipment_id === e.target.attrs.equipmentId
    );
    setEquipmentDetail(equipment);
  };

  const onClickRemove = e => {
    if (
      e !== undefined &&
      e.target !== undefined &&
      e.target.attrs !== undefined &&
      e.target.attrs.equipmentId !== undefined
    ) {
      return selectEquipment(e);
    }
    setDetailVisible(false);
    setEquipmentDetail({});
  };

  const ref = useRef(null);
  const stageRef = useRef(null);

  useEffect(() => {
    setDetailVisible(false);
  }, [documentPlan.documentName]);

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.clientHeight);
      setWidth(ref.current.clientWidth);
    }
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const selectIcon = (isKonva, eq, passageEquipment) => {
    switch (eq.equipmentType) {
      case "baitStation":
        if (
          isKonva &&
          equipmentDetail.equipment_id === eq.equipment_id &&
          passageEquipment &&
          passageEquipment.equipmentState === "consumedRenewed"
        ) {
          return IconDERATConsumedSelected;
        }
        if (
          isKonva &&
          passageEquipment &&
          passageEquipment.equipmentState === "consumedRenewed"
        ) {
          return IconDERATConsumed;
        }
        if (isKonva && equipmentDetail.equipment_id === eq.equipment_id) {
          return IconDERATSelected;
        }
        return IconDERAT;
      case "DEIV":
        if (
          isKonva &&
          equipmentDetail.equipment_id === eq.equipment_id &&
          passageEquipment &&
          passageEquipment.equipmentState
        ) {
          return IconDEIVLastSelected;
        }
        if (passageEquipment && passageEquipment.equipmentState) {
          return IconDEIVLast;
        }
        if (isKonva && equipmentDetail.equipment_id === eq.equipment_id) {
          return IconDEIVSelected;
        }
        return IconDEIV;
      case "mothsTrap":
        if (
          isKonva &&
          equipmentDetail.equipment_id === eq.equipment_id &&
          passageEquipment &&
          passageEquipment.equipmentState
        ) {
          return IconTEIGNELastSelected;
        }
        if (passageEquipment && passageEquipment) {
          return IconTEIGNELast;
        }
        if (isKonva && equipmentDetail.equipment_id === eq.equipment_id) {
          return IconTEIGNESelected;
        }
        return IconTEIGNE;
      case "remarqueLibre":
        return IconRL;
      default:
        return IconRL;
    }
  };

  const equipmentsPlan = filter(equipments, eq => {
    const mapPlanDetail = map(
      eq.equipmentPlanDetail,
      planDetail => JSON.parse(planDetail).planId === parseInt(planId, 10)
    );
    return (
      eq.equipmentPlan &&
      eq.equipmentPlan.indexOf(parseInt(planId, 10)) !== -1 &&
      mapPlanDetail &&
      mapPlanDetail.length > 0 &&
      mapPlanDetail.indexOf(true) !== -1
    );
  });

  const imageBackground = useProgressiveImage({
    src: `${UPLOADS_URL}${documentPlan.documentURL}`
  });
  const sortPassages = reverse(sortBy(passages, ["passageDate"]));
  const passage = find(sortPassages, p =>
    find(
      p.passageEquipments,
      eq => eq.equipment === equipmentDetail.equipment_id
    )
  );
  return (
    <div className="col-9_sm-12 canvas-container">
      <div ref={ref}>
        <div className="box-content box-content-centered">
          {documentPlan && documentPlan.documentURL && imageBackground && (
            <Stage
              width={imageBackground.width}
              height={imageBackground.height}
              onClick={e => onClickRemove(e)}
            >
              <Layer>
                <Group>
                  <URLImage
                    src={`${UPLOADS_URL}${documentPlan.documentURL}`}
                    width={imageBackground.width}
                    height={imageBackground.height}
                    opacity={0.6}
                  />
                </Group>
                {map(equipmentsPlan, (eq, index) => {
                  const planDetail = JSON.parse(
                    find(
                      eq.equipmentPlanDetail,
                      equipPlanDetail =>
                        JSON.parse(equipPlanDetail).planId ===
                        parseInt(planId, 10)
                    )
                  );
                  const lastPassage = find(sortPassages, p =>
                    find(
                      p.passageEquipments,
                      equ => equ.equipment === eq.equipment_id
                    )
                  );
                  let passageEquipment = null;
                  if (lastPassage !== undefined) {
                    passageEquipment = find(
                      lastPassage.passageEquipments,
                      o => o.equipment === eq.equipment_id
                    );
                  }
                  const IconKonva = selectIcon(true, eq, passageEquipment);
                  return (
                    <Group
                      key={index}
                      equipmentId={eq.equipment_id}
                      onClick={e => selectEquipment(e)}
                      x={planDetail.x}
                      y={planDetail.y}
                      onMouseEnter={() => {
                        document.body.style.cursor = "pointer";
                      }}
                      onMouseLeave={() => {
                        document.body.style.cursor = "default";
                      }}
                    >
                      <URLImage
                        usePropsWidth
                        usePropsHeight
                        equipmentId={eq.equipment_id}
                        src={IconKonva}
                        x={0}
                        y={0}
                        width={40}
                        height={eq.equipmentType === "remarqueLibre" ? 40 : 48}
                      />
                      <Text
                        equipmentId={eq.equipment_id}
                        x={0}
                        y={0}
                        text={eq.equipmentPostNumber}
                        width={40}
                        height={40}
                        fill={"#ffffff"}
                        align={"center"}
                        verticalAlign={"middle"}
                        fontSize={"18"}
                        fontStyle={"bold"}
                      />
                    </Group>
                  );
                })}
              </Layer>
            </Stage>
          )}
        </div>
      </div>
      {detailVisible && (
        <div
          className={`plan-equipment-detail plan-equipment-detail-${equipmentDetail.equipmentType}`}
        >
          <button type="button" className="btn" onClick={onClickRemove}>
            <i className="far fa-times" />
          </button>
          <div className="equipment">
            <div
              className={`equipment-icon equipment-icon-${equipmentDetail.equipmentType}`}
            >
              {equipmentDetail.equipmentPostNumber}
            </div>
            <div className="equipment-txt">
              <div className="equipment-txt-title">
                {equipmentDetail.equipmentName}
              </div>
              {equipmentDetail.equipmentType !== "remarqueLibre" && (
                <div>{EQUIPMENT_TYPES[equipmentDetail.equipmentType]}</div>
              )}
            </div>
          </div>
          {equipmentDetail.equipmentType !== "remarqueLibre" && (
            <Fragment>
              <div className="equipment-infos">
                {equipmentDetail.equipmentPosition !== "" && (
                  <div>
                    <strong>Emplacement : </strong>
                    {EQUIPMENT_LOCATION[equipmentDetail.equipmentPosition]}
                  </div>
                )}
                {equipmentDetail.equipmentLocation && (
                  <div>
                    <strong>Localisation : </strong>
                    {equipmentDetail.equipmentLocation}
                  </div>
                )}

                {equipmentDetail.equipmentLocation &&
                  equipmentDetail.equipmentBaitType && (
                    <div>
                      <strong>Type d&apos;appât : </strong>
                      {equipmentDetail.equipmentBaitType}
                    </div>
                  )}
              </div>
              <div className="equipment-infos">
                {passage !== undefined && (
                  <div>
                    <strong>Dernier relevé : </strong>
                    {moment(passage.passageDate).format(DATE_FORMAT)}
                  </div>
                )}
              </div>
            </Fragment>
          )}
          {equipmentDetail.equipmentType === "remarqueLibre" && (
            <div className="equipment-infos">
              <strong>Date : </strong>
              {moment(equipmentDetail.updated_at).format(DATE_FORMAT)}
            </div>
          )}

          {equipmentDetail.equipmentNote && (
            <div className="equipment-note">
              <strong>Remarque :</strong>
              <br />
              {equipmentDetail.equipmentNote}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

// <img alt={documentPlan.document_id} src={`${UPLOADS_URL}/${documentPlan.documentURL}`}/>

PlansDetailsView.propTypes = {
  documentPlan: PropTypes.shape({
    document_id: PropTypes.number.isRequired,
    documentName: PropTypes.string.isRequired,
    documentType: PropTypes.string.isRequired,
    documentURL: PropTypes.string.isRequired,
    documentCategory: PropTypes.number.isRequired,
    documentStartDate: PropTypes.string,
    documentEndDate: PropTypes.string,
    documentSite: PropTypes.number.isRequired
  }).isRequired,
  equipments: PropTypes.arrayOf(PropTypes.shape()),
  passages: PropTypes.objectOf(PropTypes.shape()),
  planId: PropTypes.string
};
export default PlansDetailsView;
