import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { withRouter, Switch, Route } from 'react-router-dom';
import { get } from 'lodash';

import createSelector from '../../selectors/ClientSubEntitiesSelector';

import { createOrUpdateContract, deleteContract } from '../../store/modules/contracts';

import ContractDetailView from '../../components/contracts/ContractDetailView';
import ContractEditForm from '../../components/contracts/ContractEditForm';
import ContractsCardListView from '../../components/contracts/ContractsCardListView';

import modalDefaultClass from '../../utils/ModalDefaultClass';

const clientSubEntitiesSelector = createSelector();

const RenderEditDetailsModalsRouter = ({
  clientId, url, editContract, delContract, push, contracts,
}) => (
  <Switch>
    <Route
      exact
      path={`${url}/new`}
      render={() => (
        <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
          <ContractEditForm
            initialValues={{
              contractClient: clientId,
            }}
            onSubmit={(v) => {
              editContract(v)
              .then(() => push(url));
            }}
            baseUrl={url}
            clientId={clientId}
          />
        </Modal>
      )}
    />
    <Route
      exact
      path={`${url}/:contractId/edit`}
      render={subProps => get(contracts, subProps.match.params.contractId, null) && (
        <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
          <ContractEditForm
            initialValues={contracts[subProps.match.params.contractId]}
            onSubmit={(v) => {
              editContract(v)
              .then(() => push(url));
            }}
            baseUrl={url}
            clientId={clientId}
          />
        </Modal>
      )}
    />
    <Route
      exact
      path={`${url}/:contractId`}
      render={subProps => get(contracts, subProps.match.params.contractId, null) && (
        <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
          <ContractDetailView
            contract={contracts[subProps.match.params.contractId]}
            baseUrl={url}
            onClickDelete={() => {
              delContract(subProps.match.params.contractId)
              .then(() => push(url));
            }}
            clientId={clientId}
          />
        </Modal>
      )}
    />
  </Switch>
);

RenderEditDetailsModalsRouter.propTypes = {
  url: PropTypes.string.isRequired,
  editContract: PropTypes.func.isRequired,
  delContract: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  contracts: PropTypes.objectOf(PropTypes.shape()).isRequired,
  clientId: PropTypes.number.isRequired,
};

const ContractsContainer = ({
  clientId, isAdmin, match: { url }, history: { push }, contracts, ...props
}) => (
  <Fragment>
    <ContractsCardListView
      contracts={contracts}
      baseUrl={url}
      onClickDelete={(contractId) => {
        props.deleteContract(contractId)
        .then(() => push(url));
      }}
      isAdmin={isAdmin}
    />
    <RenderEditDetailsModalsRouter
      url={url}
      editContract={props.createOrUpdateContract}
      delContract={props.deleteContract}
      push={push}
      contracts={contracts}
      clientId={clientId}
    />
  </Fragment>
);

ContractsContainer.propTypes = {
  clientId: PropTypes.number.isRequired,
  isAdmin: PropTypes.bool,

  createOrUpdateContract: PropTypes.func.isRequired,
  deleteContract: PropTypes.func.isRequired,

  contracts: PropTypes.objectOf(PropTypes.shape()).isRequired,

  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect(
  (state, { clientId }) => ({
    contracts: clientSubEntitiesSelector(state.data.entities.contracts, clientId, 'contractClient'),
  }),
  { createOrUpdateContract, deleteContract },
)(withRouter(ContractsContainer));
