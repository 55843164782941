import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { map } from 'lodash';

const SharedcategorysTableListView = ({
  options, baseUrl,
}) => (
  <div className="box">
    <div className="box-content">
      {map(options, (option, optionName) => (
        <div className="grid">
          <div className="col">
            {optionName}
            :&nbsp;
            {option}
          </div>
          <div className="btn-group">
            <NavLink to={`${baseUrl}/${optionName}/edit`} className="btn btn-primary">
              <i className="fas fa-pencil" />
            </NavLink>
          </div>
        </div>
      ))}
    </div>
  </div>
);

SharedcategorysTableListView.propTypes = {
  options: PropTypes.shape().isRequired,

  baseUrl: PropTypes.string.isRequired,
};

export default SharedcategorysTableListView;
