import { flow, set } from 'lodash/fp';
import { omit } from 'lodash';

import { CALL_API, Schemas } from '../Schemas';

const FETCH_ALL_CLIENTS_REQUEST = 'fgwa/clients/FETCH_ALL_CLIENTS_REQUEST';
export const FETCH_ALL_CLIENTS_SUCCESS = 'fgwa/clients/FETCH_ALL_CLIENTS_SUCCESS';
const FETCH_ALL_CLIENTS_FAILURE = 'fgwa/clients/FETCH_ALL_CLIENTS_FAILURE';
const FETCH_CLIENT_REQUEST = 'fgwa/clients/FETCH_CLIENT_REQUEST';
const FETCH_CLIENT_SUCCESS = 'fgwa/clients/FETCH_CLIENT_SUCCESS';
const FETCH_CLIENT_FAILURE = 'fgwa/clients/FETCH_CLIENT_FAILURE';
const SET_CLIENT_REQUEST = 'fgwa/clients/SET_CLIENT_REQUEST';
const SET_CLIENT_SUCCESS = 'fgwa/clients/SET_CLIENT_SUCCESS';
const SET_CLIENT_FAILURE = 'fgwa/clients/SET_CLIENT_FAILURE';
const DELETE_CLIENT_REQUEST = 'fgwa/clients/DELETE_CLIENT_REQUEST';
const DELETE_CLIENT_SUCCESS = 'fgwa/clients/DELETE_CLIENT_SUCCESS';
const DELETE_CLIENT_FAILURE = 'fgwa/clients/DELETE_CLIENT_FAILURE';

export const clientsActionsHandlers = {
  [FETCH_ALL_CLIENTS_REQUEST]: state => flow(
    set('loaded.clients', false),
    set('loading.clients', true),
  )(state),
  [FETCH_ALL_CLIENTS_SUCCESS]: (state, action) => flow(
    set('entities.clients', action.response.entities.clients || {}),
    set('loaded.clients', true),
    set('loading.clients', false),
  )(state),
  [FETCH_ALL_CLIENTS_FAILURE]: state => flow(
    set('loaded.clients', false),
    set('loading.clients', false),
  )(state),
  [FETCH_CLIENT_SUCCESS]: (state, action) => flow(
    set(`entities.clients.${action.id}`, action.response.entities.clients),
  )(state),
  [SET_CLIENT_SUCCESS]: (state, action) => flow(
    set('entities.clients', {
      ...state.entities.clients,
      ...action.response.entities.clients,
    }),
  )(state),
  [DELETE_CLIENT_SUCCESS]: (state, action) => flow(
    set('entities.clients', omit(state.entities.clients, action.id)),
  )(state),
};

export function loadClient (id) {
  return {
    id,
    [CALL_API]: {
      types: [
        FETCH_CLIENT_REQUEST,
        FETCH_CLIENT_SUCCESS,
        FETCH_CLIENT_FAILURE,
      ],
      method: 'GET',
      endpoint: `/clients/${id}`,
      schema: Schemas.CLIENT,
    },
  };
}

export function loadClients () {
  return {
    [CALL_API]: {
      types: [
        FETCH_ALL_CLIENTS_REQUEST,
        FETCH_ALL_CLIENTS_SUCCESS,
        FETCH_ALL_CLIENTS_FAILURE,
      ],
      method: 'GET',
      endpoint: '/clients',
      schema: Schemas.CLIENT_ARRAY,
    },
  };
}

export function createOrUpdateClient (client) {
  return {
    [CALL_API]: {
      types: [
        SET_CLIENT_REQUEST,
        SET_CLIENT_SUCCESS,
        SET_CLIENT_FAILURE,
      ],
      method: !client || !Number.isInteger(client.client_id) ? 'POST' : 'PUT',
      endpoint: !client || !Number.isInteger(client.client_id) ? '/clients' : `/clients/${client.client_id}`,
      schema: Schemas.CLIENT,
      body: client,
      successMessage: 'Client enregistrée avec succès',
    },
  };
}

export function deleteClient (id) {
  return {
    id,
    [CALL_API]: {
      types: [
        DELETE_CLIENT_REQUEST,
        DELETE_CLIENT_SUCCESS,
        DELETE_CLIENT_FAILURE,
      ],
      method: 'DELETE',
      endpoint: `/clients/${id}`,
      successMessage: 'Client supprimé avec succès',
    },
  };
}
