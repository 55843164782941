import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { map, sortBy, orderBy, head, values, assign } from 'lodash';
import moment from 'moment';
import {
  SelectField,
} from 'react-components-linaia';

import { UPLOADS_URL } from '../../../constants/Config';

const ReportsListView = ({
  history: { push }, reportsDocuments, trackingDocuments, baseUrl, contracts, selectedContract,
}) => {
  // order Contract
  let orderContract = sortBy(contracts, cont => (moment(cont.contractStartDate)));
  // show n° contract - label in the select field
  orderContract = (map(orderContract, contract => ({
    ...contract,
    contractInputLabel: contract.contractNumber + ' - ' + contract.contractLabel,
  })));

  if (selectedContract === undefined && orderContract && head(orderContract)) {
    const contractId = head(orderContract).contract_id;
    push(`${baseUrl}/${contractId}`);
    return null;
  }

  const changeContract = contractId => push(`${baseUrl}/${contractId}`);
  return (
    <div className="col-3_sm-12">
      <div className="grid">
        <div className="col-12">
          <div className="box box-secondary noShadow">
            <div className="box-header">
              <div className="box-title">
                Contrat
              </div>
            </div>
            <div className="box-content">
              <SelectField
                classNamePrefix="react-select"
                classNameReactSelect="react-select"
                input={{
                  value: selectedContract,
                  onChange: changeContract,
                }}
                options={assign({}, orderContract)}
                disableSortByValue
                optionsKeys={{ value: 'contract_id', label: 'contractInputLabel' }}
              />
            </div>
          </div>
        </div>
        {reportsDocuments && values(reportsDocuments).length > 0 &&(
          <div className="col-12">
            <div className="box"> 
              <div className="box-header">
                <div className="box-title">
                  Rapports d'interventions
                </div>
              </div>
              <div className="box-content">
                <div className="elts-list reports-list">
                  {map(orderBy(reportsDocuments, ["documentStartDate"],['desc']), reportDocument => (
                    <a className="elt" href={`${UPLOADS_URL}/${reportDocument.documentURL}`} target="_blank" rel="noopener noreferrer">
                      <i className="fas fa-file-pdf" />
                      <span>
                        {reportDocument.documentName}
                      </span>
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
        {trackingDocuments && values(trackingDocuments).length > 0 && (
          <div className="col-12">
            <div className="box">
              <div className="box-header">
                <div className="box-title">
                  Tableau de suivi
                </div>
              </div>
              <div className="box-content">
                <div className="elts-list tracking-list">
                  {map(orderBy(trackingDocuments, ["documentStartDate"], ['desc']), trackingDocument => (
                    <a className="elt" href={`${UPLOADS_URL}/${trackingDocument.documentURL}`} target="_blank" rel="noopener noreferrer">
                      <i className="fas fa-file-pdf" />
                      <span>
                        {trackingDocument.documentName}
                      </span>
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

ReportsListView.propTypes = {
  reportsDocuments: PropTypes.objectOf(PropTypes.shape({
    document_id: PropTypes.number.isRequired,
    documentName: PropTypes.string.isRequired,
    documentType: PropTypes.string.isRequired,
    documentURL: PropTypes.string.isRequired,
    documentCategory: PropTypes.number.isRequired,
    documentStartDate: PropTypes.string,
    documentEndDate: PropTypes.string,
    documentSite: PropTypes.number.isRequired,
  })).isRequired,

  trackingDocuments: PropTypes.objectOf(PropTypes.shape({
    document_id: PropTypes.number.isRequired,
    documentName: PropTypes.string.isRequired,
    documentType: PropTypes.string.isRequired,
    documentURL: PropTypes.string.isRequired,
    documentCategory: PropTypes.number.isRequired,
    documentStartDate: PropTypes.string,
    documentEndDate: PropTypes.string,
    documentSite: PropTypes.number.isRequired,
  })).isRequired,

  contracts: PropTypes.objectOf(PropTypes.shape({
    contractAffectedSite: PropTypes.number.isRequired,
    contractClient: PropTypes.number.isRequired,
    contractEndDate: PropTypes.string,
    contractLabel: PropTypes.string.isRequired,
    contractNbPassage: PropTypes.number.isRequired,
    contractNumber: PropTypes.string,
    contractStartDate: PropTypes.string,
    contractType: PropTypes.string,
    contract_id: PropTypes.number.isRequired,
  })).isRequired,
  selectedContract: PropTypes.string,

  baseUrl: PropTypes.string.isRequired,

  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(ReportsListView);
