import React, { Fragment, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { map, sortBy, get, assign, filter, indexOf } from 'lodash';
import moment from 'moment';
import {
  SelectField,
} from 'react-components-linaia';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { createOrUpdateEquipment } from '../../store/modules/equipments';

import createSelector from '../../selectors/ClientSubEntitiesSelector';

import { createOrUpdateSite, deleteSite } from '../../store/modules/sites';
import TechniciansAdminSitesHeader from '../technicians/TechniciansAdminSitesHeader';

import DynamicPlanEditor from '../dynamicplan/DynamicPlanEditor';

import { EQUIPMENT_TYPES } from '../../constants/Properties';

const clientSubEntitiesSelector = createSelector();


class List extends React.Component {
  render () {
    const { provided, innerRef, children } = this.props;
    return (
      <div {...provided.droppableProps} ref={innerRef}>
        {children}
      </div>
    );
  }
}

/* eslint-disable react/no-multi-comp */
class DraggableEquipments extends React.Component {
  render () {
    const { provided, innerRef, data, equipment, planId } = this.props;
    return (
      <div
        {...provided.dragHandleProps}
        {...provided.draggableProps}
        ref={innerRef}
      >
        {data && (
          <div className="equipment">
            <div className="equipment-icon equipment-icon-note" />
            <div className="equipment-txt">
              <div className="equipment-txt-title">{data.text}</div>
            </div>
          </div>
        )}
        {equipment && (
          <div className={`equipment ${indexOf(equipment.equipmentPlan, planId) !== -1 ? 'disable' : ''}`}>
            <div className={`equipment-icon equipment-icon-${equipment.equipmentType}`}>
              {equipment.equipmentPostNumber}
            </div>
            <div className="equipment-txt col">
              <div className="equipment-txt-title">
                {equipment.equipmentName}
              </div>
              <div>
                {equipment.equipmentLocation}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

let x = 0;
let y = 0;

const SitesTechniciansAdminView = ({
  match: { url }, history: { push }, planId, contractId, siteId, baseUrl, equipments, contracts, selectedContract, documents,
}) => {

  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const ref = useRef(null);
  const mouseUpCb = (ev) => {
    ev.preventDefault();
  };

  useEffect(() => {
    const elem = ref.current;
    if (elem) {
      setHeight(elem.clientHeight)
      setWidth(elem.clientWidth)
      elem.addEventListener('mouseup', mouseUpCb);
    }
  });

  // order Contract
  let orderContract = sortBy(contracts, cont => (moment(cont.contractStartDate)));
  // show n° contract - label in the select field
  orderContract = (map(orderContract, contract => ({
    ...contract,
    contractInputLabel: contract.contractNumber + ' - ' + contract.contractLabel,
  })));

  // init values at the begining
  // if (selectedContract === undefined && orderContract && head(orderContract)) {
  //   const contractId = head(orderContract).contract_id;
  //   const equipmentPlan = head(map(documents));
  //   push(`${baseUrl}/${contractId}${equipmentPlan ? `/plan/${equipmentPlan.document_id}` : ''}`);
  //   return null;
  // }

  // il faut que l'éqauipment soit dans le meme contract,
  // mais qu'il n'ai pas deja une valeur de contrat dans le paln detail
  const equipmentsContract = sortBy(
    filter(equipments, (eq) => {
      let mapPlanDetail = [];
      if (eq.equipmentPlanDetail && eq.equipmentPlanDetail !== undefined && eq.equipmentPlanDetail.length > 0) {
        mapPlanDetail = map(eq.equipmentPlanDetail, planDetail => {
          return JSON.parse(planDetail).contractId !== parseInt(selectedContract, 10)
        });
      }
      return (
        eq.equipmentContract === parseInt(selectedContract, 10)
        && eq.equipmentType !== 'remarqueLibre'
        && indexOf(eq.equipmentPlan, parseInt(planId, 10)) === -1
        && mapPlanDetail && (mapPlanDetail.length === 0 || (mapPlanDetail.length > 0 && mapPlanDetail.indexOf(false) === -1)))
      // && (equipmentPlanDetail === null || (equipmentPlanDetail !== null && equipmentPlanDetail[parseInt(planId, 10)].contractId === null))
        // && eq.equipmentType !== 'remarqueLibre')
    }),
    eq => (parseInt(eq.equipmentPostNumber, 10).isNaN ? 0 : parseInt(eq.equipmentPostNumber, 10)),
  );


  const changeContract = contrId => push(`${baseUrl}/${contrId}/contract/${planId}/plan`);

  function getStyle (style, snapshot) {
    if (!snapshot.isDropAnimating) {
      return style;
    }
    // patching the existing style
    return {
      ...style,
      transition: '0.001s',
    };
  }

  const documentPlan = get(documents, parseInt(planId, 10));
  return (
    <Fragment>
      {planId !== null && selectedContract !== null && (
        <Fragment>
          <div className="grid-noBottom">
            <TechniciansAdminSitesHeader
              baseUrl={url}
              documents={documents}
            />
          </div>
          <DragDropContext
            onDragEnd={(e) => {
              if (e !== undefined && e.destination && e.destination.droppableId === 'dynamicPlan') {
                const equipment = {
                  equipmentId: e.draggableId,
                  equipmentX: x,
                  equipmentY: y,
                };
                // we need to call the modal to edit the canva
                push({
                  pathname: `${url}/edit/${equipment.equipmentId - 1}`,
                  state: { x: equipment.equipmentX, y: equipment.equipmentY },
                });
              }
            }}
            onDragStart={() => {
              // we need to save x y mouse move
              window.addEventListener('mousemove', (event) => {
                event.preventDefault();
                if (event.layerX && event.layerY) {
                  x = event.layerX;
                  y = event.layerY;
                }
                // event has already been used for drag and drop
              });
            }}
          >
            <div
              className="grid-noBottom full-height"
            >
              <div className="col-3_sm-12 canvas-elements-list">
                <div className="grid-column canvas-elements-grid">
                  <div className="col-12">
                    <div className="box box-secondary noShadow">
                      <div className="box-header">
                        <div className="box-title">
                          Contrat
                        </div>
                      </div>
                      <div className="box-content">
                        <SelectField
                          classNamePrefix="react-select"
                          classNameReactSelect="react-select"
                          input={{
                            value: selectedContract,
                            onChange: changeContract,
                          }}
                          options={assign({}, orderContract)}
                          disableSortByValue
                          optionsKeys={{ value: 'contract_id', label: 'contractInputLabel' }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 equipment-list-wrapper">
                    <div className="box">
                      <div className="box-header">
                        <div className="box-title">
                          Liste des équipements
                        </div>
                      </div>
                      <div className="box-content">
                        <Droppable key={'equipmentList'} droppableId="equipmentList">
                          {(provided, snapshot) => (
                            <List provided={provided} innerRef={provided.innerRef}>
                              <Draggable draggableId={-1} index={0} key={0}>
                                {provided => (
                                  <DraggableEquipments
                                    provided={provided}
                                    innerRef={provided.innerRef}
                                    key={0}
                                    id={0}
                                    data={{ image: 'fa-exclamation-triangle', text: 'Point d\'attention' }}
                                  />
                                )}
                              </Draggable>
                              {map(equipmentsContract, (equipment, index) => (
                                <Draggable
                                  isDropAnimating={false}
                                  draggableId={equipment.equipment_id + 1}
                                  index={index}
                                  key={index}
                                >
                                  {(provided, snapshot) => (
                                    <DraggableEquipments
                                      style={getStyle(provided.draggableProps.style, snapshot)}
                                      provided={provided}
                                      innerRef={provided.innerRef}
                                      id={equipment.equipment_id}
                                      equipment={equipment}
                                    />
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </List>
                          )}
                        </Droppable>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-9_sm-12 canvas-container" ref={ref}>
                <Droppable
                  key={'dynamicPlan'}
                  droppableId="dynamicPlan"
                >
                  {(provided, snapshot) => {
                    return (
                      <List
                        {...provided.dragHandleProps}
                        provided={provided}
                        innerRef={provided.innerRef}
                      >
                        {planId && documentPlan && (
                          <DynamicPlanEditor
                            addEquipment={(equipment) => {
                              push({
                                pathname: `${url}/edit/${equipment.equipmentId}`,
                                state: { x: equipment.equipmentX, y: equipment.equipmentY },
                              })
                            }}
                            baseUrl={url}
                            documentPlan={documentPlan}
                            equipmentContract={selectedContract}
                            onClickEdit={(e) =>{
                              if (e.currentTarget.attrs.equipmentId !== undefined) {
                                push(`${url}/edit/${e.currentTarget.attrs.equipmentId}`);
                              }
                            }}
                            contractId={selectedContract}
                            planId={planId}
                            width={650}
                            height={600}
                          />
                        )}
                      </List>
                    )
                  }}
                </Droppable>
              </div>
            </div>
          </DragDropContext>
        </Fragment>
      )}
      {planId === null && (
        <div>
          Aucun plan pour ce site
          <button onClick={() => (push(`${baseUrl}`))}>Revenir à la liste des sites</button>
        </div>
      )}
      {selectedContract === null && (
        <div>
          Aucun Contract pour ce site
          <button onClick={() => (push(`${baseUrl}`))}>Revenir à la liste des sites</button>
        </div>
      )}
    </Fragment>
  );
};

SitesTechniciansAdminView.propTypes = {
  documents: PropTypes.objectOf(PropTypes.shape()),

  contracts: PropTypes.objectOf(PropTypes.shape({
    contractAffectedSite: PropTypes.number.isRequired,
    contractClient: PropTypes.number.isRequired,
    contractEndDate: PropTypes.string,
    contractLabel: PropTypes.string.isRequired,
    contractNbPassage: PropTypes.number.isRequired,
    contractNumber: PropTypes.string,
    contractStartDate: PropTypes.string,
    contractType: PropTypes.string,
    contract_id: PropTypes.number.isRequired,
  })),
  equipments: PropTypes.objectOf(PropTypes.shape()),
  selectedContract: PropTypes.number,
  planId: PropTypes.number,
  contractId: PropTypes.number,
  siteId: PropTypes.number,
  baseUrl: PropTypes.string.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect(
  (state, { clientId }) => ({
    sites: clientSubEntitiesSelector(state.data.entities.sites, clientId, 'siteClient'),
  }),
  { createOrUpdateSite, createOrUpdateEquipment, deleteSite },
)(withRouter(SitesTechniciansAdminView));
