import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";

import { capitalize, get } from "lodash";
import { ButtonConfirmModal } from "react-components-linaia";

import TableListView from "../shared/TableListView";

import { UPLOADS_URL } from "../../constants/Config";

const BillsTableListView = ({
  history: { push },
  bills,
  isAdmin,
  baseUrl,
  onClickDelete,
  clientId,
  clients
}) => {
  const information = get(clients, `${clientId}.clientOutstanding`)
    ? `En attente de réglement : ${get(
        clients,
        `${clientId}.clientOutstanding`
      )} €`
    : "";

  return (
    <TableListView
      className="table-boxed full-height"
      title={!isAdmin ? "Factures" : null}
      data={bills}
      cols={{
        factureNumero: {
          label: "Numéro",
          className: "col_sm-12",
          render: capitalize
        },
        factureDate: {
          label: "Date",
          className: "col_sm-12",
          render: factureDate =>
            factureDate && moment(factureDate).format("DD/MM/YYYY")
        },
        factureLibelle: {
          label: "Libellé",
          className: "col_sm-12",
          render: capitalize
        },
        factureHT: {
          label: "Montant HT",
          className: "col_sm-12 col-price",
          render: factureHT =>
            new Intl.NumberFormat("fr-FR", {
              style: "currency",
              currency: "EUR"
            }).format(factureHT)
        },
        factureTTC: {
          label: "Montant TTC",
          className: "col_sm-12 col-price",
          render: factureTTC =>
            new Intl.NumberFormat("fr-FR", {
              style: "currency",
              currency: "EUR"
            }).format(factureTTC)
        }
      }}
      search={["factureLibelle", "factureNumero", "factureTTC", "factureHT"]}
      searchLabel="Rechercher une facture"
      sortByDefault="factureDate"
      sortOrderDefault = 'desc'
      information={isAdmin ? null : information}
      // buttons={
      //   !isAdmin ? null : (
      //     <div>
      //       <NavLink className="btn btn-main-mobile" to={`${baseUrl}/new`}>
      //         <span>Ajouter une facture</span>
      //         <i className="far fa-plus" />
      //       </NavLink>
      //     </div>
      //   )
      // }
      // rowButtons={
      //   !isAdmin
      //     ? null
      //     : bill => (
      //         <div className="col-0_sm-12 cln-2btn">
      //           <div className="td td-buttons">
      //             {bill && (
      //               <Fragment>
      //                 <NavLink
      //                   className="btn"
      //                   to={`${baseUrl}/${bill.bill_id}/edit`}
      //                 >
      //                   <i className="far fa-pencil" />
      //                 </NavLink>
      //                 <ButtonConfirmModal
      //                   onConfirmationButton={() => onClickDelete(bill.bill_id)}
      //                   message="Voulez-vous vraiment effacer cette facture ?"
      //                 >
      //                   <button className="btn" type="button">
      //                     <i className="far fa-trash" />
      //                   </button>
      //                 </ButtonConfirmModal>
      //               </Fragment>
      //             )}
      //           </div>
      //         </div>
      //       )
      // }
    />
  );
};

BillsTableListView.propTypes = {
  bills: PropTypes.objectOf(
    PropTypes.shape({
      bill_id: PropTypes.number.isRequired,
      factureHT: PropTypes.number.isRequired,
      factureTTC: PropTypes.number.isRequired,
      factureLibelle: PropTypes.string.isRequired,
      factureNumero: PropTypes.number.isRequired,
      factureClient: PropTypes.number.isRequired,
      factureDate: PropTypes.string.isRequired
    })
  ).isRequired,
  isAdmin: PropTypes.bool,
  clientId: PropTypes.number.isRequired,

  clients: PropTypes.objectOf(
    PropTypes.shape({
      client_id: PropTypes.number.isRequired,
      clientName: PropTypes.string.isRequired,
      clientOutstanding: PropTypes.number
    })
  ).isRequired,

  baseUrl: PropTypes.string.isRequired,
  onClickDelete: PropTypes.func,

  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default connect(state => ({
  clients: state.data.entities.clients
}))(withRouter(BillsTableListView));
