import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { withRouter, Switch, Route } from 'react-router-dom';
import { get } from 'lodash';

import createSelector from '../../selectors/ClientSubEntitiesSelector';

import { createOrUpdateContact, deleteContact } from '../../store/modules/contacts';

import ContactDetailView from '../../components/contacts/ContactDetailView';
import ContactEditForm from '../../components/contacts/ContactEditForm';
import ContactsCardListView from '../../components/contacts/ContactsCardListView';

import modalDefaultClass from '../../utils/ModalDefaultClass';

const clientSubEntitiesSelector = createSelector();

const RenderEditDetailsModalsRouter = ({
  url, editContact, delContact, push, contacts, accounts, clientId,
}) => (
  <Switch>
    <Route
      exact
      path={`${url}/new`}
      render={() => (
        <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
          <ContactEditForm
            initialValues={{
              contactClient: clientId,
              contactBillingAccess: false,
            }}
            onSubmit={(v) => {
              editContact(v)
              .then(() => push(url));
            }}
            baseUrl={url}
            clientId={clientId}
          />
        </Modal>
      )}
    />
    <Route
      exact
      path={`${url}/:contactId/edit`}
      render={subProps => get(contacts, subProps.match.params.contactId, null) && (
        <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
          <ContactEditForm
            initialValues={{
              ...contacts[subProps.match.params.contactId],
              contactEmail: get(accounts, `${get(contacts, `${subProps.match.params.contactId}.contactAccount`)}.email`),
            }}
            onSubmit={(v) => {
              editContact(v)
              .then(() => push(url));
            }}
            baseUrl={url}
            clientId={clientId}
          />
        </Modal>
      )}
    />
    <Route
      exact
      path={`${url}/:contactId`}
      render={subProps => get(contacts, subProps.match.params.contactId, null) && (
        <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
          <ContactDetailView
            contact={contacts[subProps.match.params.contactId]}
            baseUrl={url}
            onClickDelete={() => {
              delContact(subProps.match.params.contactId)
              .then(() => push(url));
            }}
            clientId={clientId}
          />
        </Modal>
      )}
    />
  </Switch>
);

RenderEditDetailsModalsRouter.propTypes = {
  url: PropTypes.string.isRequired,
  editContact: PropTypes.func.isRequired,
  delContact: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  contacts: PropTypes.objectOf(PropTypes.shape()).isRequired,
  accounts: PropTypes.objectOf(PropTypes.shape()).isRequired,
  clientId: PropTypes.number.isRequired,
};

const ContactsContainer = ({
  clientId, match: { url }, history: { push }, contacts, accounts, ...props
}) => (
  <Fragment>
    <ContactsCardListView
      contacts={contacts}
      baseUrl={url}
      onClickDelete={(contactId) => {
        props.deleteContact(contactId)
        .then(() => push(url));
      }}
      clientId={clientId}

    />
    <RenderEditDetailsModalsRouter
      url={url}
      editContact={props.createOrUpdateContact}
      delContact={props.deleteContact}
      push={push}
      contacts={contacts}
      accounts={accounts}
      clientId={clientId}
    />
  </Fragment>
);

ContactsContainer.propTypes = {
  clientId: PropTypes.number.isRequired,

  createOrUpdateContact: PropTypes.func.isRequired,
  deleteContact: PropTypes.func.isRequired,

  contacts: PropTypes.objectOf(PropTypes.shape()).isRequired,
  accounts: PropTypes.objectOf(PropTypes.shape()).isRequired,

  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect(
  (state, { clientId }) => ({
    contacts: clientSubEntitiesSelector(state.data.entities.contacts, clientId, 'contactClient'),
    accounts: state.data.entities.accounts,
  }),
  { createOrUpdateContact, deleteContact },
)(withRouter(ContactsContainer));
