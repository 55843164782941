import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import fileExtension from 'file-extension';
import {
  map, get, slice, values, pickBy,
} from 'lodash';

import {
  SelectField,
} from 'react-components-linaia';

import { UPLOADS_URL } from '../../constants/Config';

const ShareddocumentListView = ({ shareddocuments, sharedcategorys, nbItemPerPage }) => {
  const [filter, setfilter] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);

  const [filteredData, setFilterData] = useState(null);
  useEffect(() => {
    setCurrentPage(0);
    if (filter === null) {
      return setFilterData(shareddocuments);
    }
    return setFilterData(pickBy(
      shareddocuments,
      d => d.shareddocumentCategory === filter,
    ));
  }, [filter, shareddocuments]);
  const [displayedDocu, setDisplayedDocu] = useState(filteredData);
  useEffect(() => {
    setDisplayedDocu(slice(
      values(filteredData),
      currentPage * nbItemPerPage,
      (currentPage + 1) * nbItemPerPage,
    ));
  }, [filteredData, currentPage, nbItemPerPage]);

  const nbTotalPage = Math.ceil(values(filteredData).length / nbItemPerPage);
  return (
    <div className="box full-height">
      <div className="box-header">
        <div className="box-title">
          Documents
        </div>
        <SelectField
          classNamePrefix="react-select"
          classNameReactSelect="react-select"
          input={{
            value: filter,
            onChange: setfilter,
          }}
          options={sharedcategorys}
          optionsKeys={{ value: 'sharedcategory_id', label: 'sharedcategoryName' }}
          removable
        />
      </div>
      <div className="box-content">
        <div className="news-list">
          {map(displayedDocu, (shareddocument, shareddocumentId) => (
            <div key={shareddocumentId} className="shared-document">
              <div className="grid-noBottom">
                <div className="col-0">
                  { /* TODO ajouter extension du fichier dans la className ex: pdf, doc, docx, csv...*/ }
                  <div className={`doc-cat-icon ${shareddocument.shareddocumentType === 'lien' ? 'link' : fileExtension(shareddocument.shareddocumentFile)}`}>
                    <i />
                  </div>
                </div>
                <div className="col">
                  <div className="shared-document-name" key={shareddocumentId}>
                    <a href={shareddocument.shareddocumentType === 'lien' ? shareddocument.shareddocumentFile : `${UPLOADS_URL}/${shareddocument.shareddocumentFile}`} target="_blank">{shareddocument.shareddocumentName}</a>
                  </div>
                  <div className="shared-document-cat">
                    {get(sharedcategorys, `${shareddocument.shareddocumentCategory}.sharedcategoryName`)}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="pagination btn-group center">
          {map(new Array(nbTotalPage), (v, i) => (
            <button key={i} type="button" onClick={() => setCurrentPage(i)} className={currentPage === i ? 'btn btn-primary active' : 'btn btn-primary'}>
              {(i + 1)}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

ShareddocumentListView.propTypes = {
  shareddocuments: PropTypes.objectOf(PropTypes.shape({
    shareddocument_id: PropTypes.number.isRequired,
    shareddocumentName: PropTypes.string.isRequired,
    shareddocumentCategory: PropTypes.number.isRequired,
    shareddocumentFile: PropTypes.string.isRequired,
    shareddocumentStartDate: PropTypes.string,
    shareddocumentEndDate: PropTypes.string,
  })).isRequired,
  nbItemPerPage: PropTypes.number,

  sharedcategorys: PropTypes.objectOf(PropTypes.shape()).isRequired,
};

export default connect(
  state => ({
    shareddocuments: state.data.entities.shareddocuments,
    sharedcategorys: state.data.entities.sharedcategorys,
  }),
)(withRouter(ShareddocumentListView));
