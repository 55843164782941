import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { NavLink } from 'react-router-dom';
import { Field, InputField, ToggleField } from 'react-components-linaia';

const ClientEditForm = ({ handleSubmit, baseUrl, initialValues }) => (
  <Fragment>
    <div className="modal-title">
      <div className="modal-title-content">
        {initialValues.client_id >= 0
          ? 'Modifier le client'
          : 'Ajouter un client'}
        <NavLink className="btn" to={baseUrl}>
          <i className="fal fa-times" />
        </NavLink>
      </div>
    </div>
    <form onSubmit={handleSubmit}>
      <div className="modal-body">
        <div className="grid">
          <Field
            name="clientName"
            component={InputField}
            label="Nom"
            className="col-12"
          />
          <Field
            name="clientReference"
            component={InputField}
            label="Référence Client"
            className="col-12"
          />
          <Field
            name="clientOutstanding"
            component={InputField}
            type="number"
            label="Encours client"
            className="col-12"
          />
          <Field
            name="clientDivalto"
            component={ToggleField}
            label="Synchronisation Divalto"
            className="col-12"
            fieldClassName="checkbox"
          />
        </div>
      </div>
      <div className="modal-footer">
        <div className="btn-group center">
          <button className="btn btn-primary" type="submit">
            Enregistrer
          </button>
        </div>
      </div>
    </form>
  </Fragment>
);

ClientEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
  initialValues: PropTypes.shape().isRequired
};

export default reduxForm({
  form: 'ClientEditForm',
  validate: (values = {}) => {
    const errors = {};

    if (!values.clientName)
      errors.clientName = 'Merci de renseigner le nom du client';

    return errors;
  }
})(ClientEditForm);
