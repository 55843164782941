import React, { useState, useEffect, useReducer, Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Stage, Layer, Rect, Text, Group
} from 'react-konva';
import { withRouter } from 'react-router-dom';
import useImage from 'use-image';
import fetch from 'isomorphic-fetch';


import { map, filter, find, remove } from 'lodash';
import Konva from 'konva';
import DropItem from '../drop-item/DropItem';
import URLImage from './URLImage';
import { UPLOADS_URL } from '../../constants/Config';

import IconDEIV from '../../../assets/images/icons/icon-deiv.svg';
import IconTEIGNE from '../../../assets/images/icons/icon-teigne.svg';
import IconDERAT from '../../../assets/images/icons/icon-derat.svg';
import IconRL from '../../../assets/images/icons/icon-note.svg';
import { createOrUpdateEquipment, deleteEquipmentPlan } from '../../store/modules/equipments';

function reducer (currentSrc, action) {
  if (action.type === 'main image loaded') {
    return action.response;
  }
  if (!currentSrc) {
    return action.src;
  }
  return currentSrc;
}


function useProgressiveImage ({ src }) {
  const [currentSrc, dispatch] = React.useReducer(reducer, null);
  useEffect(() => {
    const mainImage = new Image();
    mainImage.onload = (e) => {
      if (e.target) {
        dispatch({
          type: 'main image loaded',
          response: {
            src,
            width: e.target.width,
            height: e.target.height,
          },
        });
      }
      if (e.path[0] !== undefined) {
        dispatch({
          type: 'main image loaded',
          response: {
            src,
            width: e.path[0].width,
            height: e.path[0].height,
          },
        });
      }

    };

    mainImage.src = src;
  }, [src]);
  return currentSrc;
}

const DynamicPlanEditor = ({
  plan, baseUrl, addEquipment, equipments, documentPlan, onClickEdit, planId, contractId, ...props
}) => {
  const [color, setColor] = useState(Konva.Util.getRandomColor());
  const equipmentsPlan = filter(equipments, (eq) => {
    // let equipmentPlanDetail = null;
    // if (eq.equipmentPlanDetail !== '{}') {
    //   equipmentPlanDetail = JSON.parse(eq.equipmentPlanDetail);
    // }
    const mapPlanDetail = map(eq.equipmentPlanDetail, planDetail => JSON.parse(planDetail).planId === planId);
    return (
      eq.equipmentPlan
      && eq.equipmentPlan.indexOf(planId) !== -1
      && mapPlanDetail && mapPlanDetail.length > 0 && mapPlanDetail.indexOf(true) !== -1)
      // && equipmentPlanDetail !== null
      // && equipmentPlanDetail[parseInt(contractId, 10)] !== null
      // && equipmentPlanDetail[parseInt(contractId, 10)] !== undefined);
  });
  const NumberPlanId = planId;
  const imageBackground = useProgressiveImage({
    src: `${UPLOADS_URL}${documentPlan.documentURL}`,
  });
  // const [size, setSize] = useState({ width, height });
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    setHeight(ref.current.clientHeight)
    setWidth(ref.current.clientWidth)
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const calculPosition = (pos, widthGroup, heightGroup) => {
    let retourX = pos.x;
    let retourY = pos.y;
    const x = widthGroup - 40;
    // -40 la taille du carré réprésentant l'équipement
    const y = heightGroup - 48;
    // -48 la taille du carré réprésentant l'équipement
    if (x < (pos.x)) {
      retourX = x;
    }
    if (y < (pos.y)) {
      retourY = y;
    }
    if ((pos.x) <= 0) {
      retourX = 0;
    }
    if ((pos.y) <= 0) {
      retourY = 0;
    }
    return { x: retourX, y: retourY };
  };

  return (
    <div ref={ref}>
      <DropItem
        onDrop={(id, x, y) => {
          const eventEquipment = { equipmentId: id, equipmentX: x, equipmentY: y };
          // we need to call the modal to edit the canva
          addEquipment(eventEquipment);
        }}
      >
        {documentPlan && documentPlan.documentURL && imageBackground && (
          <Stage
            width={imageBackground.width}
            height={imageBackground.height}
          >
            <Layer>
              <Group>
                <URLImage
                  src={`${UPLOADS_URL}${documentPlan.documentURL}`}
                  width={imageBackground.width}
                  height={imageBackground.height}
                  opacity={0.6}
                />
              </Group>
              {
                map(equipmentsPlan, (eq, index) => {
                  const planDetail = JSON.parse(find(eq.equipmentPlanDetail, equipPlanDetail => (JSON.parse(equipPlanDetail).planId === planId)));
                  let Icon;
                  switch (eq.equipmentType) {
                  case 'baitStation':
                    Icon = IconDERAT;
                    break;
                  case 'DEIV':
                    Icon = IconDEIV;
                    break;
                  case 'mothsTrap':
                    Icon = IconTEIGNE;
                    break;
                  case 'remarqueLibre':
                    Icon = IconRL;
                    break;
                  default:
                    Icon = IconDERAT;
                  }
                  return (
                    <Group
                      key={index}
                      equipmentId={eq.equipment_id}
                      onClick={e => (
                        onClickEdit(e)
                      )}
                      onClick={e => ( onClickEdit(e) )}
                      onMouseEnter={() => { document.body.style.cursor = 'pointer'; }}
                      onMouseLeave={() => { document.body.style.cursor = 'default'; }}
                      draggable
                      onDragEnd={(e) => {
                        // TODO we need to add contractID !!!!!!!!!!!!!!!!!!!!!!!!!!
                        remove(eq.equipmentPlanDetail, equipPlanDetail => (JSON.parse(equipPlanDetail).planId === planId && (JSON.parse(equipPlanDetail).contractId === contractId)));
                        planDetail.x = e.currentTarget.attrs.x;
                        planDetail.y = e.currentTarget.attrs.y;
                        eq.equipmentPlanDetail.push(JSON.stringify(planDetail));
                        props.createOrUpdateEquipment(eq);
                        return { x: e.currentTarget.attrs.x, y: e.currentTarget.attrs.y };
                      }}
                      x={planDetail.x}
                      y={planDetail.y}
                      dragBoundFunc={pos => (calculPosition(pos, imageBackground.width, imageBackground.height, planDetail[NumberPlanId]))}
                      width={40}
                      height={40}
                    >
                      <URLImage
                        usePropsWidth
                        usePropsHeight
                        equipmentId={eq.equipment_id}
                        src={Icon}
                        x={0}
                        y={0}
                        width={40}
                        height={eq.equipmentType === 'remarqueLibre' ? 40 : 48}
                      />
                      <Text
                        equipmentId={eq.equipment_id}
                        x={0}
                        y={0}
                        text={eq.equipmentPostNumber}
                        width={40}
                        height={40}
                        fill={'#ffffff'}
                        align={'center'}
                        verticalAlign={'middle'}
                        fontSize={'18'}
                        fontStyle={'bold'}
                      />
                    </Group>
                  );
                })
              }
            </Layer>
          </Stage>
        )}
      </DropItem>
    </div>
  );
};

DynamicPlanEditor.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,

  plan: PropTypes.shape(),
  planId: PropTypes.number,
  contractId: PropTypes.number,
  baseUrl: PropTypes.string.isRequired,

  addEquipment: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func.isRequired,

  documentPlan: PropTypes.shape(),

  equipments: PropTypes.objectOf(PropTypes.shape()),

  createOrUpdateEquipment: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    equipments: state.data.entities.equipments,
  }),
  { createOrUpdateEquipment, deleteEquipmentPlan },
)(withRouter(DynamicPlanEditor));
