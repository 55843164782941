import { flow, set } from 'lodash/fp';
import { omit } from 'lodash';

import { CALL_API, Schemas } from '../Schemas';

const FETCH_ALL_STRUCTURES_REQUEST = 'fgwa/structures/FETCH_ALL_STRUCTURES_REQUEST';
export const FETCH_ALL_STRUCTURES_SUCCESS = 'fgwa/structures/FETCH_ALL_STRUCTURES_SUCCESS';
const FETCH_ALL_STRUCTURES_FAILURE = 'fgwa/structures/FETCH_ALL_STRUCTURES_FAILURE';
const FETCH_STRUCTURE_REQUEST = 'fgwa/structures/FETCH_STRUCTURE_REQUEST';
const FETCH_STRUCTURE_SUCCESS = 'fgwa/structures/FETCH_STRUCTURE_SUCCESS';
const FETCH_STRUCTURE_FAILURE = 'fgwa/structures/FETCH_STRUCTURE_FAILURE';
const SET_STRUCTURE_REQUEST = 'fgwa/structures/SET_STRUCTURE_REQUEST';
const SET_STRUCTURE_SUCCESS = 'fgwa/structures/SET_STRUCTURE_SUCCESS';
const SET_STRUCTURE_FAILURE = 'fgwa/structures/SET_STRUCTURE_FAILURE';
const DELETE_STRUCTURE_REQUEST = 'fgwa/structures/DELETE_STRUCTURE_REQUEST';
const DELETE_STRUCTURE_SUCCESS = 'fgwa/structures/DELETE_STRUCTURE_SUCCESS';
const DELETE_STRUCTURE_FAILURE = 'fgwa/structures/DELETE_STRUCTURE_FAILURE';

export const structuresActionsHandlers = {
  [FETCH_ALL_STRUCTURES_REQUEST]: state => flow(
    set('loaded.structures', false),
    set('loading.structures', true),
  )(state),
  [FETCH_ALL_STRUCTURES_SUCCESS]: (state, action) => flow(
    set('entities.structures', action.response.entities.structures || {}),
    set('loaded.structures', true),
    set('loading.structures', false),
  )(state),
  [FETCH_ALL_STRUCTURES_FAILURE]: state => flow(
    set('loaded.structures', false),
    set('loading.structures', false),
  )(state),
  [FETCH_STRUCTURE_SUCCESS]: (state, action) => flow(
    set(`entities.structures.${action.id}`, action.response.entities.structures),
  )(state),
  [SET_STRUCTURE_SUCCESS]: (state, action) => flow(
    set('entities.structures', {
      ...state.entities.structures,
      ...action.response.entities.structures,
    }),
  )(state),
  [DELETE_STRUCTURE_SUCCESS]: (state, action) => flow(
    set('entities.structures', omit(state.entities.structures, action.id)),
  )(state),
};

export function loadStructure (id) {
  return {
    id,
    [CALL_API]: {
      types: [
        FETCH_STRUCTURE_REQUEST,
        FETCH_STRUCTURE_SUCCESS,
        FETCH_STRUCTURE_FAILURE,
      ],
      method: 'GET',
      endpoint: `/structures/${id}`,
      schema: Schemas.STRUCTURE,
    },
  };
}

export function loadStructures () {
  return {
    [CALL_API]: {
      types: [
        FETCH_ALL_STRUCTURES_REQUEST,
        FETCH_ALL_STRUCTURES_SUCCESS,
        FETCH_ALL_STRUCTURES_FAILURE,
      ],
      method: 'GET',
      endpoint: '/structures',
      schema: Schemas.STRUCTURE_ARRAY,
    },
  };
}

export function createOrUpdateStructure (structure) {
  return {
    [CALL_API]: {
      types: [
        SET_STRUCTURE_REQUEST,
        SET_STRUCTURE_SUCCESS,
        SET_STRUCTURE_FAILURE,
      ],
      method: !structure || !Number.isInteger(structure.structure_id) ? 'POST' : 'PUT',
      endpoint: !structure || !Number.isInteger(structure.structure_id) ? '/structures' : `/structures/${structure.structure_id}`,
      schema: Schemas.STRUCTURE,
      body: structure,
      successMessage: 'Structure enregistré avec succès',
    },
  };
}

export function deleteStructure (id) {
  return {
    id,
    [CALL_API]: {
      types: [
        DELETE_STRUCTURE_REQUEST,
        DELETE_STRUCTURE_SUCCESS,
        DELETE_STRUCTURE_FAILURE,
      ],
      method: 'DELETE',
      endpoint: `/structures/${id}`,
      successMessage: 'Structure supprimé avec succès',
    },
  };
}
