import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { ButtonConfirmModal } from 'react-components-linaia';

const StructureDetailView = ({
  structure = {}, baseUrl, onClickDelete,
}) => (
  <div>
    <div>
      Structure Id:&nbsp;
      {structure.structure_id}
    </div>
    <div>
      Structure Name:&nbsp;
      {structure.structureName}
    </div>
    <div>
      Structure Phone:&nbsp;
      {structure.structurePhone}
    </div>
    <div>
      Structure Email:&nbsp;
      {structure.structureEmail}
    </div>

    <div className="btn-group right">
      <NavLink className="btn" to={`${baseUrl}/${structure.structure_id}/edit`}>
        Modifier
      </NavLink>
      {onClickDelete && (
        <ButtonConfirmModal
          onConfirmationButton={() => onClickDelete()}
          message="Voulez-vous vraiment supprimer cette structure ?"
        >
          <button className="btn" type="button">Supprimer</button>
        </ButtonConfirmModal>
      )}
    </div>
  </div>
);

StructureDetailView.propTypes = {
  structure: PropTypes.shape({
    structure_id: PropTypes.number.isRequired,
    structureName: PropTypes.string.isRequired,
    structurePhone: PropTypes.string.isRequired,
    structureEmail: PropTypes.string.isRequired,
  }).isRequired,

  baseUrl: PropTypes.string.isRequired,
  onClickDelete: PropTypes.func,
};

export default (StructureDetailView);
