import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { get } from "lodash";
import { NavLink } from "react-router-dom";
import { ButtonConfirmModal } from "react-components-linaia";

import { UPLOADS_URL } from "../../constants/Config";

const BillDetailView = ({ bill = {}, baseUrl, onClickDelete, clients }) => (
  <div>
    <div>
      Bill numero:&nbsp;
      {bill.factureNumero}
    </div>
    <div>
      Bill Date:&nbsp;
      {bill.factureDate}
    </div>
    <div>
      Bill libelle:&nbsp;
      {bill.factureLibelle}
    </div>
    <div>
      Bill HT:&nbsp;
      {bill.factureHT}
    </div>
    <div>
      Bill TTC:&nbsp;
      {bill.factureTTC}
    </div>

    <div className="btn-group right">
      <NavLink className="btn" to={`${baseUrl}/${bill.bill_id}/edit`}>
        Modifier
      </NavLink>
      {onClickDelete && (
        <ButtonConfirmModal
          onConfirmationButton={() => onClickDelete()}
          message="Voulez-vous vraiment supprimer cette facture ?"
        >
          <button className="btn" type="button">
            Supprimer
          </button>
        </ButtonConfirmModal>
      )}
    </div>
  </div>
);

BillDetailView.propTypes = {
  bill: PropTypes.shape({
    bill_id: PropTypes.number.isRequired,
    factureHT: PropTypes.number.isRequired,
    factureTTC: PropTypes.number.isRequired,
    factureLibelle: PropTypes.string.isRequired,
    factureNumero: PropTypes.number.isRequired,
    factureClient: PropTypes.number.isRequired,
    factureDate: PropTypes.number.isRequired
  }).isRequired,

  clients: PropTypes.objectOf(PropTypes.shape()).isRequired,

  baseUrl: PropTypes.string.isRequired,
  onClickDelete: PropTypes.func
};

export default connect(state => ({
  clients: state.data.entities.clients
}))(BillDetailView);
