import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { NavLink } from 'react-router-dom';
import { ButtonConfirmModal } from 'react-components-linaia';

import createSelector from '../../selectors/ClientSubEntitiesSelector';

const clientSubEntitiesSelector = createSelector();

const EquipmentDetailView = ({
  equipment = {}, baseUrl, onClickDelete, contracts,
}) => (
  <div>
    <div>
      Equipment Id:&nbsp;
      {equipment.equipment_id}
    </div>
    <div>
      Equipment Name:&nbsp;
      {equipment.equipmentName}
    </div>
    <div>
      Equipment Location:&nbsp;
      {equipment.equipmentLocation}
    </div>
    <div>
      Equipment Post Number:&nbsp;
      {equipment.equipmentPostNumber}
    </div>
    <div>
      Equipment Type:&nbsp;
      {equipment.equipmentType}
    </div>
    <div>
      Equipment Bait Type:&nbsp;
      {equipment.equipmentBaitType}
    </div>
    <div>
      Equipment Is Toxic:&nbsp;
      {equipment.equipmentIsToxic}
    </div>
    <div>
      Equipment Used Product:&nbsp;
      {equipment.equipmentUsedProduct}
    </div>
    <div>
      Equipment Contract:&nbsp;
      {get(contracts, `${equipment.equipmentContract}.contractLabel`)}
    </div>

    <div className="btn-group right">
      <NavLink className="btn" to={`${baseUrl}/${equipment.equipment_id}/edit`}>
        Modifier
      </NavLink>
      {onClickDelete && (
        <ButtonConfirmModal
          onConfirmationButton={() => onClickDelete()}
          message="Voulez-vous vraiment supprimer cet équipement ?"
        >
          <button className="btn" type="button">Supprimer</button>
        </ButtonConfirmModal>
      )}
    </div>
  </div>
);

EquipmentDetailView.propTypes = {
  equipment: PropTypes.shape({
    equipment_id: PropTypes.number.isRequired,
    equipmentName: PropTypes.string.isRequired,
    equipmentLocation: PropTypes.string.isRequired,
    equipmentPostNumber: PropTypes.string.isRequired,
    equipmentType: PropTypes.string.isRequired,
    equipmentBaitType: PropTypes.string.isRequired,
    equipmentIsToxic: PropTypes.bool.isRequired,
    equipmentUsedProduct: PropTypes.string.isRequired,
    equipmentContract: PropTypes.number.isRequired,
  }).isRequired,

  contracts: PropTypes.objectOf(PropTypes.shape()).isRequired,

  baseUrl: PropTypes.string.isRequired,
  onClickDelete: PropTypes.func,
};

export default connect(
  (state, { clientId }) => ({
    contracts: clientSubEntitiesSelector(state.data.entities.contracts, clientId, 'contractClient'),
  }),
)(EquipmentDetailView);
