import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { get, map } from 'lodash';
import { ButtonConfirmModal } from 'react-components-linaia';

import createSelector from '../../selectors/ClientSubEntitiesSelector';

import CardListView from '../shared/CardListView';

const clientSubEntitiesSelector = createSelector();

const ContactsCardListView = ({
  contacts, baseUrl, onClickDelete, accounts, sites, categorys,
}) => (
  <CardListView
    className="card-list-contacts"
    data={contacts}
    cardComponent={contact => (
      <div className="col-3_xs-12_sm-6_md-4">
        <div className="box card simple-card">
          <div className="box-content">
            <div className="grid-noBottom">
              <div className="col-12">
                <div className="card-title">
                  {contact.contactFirstname} {contact.contactLastname}
                </div>
              </div>
              {contact.contactPhone && (
                <div className="col-12">
                  <span className="iconed-data">
                    <i className="fas fa-phone" />
                    <span>
                      {contact.contactPhone}
                    </span>
                  </span>
                </div>
              )}

              <div className="col-12">
                <span className="iconed-data">
                  <i className="fas fa-envelope" />
                  <span>
                    {get(accounts, `${contact.contactAccount}.email`)}
                  </span>
                </span>
              </div>
              <div className="col-12">
                <span className="iconed-data">
                  <i className="fas fa-warehouse" />
                  <span>
                    {map(contact.contactSites, contactSite => (get(sites, `${contactSite}.siteName`)) + ', ')}
                  </span>
                </span>
              </div>
              <div className="col-12">
                <span className="iconed-data">
                  <i className="fas fa-file-alt" />
                  <span>
                    {map(contact.contactCategories, contactcategorie => (get(categorys, `${contactcategorie}.categoryName`)) + ', ')}
                  </span>
                </span>
              </div>
              {contact.contactBillingAccess && (
                <div className="col-12">
                  <span className="iconed-data">
                    <i className="fas fa-file-invoice" />
                    <span>
                      Accès aux factures
                    </span>
                  </span>
                </div>
              )}
            </div>
            <div className="card-actions btn-group">
              <NavLink to={`${baseUrl}/${contact.contact_id}/edit`} className="btn">
                <i className="far fa-pencil" />
                <span>Modifier</span>
              </NavLink>
              {onClickDelete && (
                <ButtonConfirmModal
                  onConfirmationButton={() => onClickDelete(contact.contact_id)}
                  message="Voulez-vous vraiment supprimer ce contact ?"
                >
                  <button className="btn" type="button">
                    <i className="far fa-trash" />
                    <span>Supprimer</span>
                  </button>
                </ButtonConfirmModal>
              )}
            </div>
          </div>
        </div>
      </div>
    )}
    search={['contactFirstname', 'contactLastname']}
    searchLabel="Rechercher un contact"
    sortByDefault="contactLastname"
    buttons={(
      <div>
        <NavLink className="btn btn-main-mobile" to={`${baseUrl}/new`}>
          <span>Ajouter un contact</span>
          <i className="far fa-plus" />
        </NavLink>
      </div>
    )}
  />
);

ContactsCardListView.propTypes = {
  contacts: PropTypes.objectOf(PropTypes.shape({
    contact_id: PropTypes.number.isRequired,
    contactClient: PropTypes.number.isRequired,
    contactPhone: PropTypes.string.isRequired,
    contactAccount: PropTypes.number.isRequired,
    contactFirstname: PropTypes.string.isRequired,
    contactLastname: PropTypes.string.isRequired,
    contactSites: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
    contactCategories: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
    contactBillingAccess: PropTypes.bool.isRequired,
  })).isRequired,

  accounts: PropTypes.objectOf(PropTypes.shape()).isRequired,
  sites: PropTypes.objectOf(PropTypes.shape()).isRequired,
  categorys: PropTypes.objectOf(PropTypes.shape()).isRequired,

  baseUrl: PropTypes.string.isRequired,
  onClickDelete: PropTypes.func,
};

export default connect(
  (state, { clientId }) => ({
    accounts: state.data.entities.accounts,
    sites: clientSubEntitiesSelector(state.data.entities.sites, clientId, 'siteClient'),
    categorys: state.data.entities.categorys,
  }),
)(ContactsCardListView);
