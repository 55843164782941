import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { NavLink } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';
import { map, isNumber } from 'lodash';
import {
  Field,
  CleaveField,
  FileField,
  InputField,
  SelectField,
} from 'react-components-linaia';

import { uploadFiles, removeFile } from '../../store/modules/uploads';

import { UPLOADS_URL } from '../../constants/Config';

const SiteEditForm = ({
  handleSubmit,
  baseUrl,
  initialValues,
  upFiles,
  rmFile,
  technicians,
}) => (
  <Fragment>
    <div className="modal-title">
      <div className="modal-title-content">
        {initialValues.site_id >= 0 ? 'Modifier le site' : 'Ajouter un site'}
        <NavLink className="btn" to={baseUrl}>
          <i className="fal fa-times" />
        </NavLink>
      </div>
    </div>
    <form onSubmit={handleSubmit}>
      <div className="modal-body">
        <div className="grid">
          <div className="col-6_sm-12">
            <div className="grid">
              <Field
                name="sitePhotoUrl"
                component={FileField}
                uploadFiles={upFiles}
                removeFile={rmFile}
                buttonLabel="Importer une photo"
                getUrl={item => `${UPLOADS_URL}/${item}`}
                className="col-12"
              />
              <Field
                name="siteName"
                component={InputField}
                disabled={initialValues.siteIdExterne}
                label="Nom du site"
                className="col-12"
              />
              <Field
                name="sitePhone1"
                component={CleaveField}
                disabled={initialValues.siteIdExterne}
                type="tel"
                label="Téléphone 1"
                className="col-tel"
                cleaveOptions={{ phone: true, phoneRegionCode: 'FR' }}
              />
              <Field
                name="sitePhone2"
                component={CleaveField}
                disabled={initialValues.siteIdExterne}
                type="tel"
                label="Téléphone 2"
                className="col-tel"
                cleaveOptions={{ phone: true, phoneRegionCode: 'FR' }}
              />
              <Field
                name="siteEmail"
                component={InputField}
                disabled={initialValues.siteIdExterne}
                type="email"
                label="Email"
                className="col-12"
              />
            </div>
          </div>
          <div className="col-6_sm-12">
            <div className="grid">
              <Field
                name="siteAddress1"
                component={InputField}
                disabled={initialValues.siteIdExterne}
                placeholder="Adresse"
                label="Adresse"
                className="col-12"
              />
              <Field
                name="siteAddress2"
                component={InputField}
                disabled={initialValues.siteIdExterne}
                placeholder="Complément d'adresse"
                className="col-12"
              />
              <Field
                name="siteAddress3"
                component={InputField}
                disabled={initialValues.siteIdExterne}
                placeholder="Complément d'adresse 2"
                className="col-12"
              />
              <Field
                name="sitePostalCode"
                component={InputField}
                disabled={initialValues.siteIdExterne}
                label="Code Postal"
                className="col-cp"
              />
              <Field
                name="siteCity"
                component={InputField}
                disabled={initialValues.siteIdExterne}
                label="Ville"
                className="col"
              />
              <Field
                name="siteTechnician"
                component={SelectField}
                disabled={initialValues.siteIdExterne}
                options={map(technicians, tech => ({
                  value: tech.technician_id,
                  label: `${tech.technicianFirstname} ${tech.technicianLastname}`,
                }))}
                label="Technicien"
                className="col-12"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <div className="btn-group center">
          <button className="btn btn-primary" type="submit">
            Enregistrer
          </button>
        </div>
      </div>
    </form>
  </Fragment>
);

SiteEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
  initialValues: PropTypes.shape().isRequired,

  technicians: PropTypes.objectOf(PropTypes.shape()).isRequired,

  upFiles: PropTypes.func.isRequired,
  rmFile: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'SiteEditForm',
  validate: (values = {}) => {
    const errors = {};

    if (!values.siteName)
      errors.siteName = 'Merci de renseigner le nom du site';
    if (!values.sitePostalCode)
      errors.sitePostalCode = 'Merci de renseigner un code postal';
    if (!values.siteCity) errors.siteCity = 'Merci de renseigner la ville';
    if (!isNumber(values.siteTechnician))
      errors.siteTechnician = 'Merci de sélectionner un technicien';

    return errors;
  },
})(
  connect(
    state => ({
      technicians: state.data.entities.technicians,
    }),
    { upFiles: uploadFiles, rmFile: removeFile },
  )(SiteEditForm),
);
