import { flow, set } from 'lodash/fp';
import { omit } from 'lodash';

import { CALL_API, Schemas } from '../Schemas';

const FETCH_ALL_BILLS_REQUEST = 'fgwa/bills/FETCH_ALL_BILLS_REQUEST';
export const FETCH_ALL_BILLS_SUCCESS = 'fgwa/bills/FETCH_ALL_BILLS_SUCCESS';
const FETCH_ALL_BILLS_FAILURE = 'fgwa/bills/FETCH_ALL_BILLS_FAILURE';
const FETCH_BILL_REQUEST = 'fgwa/bills/FETCH_BILL_REQUEST';
const FETCH_BILL_SUCCESS = 'fgwa/bills/FETCH_BILL_SUCCESS';
const FETCH_BILL_FAILURE = 'fgwa/bills/FETCH_BILL_FAILURE';
const SET_BILL_REQUEST = 'fgwa/bills/SET_BILL_REQUEST';
const SET_BILL_SUCCESS = 'fgwa/bills/SET_BILL_SUCCESS';
const SET_BILL_FAILURE = 'fgwa/bills/SET_BILL_FAILURE';
const DELETE_BILL_REQUEST = 'fgwa/bills/DELETE_BILL_REQUEST';
const DELETE_BILL_SUCCESS = 'fgwa/bills/DELETE_BILL_SUCCESS';
const DELETE_BILL_FAILURE = 'fgwa/bills/DELETE_BILL_FAILURE';

export const billsActionsHandlers = {
  [FETCH_ALL_BILLS_REQUEST]: state => flow(
    set('loaded.bills', false),
    set('loading.bills', true),
  )(state),
  [FETCH_ALL_BILLS_SUCCESS]: (state, action) => flow(
    set('entities.bills', action.response.entities.bills || {}),
    set('loaded.bills', true),
    set('loading.bills', false),
  )(state),
  [FETCH_ALL_BILLS_FAILURE]: state => flow(
    set('loaded.bills', false),
    set('loading.bills', false),
  )(state),
  [FETCH_BILL_SUCCESS]: (state, action) => flow(
    set(`entities.bills.${action.id}`, action.response.entities.bills),
  )(state),
  [SET_BILL_SUCCESS]: (state, action) => flow(
    set('entities.bills', {
      ...state.entities.bills,
      ...action.response.entities.bills,
    }),
  )(state),
  [DELETE_BILL_SUCCESS]: (state, action) => flow(
    set('entities.bills', omit(state.entities.bills, action.id)),
  )(state),
};

export function loadBill (id) {
  return {
    id,
    [CALL_API]: {
      types: [
        FETCH_BILL_REQUEST,
        FETCH_BILL_SUCCESS,
        FETCH_BILL_FAILURE,
      ],
      method: 'GET',
      endpoint: `/bills/${id}`,
      schema: Schemas.BILL,
    },
  };
}

export function loadBills () {
  return {
    [CALL_API]: {
      types: [
        FETCH_ALL_BILLS_REQUEST,
        FETCH_ALL_BILLS_SUCCESS,
        FETCH_ALL_BILLS_FAILURE,
      ],
      method: 'GET',
      endpoint: '/bills',
      schema: Schemas.BILL_ARRAY,
    },
  };
}

export function createOrUpdateBill (bill) {
  return {
    [CALL_API]: {
      types: [
        SET_BILL_REQUEST,
        SET_BILL_SUCCESS,
        SET_BILL_FAILURE,
      ],
      method: !bill || !Number.isInteger(bill.bill_id) ? 'POST' : 'PUT',
      endpoint: !bill || !Number.isInteger(bill.bill_id) ? '/bills' : `/bills/${bill.bill_id}`,
      schema: Schemas.BILL,
      body: bill,
      successMessage: 'Facture enregistrée avec succès',
    },
  };
}

export function deleteBill (id) {
  return {
    id,
    [CALL_API]: {
      types: [
        DELETE_BILL_REQUEST,
        DELETE_BILL_SUCCESS,
        DELETE_BILL_FAILURE,
      ],
      method: 'DELETE',
      endpoint: `/bills/${id}`,
      successMessage: 'Facture supprimée avec succès',
    },
  };
}
