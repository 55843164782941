import { flow, set } from "lodash/fp";
import { omit } from "lodash";

import { CALL_API, Schemas } from "../Schemas";

const FETCH_ALL_PASSAGES_REQUEST = "fgwa/passages/FETCH_ALL_PASSAGES_REQUEST";
export const FETCH_ALL_PASSAGES_SUCCESS = "fgwa/passages/FETCH_ALL_PASSAGES_SUCCESS";
const FETCH_ALL_PASSAGES_FAILURE = "fgwa/passages/FETCH_ALL_PASSAGES_FAILURE";
const FETCH_PASSAGE_REQUEST = "fgwa/passages/FETCH_PASSAGE_REQUEST";
const FETCH_PASSAGE_SUCCESS = "fgwa/passages/FETCH_PASSAGE_SUCCESS";
const FETCH_PASSAGE_FAILURE = "fgwa/passages/FETCH_PASSAGE_FAILURE";
const SET_PASSAGE_REQUEST = "fgwa/passages/SET_PASSAGE_REQUEST";
const SET_PASSAGE_SUCCESS = "fgwa/passages/SET_PASSAGE_SUCCESS";
const SET_PASSAGE_FAILURE = "fgwa/passages/SET_PASSAGE_FAILURE";
const REFRESH_PASSAGE_REQUEST = "fgwa/passages/REFRESH_PASSAGE_REQUEST";
const REFRESH_PASSAGE_SUCCESS = "fgwa/passages/REFRESH_PASSAGE_SUCCESS";
const REFRESH_PASSAGE_FAILURE = "fgwa/passages/REFRESH_PASSAGE_FAILURE";
const DELETE_PASSAGE_REQUEST = "fgwa/passages/DELETE_PASSAGE_REQUEST";
const DELETE_PASSAGE_SUCCESS = "fgwa/passages/DELETE_PASSAGE_SUCCESS";
const DELETE_PASSAGE_FAILURE = "fgwa/passages/DELETE_PASSAGE_FAILURE";

export const passagesActionsHandlers = {
  [FETCH_ALL_PASSAGES_REQUEST]: (state) =>
    flow(set("loaded.passages", false), set("loading.passages", true))(state),
  [FETCH_ALL_PASSAGES_SUCCESS]: (state, action) =>
    flow(
      set("entities.passages", action.response.entities.passages || {}),
      set("loaded.passages", true),
      set("loading.passages", false)
    )(state),
  [FETCH_ALL_PASSAGES_FAILURE]: (state) =>
    flow(set("loaded.passages", false), set("loading.passages", false))(state),
  [FETCH_PASSAGE_SUCCESS]: (state, action) =>
    flow(set(`entities.passages.${action.id}`, action.response.entities.passages))(state),
  [SET_PASSAGE_SUCCESS]: (state, action) =>
    flow(
      set("entities.passages", {
        ...state.entities.passages,
        ...action.response.entities.passages,
      })
    )(state),
  [REFRESH_PASSAGE_SUCCESS]: (state, action) =>
    flow(
      set("entities.passages", {
        ...state.entities.passages,
        ...action.response.entities.passages,
      })
    )(state),
  [DELETE_PASSAGE_SUCCESS]: (state, action) =>
    flow(set("entities.passages", omit(state.entities.passages, action.id)))(state),
};

export function loadPassage(id) {
  return {
    id,
    [CALL_API]: {
      types: [FETCH_PASSAGE_REQUEST, FETCH_PASSAGE_SUCCESS, FETCH_PASSAGE_FAILURE],
      method: "GET",
      endpoint: `/passages/${id}`,
      schema: Schemas.PASSAGE,
    },
  };
}

export function loadPassages() {
  return {
    [CALL_API]: {
      types: [FETCH_ALL_PASSAGES_REQUEST, FETCH_ALL_PASSAGES_SUCCESS, FETCH_ALL_PASSAGES_FAILURE],
      method: "GET",
      endpoint: "/passages",
      schema: Schemas.PASSAGE_ARRAY,
    },
  };
}

export function createOrUpdatePassage(passage) {
  return {
    [CALL_API]: {
      types: [SET_PASSAGE_REQUEST, SET_PASSAGE_SUCCESS, SET_PASSAGE_FAILURE],
      method: !passage || !Number.isInteger(passage.passage_id) ? "POST" : "PUT",
      endpoint:
        !passage || !Number.isInteger(passage.passage_id)
          ? "/passages"
          : `/passages/${passage.passage_id}`,
      schema: Schemas.PASSAGE,
      body: passage,
      successMessage: "Passage enregistré avec succès",
    },
  };
}

export function refreshPassages(clientId) {
  console.log("value of clientId : ", clientId);
  return {
    [CALL_API]: {
      types: [REFRESH_PASSAGE_REQUEST, REFRESH_PASSAGE_SUCCESS, REFRESH_PASSAGE_FAILURE],
      method: "POST",
      endpoint: "/passages/refresh",
      body: {
        clientId,
      },
      successMessage: "Les passages refresh avec succès",
      successNext: (res) => loadPassasges(),
    },
  };
}

export function deletePassage(id) {
  return {
    id,
    [CALL_API]: {
      types: [DELETE_PASSAGE_REQUEST, DELETE_PASSAGE_SUCCESS, DELETE_PASSAGE_FAILURE],
      method: "DELETE",
      endpoint: `/passages/${id}`,
      successMessage: "Passage supprimé avec succès",
    },
  };
}
