import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { NavLink } from 'react-router-dom';
import { ButtonConfirmModal } from 'react-components-linaia';

import { UPLOADS_URL } from '../../constants/Config';

const TechnicianDetailView = ({
  technician = {}, baseUrl, onClickDelete, structures,
}) => (
  <div>
    <div>
      Technician Id:&nbsp;
      {technician.technician_id}
    </div>
    <div>
      Technician Firstname:&nbsp;
      {technician.technicianFirstname}
    </div>
    <div>
      Technician Lastname:&nbsp;
      {technician.technicianLastname}
    </div>
    <div>
      Technician Email:&nbsp;
      {technician.technicianEmail}
    </div>
    <div>
      Technician Phone:&nbsp;
      {technician.technicianPhone}
    </div>
    <div>
      Technician Photo Url:&nbsp;
      <img src={`${UPLOADS_URL}/${technician.technicianPhotoUrl}`} alt={technician.technicianPhotoUrl} />
    </div>
    <div>
      Technician Structure:&nbsp;
      {get(structures, `${technician.technicianStructure}.structureName`)}
    </div>

    <div className="btn-group right">
      <NavLink className="btn" to={`${baseUrl}/${technician.technician_id}/edit`}>
        Modifier
      </NavLink>
      {onClickDelete && (
        <ButtonConfirmModal
          onConfirmationButton={() => onClickDelete()}
          message="Voulez-vous vraiment supprimer ce technicien ?"
        >
          <button className="btn" type="button">Supprimer</button>
        </ButtonConfirmModal>
      )}
    </div>
  </div>
);

TechnicianDetailView.propTypes = {
  technician: PropTypes.shape({
    technician_id: PropTypes.number.isRequired,
    technicianFirstname: PropTypes.string.isRequired,
    technicianLastname: PropTypes.string.isRequired,
    technicianEmail: PropTypes.string.isRequired,
    technicianPhone: PropTypes.string.isRequired,
    technicianPhotoUrl: PropTypes.string,
    technicianStructure: PropTypes.number.isRequired,
  }).isRequired,

  structures: PropTypes.objectOf(PropTypes.shape()).isRequired,

  baseUrl: PropTypes.string.isRequired,
  onClickDelete: PropTypes.func,
};

export default connect(
  state => ({
    structures: state.data.entities.structures,
  }),
)(TechnicianDetailView);
