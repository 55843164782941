import { flow, set } from "lodash/fp";
import { omit } from "lodash";

import { CALL_API, Schemas } from "../Schemas";

const FETCH_ALL_CONTRACTS_REQUEST =
  "fgwa/contracts/FETCH_ALL_CONTRACTS_REQUEST";
export const FETCH_ALL_CONTRACTS_SUCCESS =
  "fgwa/contracts/FETCH_ALL_CONTRACTS_SUCCESS";
const FETCH_ALL_CONTRACTS_FAILURE =
  "fgwa/contracts/FETCH_ALL_CONTRACTS_FAILURE";
const FETCH_CONTRACT_REQUEST = "fgwa/contracts/FETCH_CONTRACT_REQUEST";
const FETCH_CONTRACT_SUCCESS = "fgwa/contracts/FETCH_CONTRACT_SUCCESS";
const FETCH_CONTRACT_FAILURE = "fgwa/contracts/FETCH_CONTRACT_FAILURE";
const SET_CONTRACT_REQUEST = "fgwa/contracts/SET_CONTRACT_REQUEST";
const SET_CONTRACT_SUCCESS = "fgwa/contracts/SET_CONTRACT_SUCCESS";
const SET_CONTRACT_FAILURE = "fgwa/contracts/SET_CONTRACT_FAILURE";
const DELETE_CONTRACT_REQUEST = "fgwa/contracts/DELETE_CONTRACT_REQUEST";
const DELETE_CONTRACT_SUCCESS = "fgwa/contracts/DELETE_CONTRACT_SUCCESS";
const DELETE_CONTRACT_FAILURE = "fgwa/contracts/DELETE_CONTRACT_FAILURE";

export const contractsActionsHandlers = {
  [FETCH_ALL_CONTRACTS_REQUEST]: state =>
    flow(
      set("loaded.contracts", false),
      set("loading.contracts", true)
    )(state),
  [FETCH_ALL_CONTRACTS_SUCCESS]: (state, action) =>
    flow(
      set("entities.contracts", action.response.entities.contracts || {}),
      set("loaded.contracts", true),
      set("loading.contracts", false)
    )(state),
  [FETCH_ALL_CONTRACTS_FAILURE]: state =>
    flow(
      set("loaded.contracts", false),
      set("loading.contracts", false)
    )(state),
  [FETCH_CONTRACT_SUCCESS]: (state, action) =>
    flow(
      set(`entities.contracts.${action.id}`, action.response.entities.contracts)
    )(state),
  [SET_CONTRACT_SUCCESS]: (state, action) =>
    flow(
      set("entities.contracts", {
        ...state.entities.contracts,
        ...action.response.entities.contracts
      })
    )(state),
  [DELETE_CONTRACT_SUCCESS]: (state, action) =>
    flow(set("entities.contracts", omit(state.entities.contracts, action.id)))(
      state
    )
};

export function loadContract(id) {
  return {
    id,
    [CALL_API]: {
      types: [
        FETCH_CONTRACT_REQUEST,
        FETCH_CONTRACT_SUCCESS,
        FETCH_CONTRACT_FAILURE
      ],
      method: "GET",
      endpoint: `/contracts/${id}`,
      schema: Schemas.CONTRACT
    }
  };
}

export function loadContracts() {
  return {
    [CALL_API]: {
      types: [
        FETCH_ALL_CONTRACTS_REQUEST,
        FETCH_ALL_CONTRACTS_SUCCESS,
        FETCH_ALL_CONTRACTS_FAILURE
      ],
      method: "GET",
      endpoint: "/contracts",
      schema: Schemas.CONTRACT_ARRAY
    }
  };
}

export function createOrUpdateContract(contract) {
  return {
    [CALL_API]: {
      types: [SET_CONTRACT_REQUEST, SET_CONTRACT_SUCCESS, SET_CONTRACT_FAILURE],
      method:
        !contract || !Number.isInteger(contract.contract_id) ? "POST" : "PUT",
      endpoint:
        !contract || !Number.isInteger(contract.contract_id)
          ? "/contracts"
          : `/contracts/${contract.contract_id}`,
      schema: Schemas.CONTRACT,
      body: contract,
      successMessage: "Contrat enregistré avec succès"
    }
  };
}

export function deleteContract(id) {
  return {
    id,
    [CALL_API]: {
      types: [
        DELETE_CONTRACT_REQUEST,
        DELETE_CONTRACT_SUCCESS,
        DELETE_CONTRACT_FAILURE
      ],
      method: "DELETE",
      endpoint: `/contracts/${id}`,
      successMessage: "Contrat supprimé avec succès"
    }
  };
}
