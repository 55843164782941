import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { ButtonConfirmModal } from 'react-components-linaia';

import { UPLOADS_URL } from '../../constants/Config';

const ActualityDetailView = ({
  actuality = {}, baseUrl, onClickDelete,
}) => (
  <div>
    <div>
      Actuality Id:&nbsp;
      {actuality.actuality_id}
    </div>
    <div>
      Actuality Start Date:&nbsp;
      {actuality.actualityStartDate}
    </div>
    <div>
      Actuality End Date:&nbsp;
      {actuality.actualityEndDate}
    </div>
    <div>
      Actuality Title:&nbsp;
      {actuality.actualityTitle}
    </div>
    <div>
      Actuality Summary:&nbsp;
      {actuality.actualitySummary}
    </div>
    <div>
      Actuality Content:&nbsp;
      {actuality.actualityContent}
    </div>
    <div>
      Actuality Thumbnail:&nbsp;
      <img src={`${UPLOADS_URL}/${actuality.actualityThumbnail}`} alt={actuality.actualityThumbnail} />
    </div>
    <div>
      Actuality Files:&nbsp;
      <a href={`${UPLOADS_URL}/${actuality.actualityFiles}`} target="_blank" rel="noopener noreferrer">{actuality.actualityFiles}</a>
    </div>
    <div>
      Actuality Links:&nbsp;
      {actuality.actualityLinks}
    </div>

    <div className="btn-group right">
      <NavLink className="btn" to={`${baseUrl}/${actuality.actuality_id}/edit`}>
        Modifier
      </NavLink>
      {onClickDelete && (
        <ButtonConfirmModal
          onConfirmationButton={() => onClickDelete()}
          message="Voulez-vous vraiment supprimer cette actualité ?"
        >
          <button className="btn" type="button">Supprimer</button>
        </ButtonConfirmModal>
      )}
    </div>
  </div>
);

ActualityDetailView.propTypes = {
  actuality: PropTypes.shape({
    actuality_id: PropTypes.number.isRequired,
    actualityStartDate: PropTypes.string.isRequired,
    actualityEndDate: PropTypes.string,
    actualityTitle: PropTypes.string.isRequired,
    actualitySummary: PropTypes.string.isRequired,
    actualityContent: PropTypes.string.isRequired,
    actualityThumbnail: PropTypes.string,
    actualityFiles: PropTypes.arrayOf(PropTypes.string),
    actualityLinks: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,

  baseUrl: PropTypes.string.isRequired,
  onClickDelete: PropTypes.func,
};

export default (ActualityDetailView);
