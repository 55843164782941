import { flow, set } from "lodash/fp";
import { omit } from "lodash";

import { loadDocuments } from "./documents";
import { CALL_API, Schemas } from "../Schemas";

const FETCH_ALL_SITES_REQUEST = "fgwa/sites/FETCH_ALL_SITES_REQUEST";
export const FETCH_ALL_SITES_SUCCESS = "fgwa/sites/FETCH_ALL_SITES_SUCCESS";
const FETCH_ALL_SITES_FAILURE = "fgwa/sites/FETCH_ALL_SITES_FAILURE";
const FETCH_SITE_REQUEST = "fgwa/sites/FETCH_SITE_REQUEST";
const FETCH_SITE_SUCCESS = "fgwa/sites/FETCH_SITE_SUCCESS";
const FETCH_SITE_FAILURE = "fgwa/sites/FETCH_SITE_FAILURE";
const SET_SITE_REQUEST = "fgwa/sites/SET_SITE_REQUEST";
const SET_SITE_SUCCESS = "fgwa/sites/SET_SITE_SUCCESS";
const SET_SITE_FAILURE = "fgwa/sites/SET_SITE_FAILURE";
const REFRESH_SITE_REQUEST = "fgwa/sites/REFRESH_SITE_REQUEST";
const REFRESH_SITE_SUCCESS = "fgwa/sites/REFRESH_SITE_SUCCESS";
const REFRESH_SITE_FAILURE = "fgwa/sites/REFRESH_SITE_FAILURE";
const DELETE_SITE_REQUEST = "fgwa/sites/DELETE_SITE_REQUEST";
const DELETE_SITE_SUCCESS = "fgwa/sites/DELETE_SITE_SUCCESS";
const DELETE_SITE_FAILURE = "fgwa/sites/DELETE_SITE_FAILURE";

export const sitesActionsHandlers = {
  [FETCH_ALL_SITES_REQUEST]: (state) =>
    flow(set("loaded.sites", false), set("loading.sites", true))(state),
  [FETCH_ALL_SITES_SUCCESS]: (state, action) =>
    flow(
      set("entities.sites", action.response.entities.sites || {}),
      set("loaded.sites", true),
      set("loading.sites", false)
    )(state),
  [FETCH_ALL_SITES_FAILURE]: (state) =>
    flow(set("loaded.sites", false), set("loading.sites", false))(state),
  [FETCH_SITE_SUCCESS]: (state, action) =>
    flow(set(`entities.sites.${action.id}`, action.response.entities.sites))(state),
  [SET_SITE_SUCCESS]: (state, action) =>
    flow(
      set("entities.sites", {
        ...state.entities.sites,
        ...action.response.entities.sites,
      })
    )(state),
  [REFRESH_SITE_SUCCESS]: (state, action) =>
    flow(
      set("entities.sites", {
        ...state.entities.sites,
        ...action.response.entities.sites,
      })
    )(state),
  [DELETE_SITE_SUCCESS]: (state, action) =>
    flow(set("entities.sites", omit(state.entities.sites, action.id)))(state),
};

export function loadSite(id) {
  return {
    id,
    [CALL_API]: {
      types: [FETCH_SITE_REQUEST, FETCH_SITE_SUCCESS, FETCH_SITE_FAILURE],
      method: "GET",
      endpoint: `/sites/${id}`,
      schema: Schemas.SITE,
    },
  };
}

export function loadSites() {
  return {
    [CALL_API]: {
      types: [FETCH_ALL_SITES_REQUEST, FETCH_ALL_SITES_SUCCESS, FETCH_ALL_SITES_FAILURE],
      method: "GET",
      endpoint: "/sites",
      schema: Schemas.SITE_ARRAY,
    },
  };
}

export function createOrUpdateSite(site) {
  return {
    [CALL_API]: {
      types: [SET_SITE_REQUEST, SET_SITE_SUCCESS, SET_SITE_FAILURE],
      method: !site || !Number.isInteger(site.site_id) ? "POST" : "PUT",
      endpoint: !site || !Number.isInteger(site.site_id) ? "/sites" : `/sites/${site.site_id}`,
      schema: Schemas.SITE,
      body: site,
      successMessage: "Site enregistré avec succès",
    },
  };
}
export function refreshSitesDocuments(clientId, sitesId) {
  console.log("value of document in refreshDocument : ", sitesId);
  return {
    [CALL_API]: {
      types: [REFRESH_SITE_REQUEST, REFRESH_SITE_SUCCESS, REFRESH_SITE_FAILURE],
      method: "POST",
      endpoint: "/sites/refresh",
      schema: Schemas.DOCUMENT,
      body: sitesId,
      successMessage: "Les documents des sites refresh avec succès",
      successNext: (res) => loadDocuments(),
    },
  };
}
export function deleteSite(id) {
  return {
    id,
    [CALL_API]: {
      types: [DELETE_SITE_REQUEST, DELETE_SITE_SUCCESS, DELETE_SITE_FAILURE],
      method: "DELETE",
      endpoint: `/sites/${id}`,
      successMessage: "Site supprimé avec succès",
    },
  };
}
