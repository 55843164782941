import { schema } from 'normalizr';

// We use this Normalizr schemas to transform API responses from a nested form
// to a flat form where repos and users are placed in `entities`, and nested
// JSON objects are replaced with their IDs. This is very convenient for
// consumption by reducers, because we can easily build a normalized tree
// and keep it updated as we fetch more data.

// Read more about Normalizr: https://github.com/gaearon/normalizr

const accountSchema = new schema.Entity('accounts', {}, { idAttribute: 'account_id' });

const actualitySchema = new schema.Entity('actualitys', {}, { idAttribute: 'actuality_id' });
const billSchema = new schema.Entity('bills', {}, { idAttribute: 'bill_id' });
const categorySchema = new schema.Entity('categorys', {}, { idAttribute: 'category_id' });
const clientSchema = new schema.Entity('clients', {}, { idAttribute: 'client_id' });
const contactSchema = new schema.Entity('contacts', {}, { idAttribute: 'contact_id' });
const contractSchema = new schema.Entity('contracts', {}, { idAttribute: 'contract_id' });
const documentSchema = new schema.Entity('documents', {}, { idAttribute: 'document_id' });
const equipmentSchema = new schema.Entity('equipments', {}, { idAttribute: 'equipment_id' });
const passageSchema = new schema.Entity('passages', {}, { idAttribute: 'passage_id' });
const sharedcategorySchema = new schema.Entity('sharedcategorys', {}, { idAttribute: 'sharedcategory_id' });
const shareddocumentSchema = new schema.Entity('shareddocuments', {}, { idAttribute: 'shareddocument_id' });
const siteSchema = new schema.Entity('sites', {}, { idAttribute: 'site_id' });
const structureSchema = new schema.Entity('structures', {}, { idAttribute: 'structure_id' });
const technicianSchema = new schema.Entity('technicians', {}, { idAttribute: 'technician_id' });

// Schemas for API responses.
export const Schemas = {
  ACCOUNT: accountSchema,
  ACCOUNT_ARRAY: new schema.Array(accountSchema),

  ACTUALITY: actualitySchema,
  ACTUALITY_ARRAY: new schema.Array(actualitySchema),
  BILL: billSchema,
  BILL_ARRAY: new schema.Array(billSchema),
  CATEGORY: categorySchema,
  CATEGORY_ARRAY: new schema.Array(categorySchema),
  CLIENT: clientSchema,
  CLIENT_ARRAY: new schema.Array(clientSchema),
  CONTACT: contactSchema,
  CONTACT_ARRAY: new schema.Array(contactSchema),
  CONTRACT: contractSchema,
  CONTRACT_ARRAY: new schema.Array(contractSchema),
  DOCUMENT: documentSchema,
  DOCUMENT_ARRAY: new schema.Array(documentSchema),
  EQUIPMENT: equipmentSchema,
  EQUIPMENT_ARRAY: new schema.Array(equipmentSchema),
  PASSAGE: passageSchema,
  PASSAGE_ARRAY: new schema.Array(passageSchema),
  SHAREDCATEGORY: sharedcategorySchema,
  SHAREDCATEGORY_ARRAY: new schema.Array(sharedcategorySchema),
  SHAREDDOCUMENT: shareddocumentSchema,
  SHAREDDOCUMENT_ARRAY: new schema.Array(shareddocumentSchema),
  SITE: siteSchema,
  SITE_ARRAY: new schema.Array(siteSchema),
  STRUCTURE: structureSchema,
  STRUCTURE_ARRAY: new schema.Array(structureSchema),
  TECHNICIAN: technicianSchema,
  TECHNICIAN_ARRAY: new schema.Array(technicianSchema),
};

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = Symbol('Call API');
