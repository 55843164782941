import React from 'react';

import BaseLayout from '../shared/BaseLayout';

import DynamicPlanEditor from '../../components/dynamicplan/DynamicPlanEditor';

const DynamicPlanContainer = () => (
  <BaseLayout title="Plan dynamiques">
    <DynamicPlanEditor />
  </BaseLayout>
);

DynamicPlanContainer.propTypes = {
};

export default (DynamicPlanContainer);
