import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { withRouter, Switch, Route } from 'react-router-dom';
import { get, find, parseInt } from 'lodash';

import { createOrUpdateActuality, deleteActuality } from '../../store/modules/actualitys';

import BaseLayout from '../shared/BaseLayout';

import ActualityDetailView from '../../components/actualitys/ActualityDetailView';
import ActualityEditForm from '../../components/actualitys/ActualityEditForm';
import ActualitysTableListView from '../../components/actualitys/ActualitysTableListView';

import modalDefaultClass from '../../utils/ModalDefaultClass';

const RenderEditDetailsModalsRouter = ({
  url, editActuality, delActuality, push, actualitys,
}) => (
  <Switch>
    <Route
      exact
      path={`${url}/new`}
      render={() => (
        <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)} className="modal-content full-modal">
          <ActualityEditForm
            initialValues={{}}
            onSubmit={(v) => {
              editActuality(v)
              .then(() => push(url));
            }}
            baseUrl={url}
          />
        </Modal>
      )}
    />
    <Route
      exact
      path={`${url}/:actualityId/edit`}
      render={(subProps) => {
        // On regarde si l'actuality_id existe dans les actualitées et on le passe dans la modal d'edit
        const initValue = find(actualitys, { actuality_id: parseInt(subProps.match.params.actualityId, 10) });
        return (initValue && (
          <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)} className="modal-content full-modal">
            <ActualityEditForm
              initialValues={initValue}
              onSubmit={(v) => {
                editActuality(v)
                .then(() => push(url));
              }}
              baseUrl={url}
            />
          </Modal>
        ));
      }}
    />
    <Route
      exact
      path={`${url}/:actualityId`}
      render={(subProps) => {
        const initValue = find(actualitys, { actuality_id: parseInt(subProps.match.params.actualityId, 10) });
        return (initValue && (
          <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
            <ActualityDetailView
              actuality={initValue}
              baseUrl={url}
              onClickDelete={() => {
                delActuality(subProps.match.params.actualityId)
                .then(() => push(url));
              }}
            />
          </Modal>
        ));
      }}
    />
  </Switch>
);

RenderEditDetailsModalsRouter.propTypes = {
  url: PropTypes.string.isRequired,
  editActuality: PropTypes.func.isRequired,
  delActuality: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  actualitys: PropTypes.objectOf(PropTypes.shape()).isRequired,
};

const ActualitysContainer = ({
  match: { url }, history: { push }, actualitys, ...props
}) => (
  <BaseLayout title="Actualités">
    <Route
      path={url}
      render={({ match }) => (
        <Fragment>
          <ActualitysTableListView
            actualitys={actualitys}
            baseUrl={match.url}
            onClickDelete={(actualityId) => {
              props.deleteActuality(actualityId)
              .then(() => push(match.url));
            }}
          />
          <RenderEditDetailsModalsRouter
            url={match.url}
            editActuality={props.createOrUpdateActuality}
            delActuality={props.deleteActuality}
            push={push}
            actualitys={actualitys}
          />
        </Fragment>
      )}
    />
  </BaseLayout>
);

ActualitysContainer.propTypes = {
  createOrUpdateActuality: PropTypes.func.isRequired,
  deleteActuality: PropTypes.func.isRequired,

  actualitys: PropTypes.objectOf(PropTypes.shape()).isRequired,

  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect(
  state => ({
    actualitys: state.data.entities.actualitys,
  }),
  { createOrUpdateActuality, deleteActuality },
)(withRouter(ActualitysContainer));
