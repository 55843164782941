import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { ButtonConfirmModal } from 'react-components-linaia';

import CardListView from '../shared/CardListView';

const AccountsCardListView = ({
  accounts, baseUrl, onClickDelete,
}) => (
  <CardListView
    className="card-list-accounts"
    data={accounts}
    cardComponent={account => (
      <div className="col-3_xs-12_sm-6_md-4">
        <div className="box card simple-card">
          <div className="box-content">
            <div className="grid-noBottom">
              <div className="col-12">
                <div className="card-title">
                  {account.username}
                </div>
              </div>
              {account.email && (
                <div className="col-12">
                  <div className="btn-group">
                    <a href={`mailto:${account.email}`}>
                      <i className="fas fa-envelope" />
                      <span>{account.email}</span>
                    </a>
                  </div>
                </div>
              )}
            </div>
            <div className="card-actions btn-group">
              <NavLink to={`${baseUrl}/${account.account_id}/edit`} className="btn">
                <i className="far fa-pencil" />
                <span>Modifier</span>
              </NavLink>
              {onClickDelete && (
                <ButtonConfirmModal
                  onConfirmationButton={() => onClickDelete(account.account_id)}
                  message="Voulez-vous vraiment supprimer cet administrateur ?"
                >
                  <button className="btn" type="button">
                    <i className="far fa-trash" />
                    <span>Supprimer</span>
                  </button>
                </ButtonConfirmModal>
              )}
            </div>
          </div>
        </div>
      </div>
    )}
    sortByDefault="username"
    search="username"
    searchLabel="Rechercher un administrateur"
    buttons={(
      <div>
        <NavLink className="btn btn-main-mobile" to={`${baseUrl}/new`}>
          <span>Ajouter un administrateur</span>
          <i className="far fa-plus" />
        </NavLink>
      </div>
    )}
  />
);

AccountsCardListView.propTypes = {
  accounts: PropTypes.objectOf(PropTypes.shape({
    account_id: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
  })).isRequired,

  baseUrl: PropTypes.string.isRequired,
  onClickDelete: PropTypes.func,
};

export default (AccountsCardListView);
