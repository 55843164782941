import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { NavLink } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';
import {
  Field, CleaveField, InputField,
} from 'react-components-linaia';

const StructureEditForm = ({ handleSubmit, baseUrl, initialValues }) => (
  <Fragment>
    <div className="modal-title">
      <div className="modal-title-content">
        {initialValues.technician_id >= 0 ? 'Modifier la structure' : 'Ajouter une structure'}
        <NavLink className="btn" to={baseUrl}>
          <i className="fal fa-times" />
        </NavLink>
      </div>
    </div>
    <form onSubmit={handleSubmit}>
      <div className="modal-body">
        <div className="grid">
          <Field
            name="structureName"
            component={InputField}
            label="Nom de la structure"
            className="col-12"
          />
          <Field
            name="structurePhone"
            type="tel"
            component={CleaveField}
            label="Téléphone"
            className="col-tel"
            cleaveOptions={{ phone: true, phoneRegionCode: 'FR' }}
          />
          <Field
            name="structureEmail"
            type="email"
            component={InputField}
            label="Email"
            className="col"
          />
        </div>
      </div>
      <div className="modal-footer">
        <div className="btn-group center">
          <button className="btn btn-primary" type="submit">
            Enregistrer
          </button>
        </div>
      </div>
    </form>
  </Fragment>
);

StructureEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
  initialValues: PropTypes.shape().isRequired,
};

export default reduxForm({
  form: 'StructureEditForm',
  validate: (values = {}) => {
    const errors = {};

    if (!values.structureName) errors.structureName = 'Merci de renseigner le nom de la structure';
    if (!/^(\+33 |00 33 |0)[1-9]( \d\d){4}$/.test(values.structurePhone)) errors.structurePhone = 'Merci de renseigner le numéro de téléphone de la structure';
    if (!isEmail(values.structureEmail || '')) errors.structureEmail = 'Merci de renseigner l’adresse email de la structure';

    return errors;
  },
})(StructureEditForm);
