import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { withRouter, Switch, Route } from 'react-router-dom';
import { get } from 'lodash';

import {
  createOrUpdateClient,
  deleteClient,
} from '../../store/modules/clients';

import BaseLayout from '../shared/BaseLayout';

import ClientEditForm from '../../components/clients/ClientEditForm';
import ClientsCardListView from '../../components/clients/ClientsCardListView';

import BOHeader from '../../components/clients/BOHeader';
import SitesAdminContainer from '../sites/SitesAdminContainer';
import ContactsContainer from '../contacts/ContactsContainer';
import ContractsContainer from '../contracts/ContractsContainer';
import DocumentsContainer from '../documents/DocumentsContainer';
import BillsContainer from '../bills/BillsContainer';
import EquipmentsContainer from '../equipments/EquipmentsContainer';
import PassagesContainer from '../passages/PassagesContainer';

import modalDefaultClass from '../../utils/ModalDefaultClass';

const RenderEditDetailsModalsRouter = ({ url, editClient, push, clients }) => (
  <Switch>
    <Route
      exact
      path={`${url}/new`}
      render={() => (
        <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
          <ClientEditForm
            initialValues={{ clientDivalto: false }}
            onSubmit={v => {
              editClient(v).then(() => push(url));
            }}
            baseUrl={url}
          />
        </Modal>
      )}
    />
    <Route
      exact
      path={`${url}/:clientId/edit`}
      render={subProps =>
        get(clients, subProps.match.params.clientId, null) && (
          <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
            <ClientEditForm
              initialValues={{
                ...clients[subProps.match.params.clientId],
                clientDivalto: clients[subProps.match.params.clientId]
                  .clientDivalto
                  ? clients[subProps.match.params.clientId].clientDivalto
                  : false,
              }}
              onSubmit={v => {
                editClient(v).then(() => push(url));
              }}
              baseUrl={url}
            />
          </Modal>
        )
      }
    />
  </Switch>
);

RenderEditDetailsModalsRouter.propTypes = {
  url: PropTypes.string.isRequired,
  editClient: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  clients: PropTypes.objectOf(PropTypes.shape()).isRequired,
};

const ClientsAdminContainer = ({
  match: { url },
  history: { push },
  clients,
  ...props
}) => (
  <Switch>
    <Route
      path={`${url}/:clientId/manage`}
      render={({ match }) => {
        const params = {
          clientId: parseInt(match.params.clientId, 10),
          pathName: props.location.pathname,
          isAdmin: true,
        };
        return (
          <BaseLayout
            title={get(clients, `${match.params.clientId}.clientName`, '')}
          >
            <div className="container-wrapper">
              <BOHeader baseUrl={match.url} />
              <Switch>
                <Route
                  path={`${match.url}/sites`}
                  render={() => <SitesAdminContainer {...params} />}
                />
                <Route
                  path={`${match.url}/contacts`}
                  render={() => <ContactsContainer {...params} />}
                />
                <Route
                  path={`${match.url}/contracts`}
                  render={() => <ContractsContainer {...params} />}
                />
                <Route
                  path={`${match.url}/documents`}
                  render={() => <DocumentsContainer {...params} />}
                />
                <Route
                  path={`${match.url}/bills`}
                  render={() => <BillsContainer {...params} />}
                />
                <Route
                  path={`${match.url}/equipments`}
                  render={() => <EquipmentsContainer {...params} />}
                />
                <Route
                  path={`${match.url}/passages`}
                  render={() => <PassagesContainer {...params} />}
                />
              </Switch>
            </div>
          </BaseLayout>
        );
      }}
    />
    <Route
      path={url}
      render={({ match }) => (
        <BaseLayout title="Clients">
          <ClientsCardListView
            clients={clients}
            baseUrl={match.url}
            onClickDelete={clientId => {
              props.deleteClient(clientId).then(() => push(match.url));
            }}
          />
          <RenderEditDetailsModalsRouter
            url={match.url}
            editClient={props.createOrUpdateClient}
            push={push}
            clients={clients}
          />
        </BaseLayout>
      )}
    />
  </Switch>
);

ClientsAdminContainer.propTypes = {
  createOrUpdateClient: PropTypes.func.isRequired,
  deleteClient: PropTypes.func.isRequired,

  clients: PropTypes.objectOf(PropTypes.shape()).isRequired,

  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default connect(
  state => ({
    clients: state.data.entities.clients,
  }),
  { createOrUpdateClient, deleteClient },
)(withRouter(ClientsAdminContainer));
