import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Switch, Route } from "react-router-dom";
import { get, map, pickBy } from "lodash";

import ClientSiteHeader from "../../components/sites/ClientSiteHeader";

import DocumentsSiteContainer from "../documents/DocumentsSiteContainer";
import PlansSiteContainer from "../documents/plans/PlansSiteContainer";
import ReportsSiteContainer from "../documents/reports/ReportsSiteContainer";
import ContractsSiteContainer from "../contracts/ContractsSiteContainer";

const SitesClientContainer = ({
  match: { url, params },
  sites,
  structures,
  technicians,
  categorys,
  contactCategories,
}) => {
  return (
    <Fragment>
      <ClientSiteHeader
        site={get(sites, params.siteId, {})}
        structure={get(
          structures,
          get(technicians, get(sites, params.siteId, {}).siteTechnician, {}).technicianStructure,
          {}
        )}
        technician={get(technicians, get(sites, params.siteId, {}).siteTechnician, {})}
        categorys={categorys}
        contactCategories={contactCategories}
        baseUrl={url}
      />
      <Switch>
        <Route
          path={`${url}/reports`}
          render={() => (
            <ReportsSiteContainer
              siteId={parseInt(params.siteId, 10)}
              reportCategory={2}
              trackingCategory={3}
            />
          )}
        />
        <Route
          path={`${url}/plans`}
          render={() => (
            <PlansSiteContainer siteId={parseInt(params.siteId, 10)} documentCategory={1} />
          )}
        />
        {map(categorys, (category) => (
          <Route
            key={category.category_id}
            path={`${url}/${category.category_id}`}
            render={() => (
              <DocumentsSiteContainer
                siteId={parseInt(params.siteId, 10)}
                documentCategory={category.category_id}
              />
            )}
          />
        ))}
        <Route
          path={`${url}/bilan`}
          render={() => (
            <DocumentsSiteContainer siteId={parseInt(params.siteId, 10)} documentCategory={7} />
          )}
        />
        <Route
          path={`${url}/contracts`}
          render={() => <ContractsSiteContainer siteId={parseInt(params.siteId, 10)} />}
        />
        <Route
          path={`${url}/quotations`}
          render={() => (
            <DocumentsSiteContainer siteId={parseInt(params.siteId, 10)} documentCategory={0} />
          )}
        />
      </Switch>
    </Fragment>
  );
};

SitesClientContainer.propTypes = {
  sites: PropTypes.objectOf(PropTypes.shape()).isRequired,
  structures: PropTypes.objectOf(PropTypes.shape()).isRequired,
  technicians: PropTypes.objectOf(PropTypes.shape()).isRequired,
  categorys: PropTypes.objectOf(PropTypes.shape()).isRequired,
  contactCategories: PropTypes.arrayOf(PropTypes.number).isRequired,

  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default connect((state) => ({
  sites: state.data.entities.sites,
  structures: state.data.entities.structures,
  technicians: state.data.entities.technicians,
  categorys: pickBy(state.data.entities.categorys, (c) => c.categoryDefault === false),
  contactCategories: get(state, "auth.user.contact.contactCategories", []),
}))(withRouter(SitesClientContainer));
