import { flow, set } from 'lodash/fp';
import { omit } from 'lodash';

import { CALL_API, Schemas } from '../Schemas';

const FETCH_ALL_SHAREDDOCUMENTS_REQUEST = 'fgwa/shareddocuments/FETCH_ALL_SHAREDDOCUMENTS_REQUEST';
export const FETCH_ALL_SHAREDDOCUMENTS_SUCCESS = 'fgwa/shareddocuments/FETCH_ALL_SHAREDDOCUMENTS_SUCCESS';
const FETCH_ALL_SHAREDDOCUMENTS_FAILURE = 'fgwa/shareddocuments/FETCH_ALL_SHAREDDOCUMENTS_FAILURE';
const FETCH_SHAREDDOCUMENT_REQUEST = 'fgwa/shareddocuments/FETCH_SHAREDDOCUMENT_REQUEST';
const FETCH_SHAREDDOCUMENT_SUCCESS = 'fgwa/shareddocuments/FETCH_SHAREDDOCUMENT_SUCCESS';
const FETCH_SHAREDDOCUMENT_FAILURE = 'fgwa/shareddocuments/FETCH_SHAREDDOCUMENT_FAILURE';
const SET_SHAREDDOCUMENT_REQUEST = 'fgwa/shareddocuments/SET_SHAREDDOCUMENT_REQUEST';
const SET_SHAREDDOCUMENT_SUCCESS = 'fgwa/shareddocuments/SET_SHAREDDOCUMENT_SUCCESS';
const SET_SHAREDDOCUMENT_FAILURE = 'fgwa/shareddocuments/SET_SHAREDDOCUMENT_FAILURE';
const DELETE_SHAREDDOCUMENT_REQUEST = 'fgwa/shareddocuments/DELETE_SHAREDDOCUMENT_REQUEST';
const DELETE_SHAREDDOCUMENT_SUCCESS = 'fgwa/shareddocuments/DELETE_SHAREDDOCUMENT_SUCCESS';
const DELETE_SHAREDDOCUMENT_FAILURE = 'fgwa/shareddocuments/DELETE_SHAREDDOCUMENT_FAILURE';

export const shareddocumentsActionsHandlers = {
  [FETCH_ALL_SHAREDDOCUMENTS_REQUEST]: state => flow(
    set('loaded.shareddocuments', false),
    set('loading.shareddocuments', true),
  )(state),
  [FETCH_ALL_SHAREDDOCUMENTS_SUCCESS]: (state, action) => flow(
    set('entities.shareddocuments', action.response.entities.shareddocuments || {}),
    set('loaded.shareddocuments', true),
    set('loading.shareddocuments', false),
  )(state),
  [FETCH_ALL_SHAREDDOCUMENTS_FAILURE]: state => flow(
    set('loaded.shareddocuments', false),
    set('loading.shareddocuments', false),
  )(state),
  [FETCH_SHAREDDOCUMENT_SUCCESS]: (state, action) => flow(
    set(`entities.shareddocuments.${action.id}`, action.response.entities.shareddocuments),
  )(state),
  [SET_SHAREDDOCUMENT_SUCCESS]: (state, action) => flow(
    set('entities.shareddocuments', {
      ...state.entities.shareddocuments,
      ...action.response.entities.shareddocuments,
    }),
  )(state),
  [DELETE_SHAREDDOCUMENT_SUCCESS]: (state, action) => flow(
    set('entities.shareddocuments', omit(state.entities.shareddocuments, action.id)),
  )(state),
};

export function loadShareddocument (id) {
  return {
    id,
    [CALL_API]: {
      types: [
        FETCH_SHAREDDOCUMENT_REQUEST,
        FETCH_SHAREDDOCUMENT_SUCCESS,
        FETCH_SHAREDDOCUMENT_FAILURE,
      ],
      method: 'GET',
      endpoint: `/shareddocuments/${id}`,
      schema: Schemas.SHAREDDOCUMENT,
    },
  };
}

export function loadShareddocuments () {
  return {
    [CALL_API]: {
      types: [
        FETCH_ALL_SHAREDDOCUMENTS_REQUEST,
        FETCH_ALL_SHAREDDOCUMENTS_SUCCESS,
        FETCH_ALL_SHAREDDOCUMENTS_FAILURE,
      ],
      method: 'GET',
      endpoint: '/shareddocuments',
      schema: Schemas.SHAREDDOCUMENT_ARRAY,
    },
  };
}

export function createOrUpdateShareddocument (shareddocument) {
  return {
    [CALL_API]: {
      types: [
        SET_SHAREDDOCUMENT_REQUEST,
        SET_SHAREDDOCUMENT_SUCCESS,
        SET_SHAREDDOCUMENT_FAILURE,
      ],
      method: !shareddocument || !Number.isInteger(shareddocument.shareddocument_id) ? 'POST' : 'PUT',
      endpoint: !shareddocument || !Number.isInteger(shareddocument.shareddocument_id) ? '/shareddocuments' : `/shareddocuments/${shareddocument.shareddocument_id}`,
      schema: Schemas.SHAREDDOCUMENT,
      body: shareddocument,
      successMessage: 'Document partagé enregistré avec succès',
    },
  };
}

export function deleteShareddocument (id) {
  return {
    id,
    [CALL_API]: {
      types: [
        DELETE_SHAREDDOCUMENT_REQUEST,
        DELETE_SHAREDDOCUMENT_SUCCESS,
        DELETE_SHAREDDOCUMENT_FAILURE,
      ],
      method: 'DELETE',
      endpoint: `/shareddocuments/${id}`,
      successMessage: 'Document partagé supprimé avec succès',
    },
  };
}
