import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { NavLink } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';
import { isNumber } from 'lodash';
import {
  ToggleField,
  Field,
  CleaveField,
  FileField,
  InputField,
  SelectField,
  AUTHORIZED_UPLOAD_IMAGES
} from 'react-components-linaia';

import { uploadFiles, removeFile } from '../../store/modules/uploads';

import { UPLOADS_URL } from '../../constants/Config';

const TechnicianEditForm = ({
  handleSubmit,
  baseUrl,
  initialValues,
  upFiles,
  rmFile,
  structures
}) => (
  <Fragment>
    <div className="modal-title">
      <div className="modal-title-content">
        {initialValues.technician_id >= 0
          ? 'Modifier le technicien'
          : 'Ajouter un technicien'}
        <NavLink className="btn" to={baseUrl}>
          <i className="fal fa-times" />
        </NavLink>
      </div>
    </div>
    <form onSubmit={handleSubmit}>
      <div className="modal-body">
        <div className="grid">
          <Field
            name="technicianPhotoUrl"
            component={FileField}
            uploadFiles={upFiles}
            removeFile={rmFile}
            getUrl={item => `${UPLOADS_URL}/${item}`}
            placeholder=""
            buttonLabel="Importer une photo"
            className="col-12"
            acceptedFiles={AUTHORIZED_UPLOAD_IMAGES}
          />
          <Field
            name="technicianFirstname"
            component={InputField}
            disabled={initialValues.technicianIdExterne}
            label="Prénom"
            className="col-6_xs-12"
          />
          <Field
            name="technicianLastname"
            component={InputField}
            disabled={initialValues.technicianIdExterne}
            label="Nom"
            className="col-6_xs-12"
          />
          <Field
            name="technicianPhone"
            component={CleaveField}
            disabled={initialValues.technicianIdExterne}
            label="Téléphone"
            type="tel"
            className="col-tel"
            cleaveOptions={{ phone: true, phoneRegionCode: 'FR' }}
          />
          <Field
            name="technicianEmail"
            component={InputField}
            disabled={initialValues.technicianIdExterne}
            label="Email"
            type="email"
            className="col"
          />
          <Field
            name="technicianStructure"
            component={SelectField}
            options={structures}
            optionsKeys={{ value: 'structure_id', label: 'structureName' }}
            label="Structure"
            className="col-12"
          />
          <Field
            label="Accès Back-office"
            name="technicianAdmin"
            component={ToggleField}
            className="col-12"
            fieldClassName="checkbox"
          />
        </div>
      </div>
      <div className="modal-footer">
        <div className="btn-group center">
          <button className="btn btn-primary" type="submit">
            Enregistrer
          </button>
        </div>
      </div>
    </form>
  </Fragment>
);

TechnicianEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
  initialValues: PropTypes.shape().isRequired,

  structures: PropTypes.objectOf(PropTypes.shape()).isRequired,

  upFiles: PropTypes.func.isRequired,
  rmFile: PropTypes.func.isRequired
};

export default reduxForm({
  form: 'TechnicianEditForm',
  validate: (values = {}) => {
    const errors = {};

    if (!values.technicianFirstname)
      errors.technicianFirstname =
        'Merci de renseigner le prénom du technicien';
    if (!values.technicianLastname)
      errors.technicianLastname = 'Merci de renseigner le nom du technicien';
    if (!isEmail(values.technicianEmail || ''))
      errors.technicianEmail =
        'Merci de renseigner l’adresse email du technicien';
    if (!/^(\+33 |00 33 |0)[1-9]( \d\d){4}$/.test(values.technicianPhone))
      errors.technicianPhone =
        'Merci de renseigner le numéro de téléphone du technicien';
    if (!isNumber(values.technicianStructure))
      errors.technicianStructure =
        'Merci de choisir la structure du technicien';

    return errors;
  }
})(
  connect(
    state => ({
      structures: state.data.entities.structures
    }),
    { upFiles: uploadFiles, rmFile: removeFile }
  )(TechnicianEditForm)
);
