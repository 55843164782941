import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { withRouter, Switch, Route } from 'react-router-dom';
import { get, pickBy } from 'lodash';

import { updateOption } from '../../store/modules/options';

import { createOrUpdateCategory, deleteCategory } from '../../store/modules/categorys';
import { createOrUpdateSharedcategory, deleteSharedcategory } from '../../store/modules/sharedcategorys';

import BaseLayout from '../shared/BaseLayout';

import OptionEditForm from '../../components/options/OptionEditForm';
import OptionsListView from '../../components/options/OptionsListView';

import CategoryEditForm from '../../components/categorys/CategoryEditForm';
import CategorysTableListView from '../../components/categorys/CategorysTableListView';

import SharedcategoryEditForm from '../../components/sharedcategorys/SharedcategoryEditForm';
import SharedcategorysTableListView from '../../components/sharedcategorys/SharedcategorysTableListView';

import modalDefaultClass from '../../utils/ModalDefaultClass';

const OptionsContainer = ({
  match: { url }, history: { push }, options, categorys, sharedcategorys, ...props
}) => (
  <BaseLayout title="Paramètres">
    <div className="grid-equalHeight">
      <div className="col-4_md-12">
        <OptionsListView
          options={options}
          baseUrl={`${url}/options`}
        />
      </div>
      <div className="col-4_xs-12_md-6">
        <div className="box">
          <CategorysTableListView
            categorys={categorys}
            baseUrl={`${url}/categorys`}
            onClickDelete={(categoryId) => {
              props.deleteCategory(categoryId)
              .then(() => push(url));
            }}
          />
        </div>
      </div>
      <div className="col-4_xs-12_md-6">
        <div className="box">
          <div className="box-content">
            <SharedcategorysTableListView
              sharedcategorys={sharedcategorys}
              baseUrl={`${url}/sharedcategorys`}
              onClickDelete={(sharedcategoryId) => {
                props.deleteSharedcategory(sharedcategoryId)
                .then(() => push(url));
              }}
            />
          </div>
        </div>
      </div>
    </div>

    <Switch>
      <Route
        exact
        path={`${url}/options/:optionName/edit`}
        render={subProps => get(options, subProps.match.params.optionName, null) && (
          <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
            <OptionEditForm
              initialValues={{
                name: subProps.match.params.optionName,
                data: options[subProps.match.params.optionName],
              }}
              onSubmit={(v) => {
                props.updateOption(subProps.match.params.optionName, v.data)
                .then(() => push(url));
              }}
              baseUrl={url}
            />
          </Modal>
        )}
      />

      <Route
        exact
        path={`${url}/categorys/new`}
        render={() => (
          <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
            <CategoryEditForm
              initialValues={{}}
              onSubmit={(v) => {
                props.createOrUpdateCategory(v)
                .then(() => push(url));
              }}
              baseUrl={url}
            />
          </Modal>
        )}
      />
      <Route
        exact
        path={`${url}/categorys/:categoryId/edit`}
        render={subProps => get(categorys, subProps.match.params.categoryId, null) && (
          <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
            <CategoryEditForm
              initialValues={categorys[subProps.match.params.categoryId]}
              onSubmit={(v) => {
                props.createOrUpdateCategory(v)
                .then(() => push(url));
              }}
              baseUrl={url}
            />
          </Modal>
        )}
      />

      <Route
        exact
        path={`${url}/sharedcategorys/new`}
        render={() => (
          <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
            <SharedcategoryEditForm
              initialValues={{}}
              onSubmit={(v) => {
                props.createOrUpdateSharedcategory(v)
                .then(() => push(url));
              }}
              baseUrl={url}
            />
          </Modal>
        )}
      />
      <Route
        exact
        path={`${url}/sharedcategorys/:sharedcategoryId/edit`}
        render={subProps => get(sharedcategorys, subProps.match.params.sharedcategoryId, null) && (
          <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
            <SharedcategoryEditForm
              initialValues={sharedcategorys[subProps.match.params.sharedcategoryId]}
              onSubmit={(v) => {
                props.createOrUpdateSharedcategory(v)
                .then(() => push(url));
              }}
              baseUrl={url}
            />
          </Modal>
        )}
      />
    </Switch>
  </BaseLayout>
);

OptionsContainer.propTypes = {
  updateOption: PropTypes.func.isRequired,
  deleteCategory: PropTypes.func.isRequired,
  createOrUpdateCategory: PropTypes.func.isRequired,
  deleteSharedcategory: PropTypes.func.isRequired,
  createOrUpdateSharedcategory: PropTypes.func.isRequired,

  options: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.object,
  ])).isRequired,
  categorys: PropTypes.objectOf(PropTypes.shape()).isRequired,
  sharedcategorys: PropTypes.objectOf(PropTypes.shape()).isRequired,

  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect(
  state => ({
    options: state.data.entities.options,
    categorys: pickBy(state.data.entities.categorys, c => c.categoryDefault === false),
    sharedcategorys: state.data.entities.sharedcategorys,
  }),
  {
    updateOption,
    createOrUpdateCategory,
    deleteCategory,
    createOrUpdateSharedcategory,
    deleteSharedcategory,
  },
)(withRouter(OptionsContainer));
