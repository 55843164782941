import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { NavLink } from 'react-router-dom';
import { ButtonConfirmModal } from 'react-components-linaia';

import createSelector from '../../selectors/ClientSubEntitiesSelector';

const clientSitesSelector = createSelector();
const clientContractsSelector = createSelector();
const clientEquipmentsSelector = createSelector();

const PassageDetailView = ({
  passage = {}, baseUrl, onClickDelete, sites, contracts, technicians,
}) => (
  <div>
    <div>
      Passage Id:&nbsp;
      {passage.passage_id}
    </div>
    <div>
      Passage Date:&nbsp;
      {passage.passageDate}
    </div>
    <div>
      Passage Site:&nbsp;
      {get(sites, `${passage.passageSite}.siteName`)}
    </div>
    <div>
      Passage Contract:&nbsp;
      {get(contracts, `${passage.passageContract}.contractLabel`)}
    </div>
    <div>
      Passage Technicien:&nbsp;
      {get(technicians, `${passage.passageTechnicien}.technicianFirstname`)}
    </div>

    <div className="btn-group right">
      <NavLink className="btn" to={`${baseUrl}/${passage.passage_id}/edit`}>
        Modifier
      </NavLink>
      {onClickDelete && (
        <ButtonConfirmModal
          onConfirmationButton={() => onClickDelete()}
          message="Voulez-vous vraiment effacer le passage ?"
        >
          <button className="btn" type="button">Supprimer</button>
        </ButtonConfirmModal>
      )}
    </div>
  </div>
);

PassageDetailView.propTypes = {
  passage: PropTypes.shape({
    passage_id: PropTypes.number.isRequired,
    passageDate: PropTypes.string.isRequired,
    passageSite: PropTypes.number.isRequired,
    passageContract: PropTypes.number.isRequired,
    passageTechnicien: PropTypes.number.isRequired,
    passageEquipments: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  }).isRequired,

  sites: PropTypes.objectOf(PropTypes.shape()).isRequired,
  contracts: PropTypes.objectOf(PropTypes.shape()).isRequired,
  technicians: PropTypes.objectOf(PropTypes.shape()).isRequired,

  baseUrl: PropTypes.string.isRequired,
  onClickDelete: PropTypes.func,
};

export default connect(
  (state, { clientId }) => ({
    sites: clientSitesSelector(state.data.entities.sites, clientId, 'siteClient'),
    contracts: clientContractsSelector(state.data.entities.contracts, clientId, 'contractClient'),
    technicians: state.data.entities.technicians,
    equipments: clientEquipmentsSelector(
      state.data.entities.equipments,
      clientId,
      'equipmentContract',
      state.data.entities.contracts,
      'contractClient',
    ),
  }),
)(PassageDetailView);
