import React, { useState } from "react";
import { NavLink, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classNames from "classnames";
import { IconButton, SelectField } from "react-components-linaia";
import { values, map, filter, isNull, isEmpty } from "lodash";
import moment from "moment";
import { setConfig } from "../../store/modules/globals";
import { logout } from "../../store/modules/auth";

import { isAdmin, isClientContact, isTechnicianAdmin } from "../../utils/RolesUtils";

import logo from "../../../assets/images/logos/farago-bretagne-logo-app.png";
import mobileLogo from "../../../assets/images/logos/farago-bretagne-logo-app-mobile.png";

const Header = ({
  menuIsOpen,
  menuIsTiny,
  account,
  sites,
  contracts,
  setConf,
  doLogout,
  history: {
    push,
    location: { pathname },
  },
}) => {
  const [site, setSite] = useState(pathname.indexOf("/clients/sites/") === 0 ? pathname[15] : null);

  // # 3723
  /* Ne pas faire apparaître un site qui n'a pas de contrat ou qui n'a plus de contrats valide (date de fin de tous les contrats passée) */
  const sitesWithValidContracts = filter(sites, (site) => {
    // on recupère tous les contrats des différents sites
    const contractsSite = filter(contracts, {
      contractAffectedSite: site.site_id,
    });

    // pour chaque contrat on va tester si ils sont actif ou pas
    const validContract = filter(contractsSite, (o) => {
      return (
        (!isNull(o.contractStartDate) &&
          isNull(o.contractEndDate) &&
          moment(o.contractStartDate).isBefore(moment())) ||
        (!isNull(o.contractStartDate) &&
          !isNull(o.contractEndDate) &&
          moment().isBetween(moment(o.contractStartDate), moment(o.contractEndDate)))
      );
    });
    // si aucun contract valid, on ne retourne pas le site
    return !isEmpty(validContract);
  });

  const redirectToPath = (selectedSite) => {
    setSite(selectedSite);
    if (menuIsOpen === true) {
      setConf("menuIsOpen", false);
    }
    return push(`/clients/sites/${selectedSite}/reports`);
  };
  return (
    <header className="header">
      <div className={classNames("header-menu", { tiny: menuIsTiny })}>
        <div className="container">
          <a href="/" className="logo-block">
            <img src={logo} alt="Logo" className="logo" />
          </a>
          <nav
            className={classNames(
              "main-nav",
              { "animated opened": menuIsOpen === true },
              { "animated closed": menuIsOpen === false }
            )}
          >
            <div href="/" className="mobile-logo-block">
              <img src={mobileLogo} alt="Logo" className="mobile-logo" />
            </div>
            {isTechnicianAdmin(account) && (
              <ul>
                <li>
                  <NavLink
                    to="/technicians"
                    onClick={menuIsOpen === true ? () => setConf("menuIsOpen", false) : null}
                    exact
                    activeClassName="active"
                  >
                    <span>Sites</span>
                  </NavLink>
                </li>
              </ul>
            )}

            {isAdmin(account) && (
              <ul>
                <li>
                  <NavLink
                    to="/admin/clients"
                    onClick={menuIsOpen === true ? () => setConf("menuIsOpen", false) : null}
                    exact
                    activeClassName="active"
                  >
                    <span>Clients</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/admin/technicians"
                    onClick={menuIsOpen === true ? () => setConf("menuIsOpen", false) : null}
                    exact
                    activeClassName="active"
                  >
                    <span>Techniciens</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/admin/structures"
                    onClick={menuIsOpen === true ? () => setConf("menuIsOpen", false) : null}
                    exact
                    activeClassName="active"
                  >
                    <span>Structures</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/admin/actualitys"
                    onClick={menuIsOpen === true ? () => setConf("menuIsOpen", false) : null}
                    exact
                    activeClassName="active"
                  >
                    <span>Actualités</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/admin/shareddocuments"
                    onClick={menuIsOpen === true ? () => setConf("menuIsOpen", false) : null}
                    exact
                    activeClassName="active"
                  >
                    <span>Documents</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/admin/accounts"
                    onClick={menuIsOpen === true ? () => setConf("menuIsOpen", false) : null}
                    exact
                    activeClassName="active"
                  >
                    <span>Administrateurs</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/admin/options"
                    onClick={menuIsOpen === true ? () => setConf("menuIsOpen", false) : null}
                    exact
                    activeClassName="active"
                  >
                    <span>Paramètres</span>
                  </NavLink>
                </li>
              </ul>
            )}
            {isClientContact(account) && (
              <ul>
                <li>
                  <NavLink
                    to="/"
                    onClick={menuIsOpen === true ? () => setConf("menuIsOpen", false) : null}
                    exact
                    activeClassName="active"
                  >
                    <span>Tableau de bord</span>
                  </NavLink>
                </li>
                {account.contact.contactBillingAccess === true && (
                  <li>
                    <NavLink
                      to="/clients/bills"
                      onClick={menuIsOpen === true ? () => setConf("menuIsOpen", false) : null}
                      exact
                      activeClassName="active"
                    >
                      <span>Factures</span>
                    </NavLink>
                  </li>
                )}
                {account.contact.contactCategories.includes(0) && (
                  <li>
                    <NavLink
                      to="/clients/quotations"
                      onClick={menuIsOpen === true ? () => setConf("menuIsOpen", false) : null}
                      exact
                      activeClassName="active"
                    >
                      <span>Devis</span>
                    </NavLink>
                  </li>
                )}
                <li>
                  <NavLink
                    to="/clients/contracts"
                    onClick={menuIsOpen === true ? () => setConf("menuIsOpen", false) : null}
                    exact
                    activeClassName="active"
                  >
                    <span>Contrats client</span>
                  </NavLink>
                </li>
              </ul>
            )}
            <div className="user-block">
              {isClientContact(account) && (
                <div className="user-site">
                  {sitesWithValidContracts.length > 1 && (
                    <SelectField
                      classNamePrefix="react-select"
                      classNameReactSelect="react-select"
                      placeholder="Choix du site"
                      input={{
                        value: site,
                        onChange: redirectToPath,
                      }}
                      options={map(sitesWithValidContracts, (s) => ({
                        label: s.siteName,
                        value: s.site_id,
                      }))}
                    />
                  )}
                  {sitesWithValidContracts.length === 1 && (
                    <NavLink
                      className="btn"
                      to={`/clients/sites/${values(sites)[0].site_id}/reports`}
                      onClick={menuIsOpen === true ? () => setConf("menuIsOpen", false) : null}
                      exact
                      activeClassName="active"
                    >
                      <i className="fas fa-warehouse" />
                      <span>{values(sites)[0].siteName}</span>
                    </NavLink>
                  )}
                </div>
              )}
              <div className="user-profil">
                <div className="user-avatar-block">
                  <div className="user-avatar">
                    <img
                      src="//www.gravatar.com/avatar/60d44261bab4309c41da7218addde9db?d=404"
                      alt="NL"
                    />
                  </div>
                </div>
                <div className="user-name">{account.username}</div>
              </div>
              <div className="user-actions">
                <NavLink
                  to="/user/account"
                  onClick={menuIsOpen === true ? () => setConf("menuIsOpen", false) : null}
                  exact
                  activeClassName="active"
                  className="btn btn-profil"
                >
                  <i className="item-icon fal fa-user" />
                </NavLink>
                <IconButton title="Déconnexion" fontIcon="fal fa-sign-out" onClick={doLogout} />
              </div>
            </div>
          </nav>
          <div
            className={classNames(
              "menu-mask",
              { "animated opened": menuIsOpen === true },
              { "animated closed": menuIsOpen === false }
            )}
            onClick={() => setConf("menuIsOpen", false)}
          />
          <IconButton
            onClick={() => setConf("menuIsOpen", true)}
            fontIcon="far fa-bars"
            title="Menu"
            className={classNames("menu-button", { "menu-opened": menuIsOpen })}
          />
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  menuIsOpen: PropTypes.bool,
  menuIsTiny: PropTypes.bool,
  account: PropTypes.shape({
    username: PropTypes.string.isRequired,
  }).isRequired,
  sites: PropTypes.objectOf(PropTypes.shape()),

  setConf: PropTypes.func.isRequired,
  doLogout: PropTypes.func,

  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect(
  (state) => ({
    menuIsOpen: state.data.config.menuIsOpen,
    menuIsTiny: state.data.config.menuIsTiny,
    sites: state.data.entities.sites,
    contracts: state.data.entities.contracts,
    account: state.auth.user,
  }),
  { setConf: setConfig, doLogout: logout }
)(withRouter(Header));
