import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter, NavLink } from "react-router-dom";
import { map } from "lodash";

import { UPLOADS_URL } from "../../constants/Config";

import clientSitePhoto from "../../../assets/images/ui/client-site-photo.jpg";
import defaultTechnicianPhoto from "../../../assets/images/ui/user-profil.png";

const SubNavLink = ({ url, title, setRef }) => (
  <li ref={(ref) => setRef(ref)}>
    <NavLink to={url} activeClassName="active">
      <span>{title}</span>
    </NavLink>
  </li>
);

SubNavLink.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  setRef: PropTypes.func.isRequired,
};

const ClientSiteHeader = ({
  site,
  structure,
  technician,
  categorys,
  contactCategories,
  baseUrl,
  location: { pathname },
}) => {
  const [nav, setNavref] = useState();
  const [reports, setReportsRef] = useState();
  const [plans, setPlansRef] = useState();
  const [products, setProductsRef] = useState();
  const [empowerments, setEmpowermentsRef] = useState();
  const [materials, setMaterialsRef] = useState();
  const [contracts, setContractsRef] = useState();
  const [quotations, setQuotationsRef] = useState();

  const activeName = pathname.replace(`${baseUrl}/`, "");
  switch (activeName) {
    case "reports":
      if (reports && reports.offsetLeft && nav)
        nav.scrollTo({
          top: 0,
          left: reports.offsetLeft - nav.offsetWidth / 2 + reports.offsetWidth / 2,
          behavior: "smooth",
        });
      break;
    case "plans":
      if (plans && plans.offsetLeft && nav)
        nav.scrollTo({
          top: 0,
          left: plans.offsetLeft - nav.offsetWidth / 2 + plans.offsetWidth / 2,
          behavior: "smooth",
        });
      break;
    case "products":
      if (products && products.offsetLeft && nav)
        nav.scrollTo({
          top: 0,
          left: products.offsetLeft - nav.offsetWidth / 2 + products.offsetWidth / 2,
          behavior: "smooth",
        });
      break;
    case "empowerments":
      if (empowerments && empowerments.offsetLeft && nav)
        nav.scrollTo({
          top: 0,
          left: empowerments.offsetLeft - nav.offsetWidth / 2 + empowerments.offsetWidth / 2,
          behavior: "smooth",
        });
      break;
    case "materials":
      if (materials && materials.offsetLeft && nav)
        nav.scrollTo({
          top: 0,
          left: materials.offsetLeft - nav.offsetWidth / 2 + materials.offsetWidth / 2,
          behavior: "smooth",
        });
      break;
    case "contracts":
      if (contracts && contracts.offsetLeft && nav)
        nav.scrollTo({
          top: 0,
          left: contracts.offsetLeft - nav.offsetWidth / 2 + contracts.offsetWidth / 2,
          behavior: "smooth",
        });
      break;
    case "quotations":
      if (quotations && quotations.offsetLeft && nav)
        nav.scrollTo({
          top: 0,
          left: quotations.offsetLeft - nav.offsetWidth / 2 + quotations.offsetWidth / 2,
          behavior: "smooth",
        });
      break;
    default:
      if (products && products.offsetLeft && nav)
        nav.scrollTo({
          top: 0,
          left: products.offsetLeft - nav.offsetWidth / 2 + products.offsetWidth / 2,
          behavior: "smooth",
        });
      break;
  }

  return (
    <div className="site-header">
      <div className="site-infos">
        <div className="grid-equalHeight">
          <div className="col-5_sm-0 site-infos-details">
            <div className="box box-transparent">
              <div className="box-content">
                <div className="grid-noBottom">
                  <div className="col-4">
                    {site.sitePhotoUrl ? (
                      <img src={`${UPLOADS_URL}/${site.sitePhotoUrl}`} alt={site.siteName} />
                    ) : (
                      <img src={clientSitePhoto} alt={site.siteName} />
                    )}
                  </div>
                  <div className="col">
                    <div className="data-list">
                      <div className="data-title">{site.siteName}</div>
                      {site.siteAddress1 && (
                        <div>
                          <div className="data">{site.siteAddress1}</div>
                          <div className="data">{site.siteAddress2}</div>
                          <div className="data">{site.siteAddress3}</div>
                          <div className="data">
                            {site.sitePostalCode} {site.siteCity}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3_sm-6 site-infos-structure">
            <div className="box box-secondary noShadow">
              <div className="box-content">
                <div className="title">Votre contact administratif</div>
                <div className="data-list">
                  <div className="data-title">{structure.structureName}</div>
                  <a
                    className="data"
                    href={`tel:${(structure.structurePhone || "").replace(/ /g, "")}`}
                  >
                    {structure.structurePhone}
                  </a>
                  <a className="data" href={`mailto:${structure.structureEmail}`}>
                    {structure.structureEmail}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-4_sm-6 site-infos-technician">
            <div className="box box-secondary noShadow">
              <div className="box-content">
                <div className="title">Votre technicien</div>
                <div className="grid-noBottom">
                  <div className="col-2_sm-0">
                    <img
                      src={
                        technician.technicianPhotoUrl
                          ? `${UPLOADS_URL}/${technician.technicianPhotoUrl}`
                          : defaultTechnicianPhoto
                      }
                      alt=""
                    />
                  </div>
                  <div className="col">
                    <div className="data-list">
                      <div className="data-title">
                        {technician.technicianFirstname} {technician.technicianLastname}
                      </div>
                      <a
                        className="data"
                        href={`tel:${(technician.technicianPhone || "").replace(/ /g, "")}`}
                      >
                        {technician.technicianPhone}
                      </a>
                      <a className="data" href={`mailto:${technician.technicianEmail}`}>
                        {technician.technicianEmail}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="submenu-wrapper">
        <nav ref={(ref) => setNavref(ref)} className="submenu">
          <ul>
            <SubNavLink setRef={setReportsRef} url={`${baseUrl}/reports`} title="Rapports" />
            {contactCategories.includes(1) && (
              <SubNavLink setRef={setPlansRef} url={`${baseUrl}/plans`} title="Plans" />
            )}
            {map(categorys, (category) => (
              <SubNavLink
                key={category.category_id}
                setRef={setProductsRef}
                url={`${baseUrl}/${category.category_id}`}
                title={category.categoryName}
              />
            ))}
            <SubNavLink
              setRef={setContractsRef}
              url={`${baseUrl}/contracts`}
              title="Contrats Site"
            />
            {contactCategories.includes(0) && (
              <SubNavLink setRef={setQuotationsRef} url={`${baseUrl}/quotations`} title="Devis" />
            )}
          </ul>
        </nav>
      </div>
    </div>
  );
};

ClientSiteHeader.propTypes = {
  site: PropTypes.shape(),
  structure: PropTypes.shape(),
  technician: PropTypes.shape(),
  categorys: PropTypes.shape(),
  contactCategories: PropTypes.arrayOf(PropTypes.number).isRequired,
  baseUrl: PropTypes.string.isRequired,

  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(ClientSiteHeader);
