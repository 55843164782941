import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { NavLink } from 'react-router-dom';
import {
  Field, InputField,
} from 'react-components-linaia';

const SharedcategoryEditForm = ({ handleSubmit, baseUrl, initialValues }) => (
  <Fragment>
    <div className="modal-title">
      <div className="modal-title-content">
        {initialValues.sharedcategory_id >= 0 ? 'Modifier la catégorie partagée' : 'Ajouter une catégorie partagée'}
        <NavLink className="btn" to={baseUrl}>
          <i className="fal fa-times" />
        </NavLink>
      </div>
    </div>
    <form onSubmit={handleSubmit}>
      <div className="modal-body">
        <div className="grid">
          <Field name="sharedcategoryName" component={InputField} label="Nom de la catégorie partagée" className="col-12" />
        </div>
        <div className="btn-group center">
          <button className="btn btn-primary" type="submit">
            Enregistrer
          </button>
        </div>
      </div>
    </form>
  </Fragment>
);

SharedcategoryEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
  initialValues: PropTypes.shape().isRequired,
};

export default reduxForm({
  form: 'SharedcategoryEditForm',
  validate: (values = {}) => {
    const errors = {};

    if (!values.sharedcategoryName) errors.sharedcategoryName = 'Merci de renseigner le nom de la catégorie partagée';

    return errors;
  },
})(SharedcategoryEditForm);
