import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { get } from 'lodash';
import moment from 'moment';
import { ButtonConfirmModal } from 'react-components-linaia';

import { CONTRACT_TYPES } from '../../constants/Properties';

import createSelector from '../../selectors/ClientSubEntitiesSelector';
import { DATE_FORMAT } from '../../constants/Config';
import CardListView from '../shared/CardListView';

const clientSubEntitiesSelector = createSelector();

const ContractsCardListView = ({
  contracts,
  baseUrl,
  onClickDelete,
  sites,
  isAdmin,
}) => (
  <CardListView
    className="card-list-contracts"
    data={contracts}
    title={!isAdmin && 'Contrats'}
    cardComponent={contract => (
      <div className="col-3_xs-12_sm-6_md-4">
        <div className="box card simple-card">
          <div className="box-content">
            <div className="grid-noBottom">
              {contract.contractNumber && (
                <div className="col-12">
                  <div className="card-title">N° {contract.contractNumber}</div>
                </div>
              )}
              {contract.contractType && (
                <div className="col-12">
                  <div className="card-data">
                    {CONTRACT_TYPES[contract.contractType]}
                  </div>
                </div>
              )}
              {contract.contractLabel && (
                <div className="col-12">
                  <span className="iconed-data">{contract.contractLabel}</span>
                </div>
              )}
              {contract.contractStartDate && (
                <div className="col-12">
                  <span className="iconed-data">
                    Début:{' '}
                    {moment(contract.contractStartDate).format(DATE_FORMAT)}
                  </span>
                </div>
              )}
              {contract.contractEndDate && (
                <div className="col-12">
                  <span className="iconed-data">
                    Fin: {moment(contract.contractEndDate).format(DATE_FORMAT)}
                  </span>
                </div>
              )}
              {contract.contractNbPassage && contract.contractNbPassage >= 0 ? (
                <div className="col-12">
                  <span className="iconed-data">
                    Passages: {contract.contractNbPassage} /an
                  </span>
                </div>
              ) : (
                <div className="col-12">
                  <span className="iconed-data">Aucun Passage défini</span>
                </div>
              )}
              <div className="col-12">
                <div className="box-separator" />
              </div>
              <div className="col-12">
                <span className="iconed-data">
                  <i className="fas fa-warehouse" />
                  <span>
                    {get(sites, `${contract.contractAffectedSite}.siteName`)}
                  </span>
                </span>
              </div>
            </div>
            {isAdmin && (
              <div className="card-actions btn-group">
                {!contract.contractIdExterne && (
                  <NavLink
                    to={`${baseUrl}/${contract.contract_id}/edit`}
                    className="btn"
                  >
                    <i className="far fa-pencil" />
                    <span>Modifier</span>
                  </NavLink>
                )}

                {onClickDelete && !contract.contractIdExterne && (
                  <ButtonConfirmModal
                    onConfirmationButton={() =>
                      onClickDelete(contract.contract_id)
                    }
                    message="Voulez-vous vraiment supprimer ce contrat ?"
                  >
                    <button className="btn" type="button">
                      <i className="far fa-trash" />
                      <span>Supprimer</span>
                    </button>
                  </ButtonConfirmModal>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    )}
    search={['contractNumber', 'contractLabel']}
    searchLabel="Rechercher"
    sortByDefault="contractNumber"
    buttons={
      isAdmin && (
        <div>
          <NavLink className="btn btn-main-mobile" to={`${baseUrl}/new`}>
            <span>Ajouter un contrat</span>
            <i className="far fa-plus" />
          </NavLink>
        </div>
      )
    }
  />
);

ContractsCardListView.propTypes = {
  contracts: PropTypes.objectOf(
    PropTypes.shape({
      contract_id: PropTypes.number.isRequired,
      contractType: PropTypes.string.isRequired,
      contractNumber: PropTypes.string.isRequired,
      contractLabel: PropTypes.string.isRequired,
      contractStartDate: PropTypes.string.isRequired,
      contractEndDate: PropTypes.string.isRequired,
      contractNbPassage: PropTypes.number.isRequired,
      contractClient: PropTypes.number.isRequired,
      contractAffectedSite: PropTypes.number.isRequired,
    }),
  ).isRequired,

  isAdmin: PropTypes.bool,

  sites: PropTypes.objectOf(PropTypes.shape()).isRequired,
  account: PropTypes.shape({
    username: PropTypes.string.isRequired,
  }).isRequired,

  baseUrl: PropTypes.string.isRequired,
  onClickDelete: PropTypes.func,
};

export default connect((state, { clientId }) => ({
  sites: clientSubEntitiesSelector(
    state.data.entities.sites,
    clientId,
    'sitesClient',
  ),
  account: state.auth.user,
}))(ContractsCardListView);
