import { flow, set } from 'lodash/fp';
import { omit, remove } from 'lodash';

import { CALL_API, Schemas } from '../Schemas';

const FETCH_ALL_EQUIPMENTS_REQUEST = 'fgwa/equipments/FETCH_ALL_EQUIPMENTS_REQUEST';
export const FETCH_ALL_EQUIPMENTS_SUCCESS = 'fgwa/equipments/FETCH_ALL_EQUIPMENTS_SUCCESS';
const FETCH_ALL_EQUIPMENTS_FAILURE = 'fgwa/equipments/FETCH_ALL_EQUIPMENTS_FAILURE';
const FETCH_EQUIPMENT_REQUEST = 'fgwa/equipments/FETCH_EQUIPMENT_REQUEST';
const FETCH_EQUIPMENT_SUCCESS = 'fgwa/equipments/FETCH_EQUIPMENT_SUCCESS';
const FETCH_EQUIPMENT_FAILURE = 'fgwa/equipments/FETCH_EQUIPMENT_FAILURE';
const SET_EQUIPMENT_REQUEST = 'fgwa/equipments/SET_EQUIPMENT_REQUEST';
const SET_EQUIPMENT_SUCCESS = 'fgwa/equipments/SET_EQUIPMENT_SUCCESS';
const SET_EQUIPMENT_FAILURE = 'fgwa/equipments/SET_EQUIPMENT_FAILURE';
const DELETE_EQUIPMENT_REQUEST = 'fgwa/equipments/DELETE_EQUIPMENT_REQUEST';
const DELETE_EQUIPMENT_SUCCESS = 'fgwa/equipments/DELETE_EQUIPMENT_SUCCESS';
const DELETE_EQUIPMENT_FAILURE = 'fgwa/equipments/DELETE_EQUIPMENT_FAILURE';
const DELETE_EQUIPMENTPLAN_REQUEST = 'fgwa/equipments/DELETE_EQUIPMENTPLAN_REQUEST';
const DELETE_EQUIPMENTPLAN_SUCCESS = 'fgwa/equipments/DELETE_EQUIPMENTPLAN_SUCCESS';
const DELETE_EQUIPMENTPLAN_FAILURE = 'fgwa/equipments/DELETE_EQUIPMENTPLAN_FAILURE';

export const equipmentsActionsHandlers = {
  [FETCH_ALL_EQUIPMENTS_REQUEST]: state => flow(
    set('loaded.equipments', false),
    set('loading.equipments', true),
  )(state),
  [FETCH_ALL_EQUIPMENTS_SUCCESS]: (state, action) => flow(
    set('entities.equipments', action.response.entities.equipments || {}),
    set('loaded.equipments', true),
    set('loading.equipments', false),
  )(state),
  [FETCH_ALL_EQUIPMENTS_FAILURE]: state => flow(
    set('loaded.equipments', false),
    set('loading.equipments', false),
  )(state),
  [FETCH_EQUIPMENT_SUCCESS]: (state, action) => flow(
    set(`entities.equipments.${action.id}`, action.response.entities.equipments),
  )(state),
  [SET_EQUIPMENT_REQUEST]: state => flow(
    set('loading.equipments', true),
  )(state),
  [SET_EQUIPMENT_SUCCESS]: (state, action) => flow(
    set('entities.equipments', {
      ...state.entities.equipments,
      ...action.response.entities.equipments,
    }),
    set('loading.equipments', false),
  )(state),
  [DELETE_EQUIPMENT_SUCCESS]: (state, action) => flow(
    set('entities.equipments', omit(state.entities.equipments, action.id)),
  )(state),
  [DELETE_EQUIPMENTPLAN_SUCCESS]: (state, action) => flow(
    set('entities.equipments', remove(state.entities.equipments.equipmentPlan, n => n === action.id)),
  )(state),
};

export function loadEquipment (id) {
  return {
    id,
    [CALL_API]: {
      types: [
        FETCH_EQUIPMENT_REQUEST,
        FETCH_EQUIPMENT_SUCCESS,
        FETCH_EQUIPMENT_FAILURE,
      ],
      method: 'GET',
      endpoint: `/equipments/${id}`,
      schema: Schemas.EQUIPMENT,
    },
  };
}

export function loadEquipments () {
  return {
    [CALL_API]: {
      types: [
        FETCH_ALL_EQUIPMENTS_REQUEST,
        FETCH_ALL_EQUIPMENTS_SUCCESS,
        FETCH_ALL_EQUIPMENTS_FAILURE,
      ],
      method: 'GET',
      endpoint: '/equipments',
      schema: Schemas.EQUIPMENT_ARRAY,
    },
  };
}

export function createOrUpdateEquipment (equipment) {
  return {
    [CALL_API]: {
      types: [
        SET_EQUIPMENT_REQUEST,
        SET_EQUIPMENT_SUCCESS,
        SET_EQUIPMENT_FAILURE,
      ],
      method: !equipment || !Number.isInteger(equipment.equipment_id) ? 'POST' : 'PUT',
      endpoint: !equipment || !Number.isInteger(equipment.equipment_id) ? '/equipments' : `/equipments/${equipment.equipment_id}`,
      schema: Schemas.EQUIPMENT,
      body: equipment,
      successMessage: 'Equipement enregistré avec succès',
    },
  };
}

export function deleteEquipment (id) {
  return {
    id,
    [CALL_API]: {
      types: [
        DELETE_EQUIPMENT_REQUEST,
        DELETE_EQUIPMENT_SUCCESS,
        DELETE_EQUIPMENT_FAILURE,
      ],
      method: 'DELETE',
      endpoint: `/equipments/${id}`,
      successMessage: 'Equipement supprimé avec succès',
    },
  };
}

export function deleteEquipmentPlan (id) {
  return {
    id,
    [CALL_API]: {
      types: [
        DELETE_EQUIPMENTPLAN_REQUEST,
        DELETE_EQUIPMENTPLAN_SUCCESS,
        DELETE_EQUIPMENTPLAN_FAILURE,
      ],
      method: 'POST',
      endpoint: `/equipments/${id}`,
      successMessage: 'Equipement supprimé du plan avec succès',
    },
  };
}
