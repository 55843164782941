import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { map, isNumber } from 'lodash';
import {
  Field, DateField, InputField, SelectField,
} from 'react-components-linaia';

import { CONTRACT_TYPES } from '../../constants/Properties';

import createSelector from '../../selectors/ClientSubEntitiesSelector';

const clientSubEntitiesSelector = createSelector();

const ContractEditForm = ({
  handleSubmit, baseUrl, initialValues, sites,
}) => (
  <Fragment>
    <div className="modal-title">
      <div className="modal-title-content">
        {initialValues.contract_id >= 0 ? 'Modifier le contrat' : 'Ajouter un contrat'}
        <NavLink className="btn" to={baseUrl}>
          <i className="fal fa-times" />
        </NavLink>
      </div>
    </div>
    <form onSubmit={handleSubmit}>
      <div className="modal-body">
        <div className="grid">
          <Field
            name="contractType"
            component={SelectField}
            options={CONTRACT_TYPES}
            label="Type de contrat"
            className="col-12"
          />
          <Field
            name="contractNumber"
            component={InputField}
            label="Numéro du contrat"
            className="col-12"
          />
          <Field
            name="contractLabel"
            component={InputField}
            label="Libellé"
            className="col-12"
          />
          <Field
            name="contractStartDate"
            component={DateField}
            label="Début"
            className="col-6"
            displayFormat="dd/MM/yyyy"
          />
          <Field
            name="contractEndDate"
            component={DateField}
            label="Fin"
            className="col-6"
            displayFormat="dd/MM/yyyy"
          />
          <Field
            name="contractNbPassage"
            component={InputField}
            type="number"
            min="0"
            label="Nombre de passage/an"
            className="col-12"
          />
          <Field
            name="contractAffectedSite"
            component={SelectField}
            options={map(sites, s => ({ label: s.siteName, value: s.site_id }))}
            label="Site"
            className="col-12"
          />
        </div>
      </div>
      <div className="modal-footer">
        <div className="btn-group center">
          <button className="btn btn-primary" type="submit">
            Enregistrer
          </button>
        </div>
      </div>
    </form>
  </Fragment>
);

ContractEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
  initialValues: PropTypes.shape().isRequired,

  sites: PropTypes.objectOf(PropTypes.shape()).isRequired,
};

export default reduxForm({
  form: 'ContractEditForm',
  validate: (values = {}) => {
    const errors = {};

    if (!values.contractType) errors.contractType = 'Merci de renseigner le type du contrat';
    if (!values.contractNumber) errors.contractNumber = 'Merci de renseigner le numéro du contrat';
    if (!values.contractLabel) errors.contractLabel = 'Merci de renseigner le libellé du contrat';
    if (!values.contractStartDate) errors.contractStartDate = 'Merci de renseigner la date de début du contrat';
    if (moment(values.contractEndDate).isBefore(moment(values.contractStartDate).add(1, 'day'), 'day')) errors.contractEndDate = 'La date de fin doit être supérieure à la date de début du contrat';
    if (!isNumber(values.contractNbPassage)) errors.contractNbPassage = 'Merci de renseigner le nombre de passages par an';
    if (values.contractNbPassage < 0) errors.contractNbPassage = 'Le nombre de passages par an doit être supérieur ou égal à 0';
    if (!isNumber(values.contractAffectedSite)) errors.contractAffectedSite = 'Merci de choisir un site';

    return errors;
  },
})(connect(
  (state, { clientId }) => ({
    sites: clientSubEntitiesSelector(state.data.entities.sites, clientId, 'siteClient'),
  }),
)(ContractEditForm));
