import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { map, slice, values, sortBy, reverse } from 'lodash';

import { UPLOADS_URL, DATE_FORMAT } from '../../constants/Config';

const ActualitysListView = ({ actualitys, nbItemPerPage, baseUrl }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [displayedActu, setDisplayedActu] = useState(actualitys);
  useEffect(() => {
    setDisplayedActu(slice(
      actualitys,
      currentPage * nbItemPerPage,
      (currentPage + 1) * nbItemPerPage,
    ));
  }, [actualitys, currentPage, nbItemPerPage]);

  const nbTotalPage = Math.ceil(actualitys.length / nbItemPerPage);
  return (
    <div className="box full-height">
      <div className="box-header">
        <div className="box-title">
          Actualités
        </div>
      </div>
      <div className="box-content">
        <div className="news-list">
          {map(displayedActu, (actuality, actualityId) => (
            <NavLink key={actualityId} className="news" to={`${baseUrl}/${actuality.actuality_id}/detail`}>
              <div className="grid">
                {actuality.actualityThumbnail && (
                  <div className="col-3">
                    <div className="news-thumbnail">
                      <img alt={actuality.actualityTitle} src={`${UPLOADS_URL}/${actuality.actualityThumbnail}`} />
                    </div>
                  </div>
                )}
                <div className="col">
                  <div className="news-title">
                    {actuality.actualityTitle}
                  </div>
                  <div className="news-date">
                    {moment(actuality.actualityStartDate).format(DATE_FORMAT)}
                  </div>
                  <div className="news-summary">
                    {actuality.actualitySummary}
                  </div>
                </div>
              </div>
            </NavLink>
          ))}
        </div>
        <div className="pagination btn-group center">
          {map(new Array(nbTotalPage), (v, i) => (
            <button key={i} type="button" onClick={() => setCurrentPage(i)} className={currentPage === i ? 'btn btn-primary active' : 'btn btn-primary'}>
              {(i + 1)}
            </button>
          ))
          }
        </div>
      </div>
    </div>
  );
};

ActualitysListView.propTypes = {
  actualitys: PropTypes.arrayOf(PropTypes.shape({
    actuality_id: PropTypes.number.isRequired,
    actualityStartDate: PropTypes.string.isRequired,
    actualityEndDate: PropTypes.string,
    actualityTitle: PropTypes.string.isRequired,
    actualitySummary: PropTypes.string.isRequired,
    actualityContent: PropTypes.string.isRequired,
    actualityThumbnail: PropTypes.string,
    actualityFiles: PropTypes.arrayOf(PropTypes.string),
    actualityLinks: PropTypes.arrayOf(PropTypes.string),
  })).isRequired,
  nbItemPerPage: PropTypes.number,
  baseUrl: PropTypes.string,
};

export default connect(
  state => ({
    actualitys: values(state.data.entities.actualitys),
  }),
)(ActualitysListView);
