import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Modal from "react-modal";
import { withRouter, Switch, Route } from "react-router-dom";
import { pickBy, get, remove } from "lodash";

import {
  createOrUpdateEquipment,
  deleteEquipmentPlan,
  deleteEquipment
} from "../../store/modules/equipments";

import SitesTechniciansAdminView from "../../components/sites/SitesTechniciansAdminView";
import EquipmentAddForm from "../../components/dynamicplan/EquipmentAddForm";

import modalDefaultClass from "../../utils/ModalDefaultClass";

import { equipmentLibre } from "../../constants/Properties";

const RenderEditDetailsModalsRouter = ({
  url,
  editEquipment,
  delEquipment,
  push,
  equipments,
  planId,
  detailEquipment,
  contractId
}) => (
  <Switch>
    <Route
      exact
      path={`${url}/edit/:equipmentId`}
      render={subProps => {
        const isNotRemarquesLibres =
          parseInt(subProps.match.params.equipmentId, 10) !== -2;
        return (
          <Modal {...modalDefaultClass} isOpen onRequestClose={() => push(url)}>
            <EquipmentAddForm
              initialValues={
                isNotRemarquesLibres
                  ? equipments[subProps.match.params.equipmentId]
                  : equipmentLibre(contractId)
              }
              planId={planId}
              onSubmit={v => {
                // if equipmentPlan doesn't exist
                if (v.equipmentPlan === null || v.equipmentPlan === undefined) {
                  v.equipmentPlan = [];
                }
                // if we are on create
                if (v.equipmentPlan.indexOf(parseInt(planId, 10)) === -1) {
                  // we need to add a planId
                  v.equipmentPlan.push(parseInt(planId, 10));
                  // we need to add init position x y in plandetail, contractId and planId
                  const planDetail = v.equipmentPlanDetail || [];
                  const PlanD = {
                    initX: detailEquipment.x,
                    initY: detailEquipment.y,
                    // and x y
                    x: detailEquipment.x,
                    y: detailEquipment.y,
                    planId: parseInt(planId, 10),
                    contractId: parseInt(contractId, 10)
                  };
                  planDetail.push(JSON.stringify(PlanD));
                  v.equipmentPlanDetail = planDetail;
                }
                editEquipment(v).then(() => push(url));
              }}
              onClickDelete={v => {
                if (isNotRemarquesLibres) {
                  // we need to remove a planId on equipmentPlan
                  remove(v.equipmentPlan, e => e === parseInt(planId, 10));
                  remove(
                    v.equipmentPlanDetail,
                    e => JSON.parse(e).planId === parseInt(planId, 10)
                  );
                  editEquipment(v).then(() => push(url));
                } else {
                  // we delete equipment if is Remarques Libres
                  delEquipment(v).then(() => push(url));
                }
              }}
              baseUrl={url}
            />
          </Modal>
        );
      }}
    />
  </Switch>
);

RenderEditDetailsModalsRouter.propTypes = {
  url: PropTypes.string.isRequired,
  planId: PropTypes.number.isRequired,
  contractId: PropTypes.number.isRequired,
  editEquipment: PropTypes.func.isRequired,
  delEquipment: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  equipments: PropTypes.objectOf(PropTypes.shape()).isRequired,
  detailEquipment: PropTypes.shape()
};

const SitesTechniciansAdminContainer = ({
  match: { url },
  history: { push },
  location: { state },
  contracts,
  documents,
  equipments,
  siteId,
  contractId,
  planId,
  baseUrl,
  ...props
}) => (
  <Switch>
    <Route
      exact
      path={`${baseUrl}/:siteId/sites/:contractId/contract`}
      render={subProps => (
        <Fragment>
          {/* pas de plan affichage d'un message (il faut changer le component */}
          <SitesTechniciansAdminView
            contracts={contracts}
            documents={documents}
            planId={null}
            selectedContract={contractId}
            baseUrl={baseUrl}
            subProps={subProps}
          />
        </Fragment>
      )}
    />
    <Route
      exact
      path={`${baseUrl}/:siteId/sites/:planId/plan`}
      render={subProps => (
        <Fragment>
          {/* pas de plan affichage d'un message (il faut changer le component */}
          <SitesTechniciansAdminView
            contracts={contracts}
            documents={documents}
            planId={planId}
            selectedContract={null}
            baseUrl={baseUrl}
            subProps={subProps}
          />
        </Fragment>
      )}
    />
    <Route
      path={`${baseUrl}/:siteId/sites/:contractId/contract/:planId/plan`}
      render={subProps => (
        <Fragment>
          <SitesTechniciansAdminView
            contracts={contracts}
            documents={documents}
            equipments={equipments}
            selectedContract={contractId}
            planId={planId}
            baseUrl={`${baseUrl}/${siteId}/sites`}
          />
          <RenderEditDetailsModalsRouter
            equipments={equipments}
            planId={planId}
            contractId={contractId}
            editEquipment={props.createOrUpdateEquipment}
            delEquipment={props.deleteEquipment}
            url={`${subProps.match.url}`}
            push={push}
            detailEquipment={state}
          />
        </Fragment>
      )}
    />
  </Switch>
);

SitesTechniciansAdminContainer.propTypes = {
  contracts: PropTypes.objectOf(PropTypes.shape()),
  documents: PropTypes.objectOf(PropTypes.shape()),
  equipments: PropTypes.objectOf(PropTypes.shape()),

  createOrUpdateEquipment: PropTypes.func.isRequired,
  deleteEquipment: PropTypes.func.isRequired,
  deleteEquipmentPlan: PropTypes.func.isRequired,

  siteId: PropTypes.number,
  contractId: PropTypes.number,
  planId: PropTypes.number,

  baseUrl: PropTypes.string,

  match: PropTypes.shape({
    url: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    state: PropTypes.shape()
  }).isRequired
};

export default connect(
  (state, { siteId, planId, contractId, documents }) => ({
    contracts: pickBy(
      state.data.entities.contracts,
      d => d.contractAffectedSite === siteId
    ),
    documents,
    equipments: state.data.entities.equipments,
    siteId,
    planId,
    contractId
  }),
  { createOrUpdateEquipment, deleteEquipmentPlan, deleteEquipment }
)(withRouter(SitesTechniciansAdminContainer));
