import React, { Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  head,
  sortBy,
  values,
  pickBy,
  map,
  sumBy,
  sum,
  orderBy,
  filter,
  isNull,
  forEach,
  isEmpty,
  includes,
  reverse,
} from "lodash";

import { DATE_FORMAT, UPLOADS_URL } from "../../constants/Config";

import ActualitysListView from "../actualitys/ActualitysListView";
import ShareddocumentListView from "../shareddocuments/ShareddocumentListView";

import Chart3Lines from "../charts/Chart3Lines";
import ChartSingleLine from "../charts/ChartSingleLine";

moment.locale("fr");

const ClientHomeComponent = ({
  username,
  welcomeMessage,
  baseUrl,
  sites,
  contracts,
  passages,
  equipments,
  documents,
}) => {
  equipments = pickBy(equipments, (eq) => eq.equipmentType !== "remarqueLibre");

  // ont recupere tous les contracts actifs
  const contractsActive = filter(values(contracts), function (o) {
    return (
      (!isNull(o.contractStartDate) &&
        isNull(o.contractEndDate) &&
        moment(o.contractStartDate).isBefore(moment())) ||
      (!isNull(o.contractStartDate) &&
        !isNull(o.contractEndDate) &&
        moment().isBetween(moment(o.contractStartDate), moment(o.contractEndDate)))
    );
  });

  // Filtre les passages ou les contrats sont actifs
  const passagesFitter = filter(values(passages), (o) => {
    const contracts = filter(contractsActive, (contract) => {
      return o.passageContract === contract.contract_id;
    });
    return contracts.length > 0;
  });

  const sortedPassages = orderBy(passagesFitter, "passageDate", "asc");
  const sortedPassagesLast = orderBy(passagesFitter, "passageDate", "desc");
  const passage = head(sortedPassagesLast);

  let datePassage = null;
  let siteName = null;
  if (passage) {
    datePassage = moment(passage.passageDate).format(DATE_FORMAT);
    siteName = sites[passage.passageSite].siteName;
  }

  let deratizationData = null;
  let deratizationLabels = null;
  const consoPosteInside = [];
  const consoPosteOutside = [];
  const consoPosteNull = [];
  let deivData = null;
  let deivLabels = null;
  let isDeiv = true;
  if (values(sites).length === 1) {
    const filterPassg = (p, c) =>
      p.passageContract === c.contract_id && moment(p.passageDate).diff(moment(), "year") === 0;
    const baitContract = pickBy(contracts, (c) => c.contractType === "deratization");
    if (values(baitContract).length === 1) {
      const sortedPassageAsc = orderBy(passagesFitter, "passageDate", "asc");
      const passagesDeratization = pickBy(sortedPassageAsc, (p) =>
        filterPassg(p, values(baitContract)[0])
      );
      let cpt = 0;
      deratizationData = map(passagesDeratization, (p) => {
        consoPosteInside[cpt] = 0;
        consoPosteOutside[cpt] = 0;
        consoPosteNull[cpt] = 0;
        forEach(p.passageEquipments, (pe) => {
          if (
            equipments[pe.equipment] &&
            equipments[pe.equipment].equipmentType === "baitStation" &&
            pe.equipmentState === "consumedRenewed"
          ) {
            if (equipments[pe.equipment].equipmentPosition === "inside") {
              consoPosteInside[cpt] += 1;
            } else if (equipments[pe.equipment].equipmentPosition === "outside") {
              consoPosteOutside[cpt] += 1;
            } else {
              consoPosteNull[cpt] += 1;
            }
          }
        });
        cpt++;
      });
      deratizationLabels = map(passagesDeratization, (p) => moment(p.passageDate).format("D MMM"));
    }

    const DEIVContract = pickBy(contracts, (c) => c.contractType === "DEIV");
    const mothsTrapContract = pickBy(contracts, (c) => c.contractType === "mothsTrap");
    if (values(DEIVContract).length === 1) {
      const passagesDEIV = pickBy(sortedPassages, (p) => filterPassg(p, values(DEIVContract)[0]));
      deivData = map(passagesDEIV, (p) =>
        sumBy(p.passageEquipments, (pe) => {
          if (equipments[pe.equipment] && equipments[pe.equipment].equipmentType === "DEIV") {
            return sum(map(values(pe.equipmentState), (v) => parseInt(v, 10)));
          }
          return 0;
        })
      );
      deivLabels = map(passagesDEIV, (p) => moment(p.passageDate).format("D MMM"));
    } else if (values(mothsTrapContract).length === 1) {
      const passagesMothsTrap = pickBy(sortedPassages, (p) =>
        filterPassg(p, values(mothsTrapContract)[0])
      );
      deivData = map(passagesMothsTrap, (p) =>
        sumBy(p.passageEquipments, (pe) => {
          if (equipments[pe.equipment] && equipments[pe.equipment].equipmentType === "mothsTrap") {
            return pe.equipmentState;
          }
          return 0;
        })
      );
      deivLabels = map(passagesMothsTrap, (p) => moment(p.passageDate).format("D MMM"));
      isDeiv = false;
    }
  }

  const reportDocuments = pickBy(documents, (d) => d.documentCategory === 2);
  const trackingDocuments = pickBy(documents, (d) => d.documentCategory === 3);
  const reportDocument = head(
    orderBy(reportDocuments, ["documentStartDate"], ["desc"])
    //sortBy(reportDocuments, d => moment(d.documentStartDate))
  );
  const trackingDocument = head(
    orderBy(trackingDocuments, ["documentStartDate"], ["desc"])
    // sortBy(trackingDocuments, d => moment(d.documentStartDate))
  );

  return (
    <Fragment>
      <div className="grid">
        <div className="col-12">
          <div className="box box-secondary separated">
            <div className="box-content">
              <div className="grid-noBottom">
                <div className="col-6_sm-12">
                  <div className="dashboard-block-infos">
                    <div className="title">Bonjour {username} !</div>
                    <div>{welcomeMessage}</div>
                  </div>
                </div>
                <span className="separator" />
                {passage && (
                  <div className="col_xs-12_sm-6">
                    <div className="dashboard-block-infos">
                      <div className="title">
                        Passage du {datePassage} sur le site {siteName}
                      </div>
                      <div className="btn-group">
                        {reportDocument && (
                          <a
                            className="btn"
                            href={`${UPLOADS_URL}/${reportDocument.documentURL}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="far fa-ballot-check" />
                            <span>Rapport d'intervention</span>
                          </a>
                        )}
                        {trackingDocument && (
                          <a
                            className="btn"
                            href={`${UPLOADS_URL}/${trackingDocument.documentURL}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="far fa-file-spreadsheet" />
                            <span>Tableau de suivi</span>
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {(deratizationData || deivData) && (
        <div className="grid">
          {deratizationData && (
            <div className="col_sm-12">
              <div className="box">
                <div className="graph-header">
                  <div className="graph-title">Evolution des consommations / captures</div>
                </div>

                <Chart3Lines
                  labels={deratizationLabels}
                  lineLabels={["Postes intérieurs", "Postes extérieurs", "Localisation inconnue"]}
                  data={[consoPosteInside, consoPosteOutside, consoPosteNull]}
                  height={100}
                />
              </div>
            </div>
          )}

          {deivData && (
            <div className="col_sm-12">
              <div className="box">
                <div className="graph-header">
                  <div className="graph-title">
                    {isDeiv === true ? "Evolution du nombre d’insectes capturés" : "Teignes"}
                  </div>
                </div>
                <ChartSingleLine labels={deivLabels} data={deivData} height={100} />
              </div>
            </div>
          )}
        </div>
      )}
      <div className="grid-spaceBetween full-height">
        <div className="col-8_sm-12_md-6">
          <ActualitysListView nbItemPerPage={4} baseUrl={baseUrl} />
        </div>
        <div className="col-4_sm-12_md-6">
          <ShareddocumentListView nbItemPerPage={6} />
        </div>
      </div>
    </Fragment>
  );
};

ClientHomeComponent.propTypes = {
  username: PropTypes.string,
  welcomeMessage: PropTypes.string,
  baseUrl: PropTypes.string.isRequired,

  sites: PropTypes.objectOf(PropTypes.shape()).isRequired,
  contracts: PropTypes.objectOf(PropTypes.shape()).isRequired,
  passages: PropTypes.objectOf(PropTypes.shape()).isRequired,
  equipments: PropTypes.objectOf(PropTypes.shape()).isRequired,
  documents: PropTypes.objectOf(PropTypes.shape()).isRequired,
};

export default connect((state) => ({
  sites: state.data.entities.sites,
  contracts: state.data.entities.contracts,
  passages: state.data.entities.passages,
  equipments: state.data.entities.equipments,
  documents: state.data.entities.documents,
}))(withRouter(ClientHomeComponent));
