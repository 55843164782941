import { accountsActionsHandlers } from './accounts';
import { optionsActionsHandlers } from './options';
import initialState from '../initialState';

import { actualitysActionsHandlers } from './actualitys';
import { billsActionsHandlers } from './bills';
import { categorysActionsHandlers } from './categorys';
import { clientsActionsHandlers } from './clients';
import { contactsActionsHandlers } from './contacts';
import { contractsActionsHandlers } from './contracts';
import { documentsActionsHandlers } from './documents';
import { equipmentsActionsHandlers } from './equipments';
import { passagesActionsHandlers } from './passages';
import { sharedcategorysActionsHandlers } from './sharedcategorys';
import { shareddocumentsActionsHandlers } from './shareddocuments';
import { sitesActionsHandlers } from './sites';
import { structuresActionsHandlers } from './structures';
import { techniciansActionsHandlers } from './technicians';

import { uploadsActionsHandlers } from './uploads';
import { socketActionsHandlers } from './socket';
import { globalsActionsHandlers } from './globals';

const actionsHandlers = {
  ...accountsActionsHandlers,
  ...optionsActionsHandlers,
  ...uploadsActionsHandlers,

  ...actualitysActionsHandlers,
  ...billsActionsHandlers,
  ...categorysActionsHandlers,
  ...clientsActionsHandlers,
  ...contactsActionsHandlers,
  ...contractsActionsHandlers,
  ...documentsActionsHandlers,
  ...equipmentsActionsHandlers,
  ...passagesActionsHandlers,
  ...sharedcategorysActionsHandlers,
  ...shareddocumentsActionsHandlers,
  ...sitesActionsHandlers,
  ...structuresActionsHandlers,
  ...techniciansActionsHandlers,

  ...socketActionsHandlers,
  ...globalsActionsHandlers,
};

export default function reducer (state = initialState, action) {
  const reduceFn = actionsHandlers[action.type];
  return reduceFn ? reduceFn(state, action) : state;
}
