import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { NavLink } from "react-router-dom";
import { isNumber } from "lodash";
import { Field, InputField, ToggleField, SelectField } from "react-components-linaia";
import buildOptionsFromContracts from "../options/OptionsContracts";
import { EQUIPMENT_TYPES } from "../../constants/Properties";

import createSelector from "../../selectors/ClientSubEntitiesSelector";

const clientSubEntitiesSelector = createSelector();

const EquipmentEditForm = ({ handleSubmit, baseUrl, initialValues, contracts }) => (
  <Fragment>
    <div className="modal-title">
      <div className="modal-title-content">
        {initialValues.equipment_id >= 0 ? "Modifier l'équipement" : " Ajouter l'équipement"}
        <NavLink className="btn" to={baseUrl}>
          <i className="fal fa-times" />
        </NavLink>
      </div>
    </div>
    <form onSubmit={handleSubmit}>
      <div className="modal-body">
        <div className="grid">
          <Field
            name="equipmentName"
            component={InputField}
            label="Libellé du type d'équipement"
            className="col-12"
          />
          <Field
            name="equipmentLocation"
            component={InputField}
            label="Localisation"
            className="col-12"
          />
          <Field
            name="equipmentPostNumber"
            component={InputField}
            label="Numéro de poste"
            className="col-12"
          />
          <Field
            name="equipmentType"
            component={SelectField}
            options={EQUIPMENT_TYPES}
            label="Type d'équipement"
            className="col-12"
          />
          <Field
            name="equipmentBaitType"
            component={InputField}
            label="Type d'appât"
            className="col-12"
          />
          <Field
            name="equipmentIsToxic"
            component={ToggleField}
            label="Toxique"
            className="col-12"
            fieldClassName="checkbox"
          />
          <Field
            name="equipmentUsedProduct"
            component={InputField}
            label="Produit utilisé"
            className="col-12"
          />
          <Field
            name="equipmentContract"
            component={SelectField}
            options={buildOptionsFromContracts(contracts)}
            // optionsKeys={{ value: 'contract_id', label: 'contractLabel' }} - removed as we build options
            label="Contrat"
            className="col-12"
          />
        </div>
      </div>
      <div className="modal-footer">
        <div className="btn-group center">
          <button className="btn btn-primary" type="submit">
            Enregistrer
          </button>
        </div>
      </div>
    </form>
  </Fragment>
);

EquipmentEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  baseUrl: PropTypes.string.isRequired,
  initialValues: PropTypes.shape().isRequired,

  contracts: PropTypes.objectOf(PropTypes.shape()).isRequired,
};

export default reduxForm({
  form: "EquipmentEditForm",
  validate: (values = {}) => {
    const errors = {};

    if (!values.equipmentName)
      errors.equipmentName = "Merci de renseigner le libellé du type de l'équipement";
    if (!values.equipmentLocation)
      errors.equipmentLocation = "Merci de renseigner la localisation de l'équipement";
    if (!values.equipmentPostNumber)
      errors.equipmentPostNumber = "Merci de renseigner le numéro du poste de l'équipement";
    if (!values.equipmentType) errors.equipmentType = "Merci de renseigner le type de l'équipement";
    if (!isNumber(values.equipmentContract))
      errors.equipmentContract = "Merci de choisir un contrat";

    return errors;
  },
})(
  connect((state, { clientId }) => ({
    contracts: clientSubEntitiesSelector(state.data.entities.contracts, clientId, "contractClient"),
  }))(EquipmentEditForm)
);
