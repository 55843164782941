import { flow, set } from 'lodash/fp';
import { filter, values, sortBy } from 'lodash';

import { CALL_API, Schemas } from '../Schemas';

const FETCH_ALL_ACTUALITYS_REQUEST = 'fgwa/actualitys/FETCH_ALL_ACTUALITYS_REQUEST';
export const FETCH_ALL_ACTUALITYS_SUCCESS = 'fgwa/actualitys/FETCH_ALL_ACTUALITYS_SUCCESS';
const FETCH_ALL_ACTUALITYS_FAILURE = 'fgwa/actualitys/FETCH_ALL_ACTUALITYS_FAILURE';
const FETCH_ACTUALITY_REQUEST = 'fgwa/actualitys/FETCH_ACTUALITY_REQUEST';
const FETCH_ACTUALITY_SUCCESS = 'fgwa/actualitys/FETCH_ACTUALITY_SUCCESS';
const FETCH_ACTUALITY_FAILURE = 'fgwa/actualitys/FETCH_ACTUALITY_FAILURE';
const SET_ACTUALITY_REQUEST = 'fgwa/actualitys/SET_ACTUALITY_REQUEST';
const SET_ACTUALITY_SUCCESS = 'fgwa/actualitys/SET_ACTUALITY_SUCCESS';
const SET_ACTUALITY_FAILURE = 'fgwa/actualitys/SET_ACTUALITY_FAILURE';
const DELETE_ACTUALITY_REQUEST = 'fgwa/actualitys/DELETE_ACTUALITY_REQUEST';
const DELETE_ACTUALITY_SUCCESS = 'fgwa/actualitys/DELETE_ACTUALITY_SUCCESS';
const DELETE_ACTUALITY_FAILURE = 'fgwa/actualitys/DELETE_ACTUALITY_FAILURE';

export const actualitysActionsHandlers = {
  [FETCH_ALL_ACTUALITYS_REQUEST]: state => flow(
    set('loaded.actualitys', false),
    set('loading.actualitys', true),
  )(state),
  [FETCH_ALL_ACTUALITYS_SUCCESS]: (state, action) => flow(
    // on tri les actualitys a la reception de la donnée et non a la recupération des actualitys (connect)
    set('entities.actualitys', Object.assign({}, sortBy(values(action.response.entities.actualitys), [o => o.actualityStartDate]).reverse()) || {}),
    set('loaded.actualitys', true),
    set('loading.actualitys', false),
  )(state),
  [FETCH_ALL_ACTUALITYS_FAILURE]: state => flow(
    set('loaded.actualitys', false),
    set('loading.actualitys', false),
  )(state),
  [FETCH_ACTUALITY_SUCCESS]: (state, action) => flow(
    set(`entities.actualitys.${action.id}`, action.response.entities.actualitys),
  )(state),
  [SET_ACTUALITY_SUCCESS]: (state, action) => flow(
    set('entities.actualitys', {
      ...state.entities.actualitys,
      ...action.response.entities.actualitys,
    }),
  )(state),
  [DELETE_ACTUALITY_SUCCESS]: (state, action) => flow(
    set('entities.actualitys', filter(state.entities.actualitys, o => (o.actuality_id !== action.id))),
  )(state),
};

export function loadActuality (id) {
  return {
    id,
    [CALL_API]: {
      types: [
        FETCH_ACTUALITY_REQUEST,
        FETCH_ACTUALITY_SUCCESS,
        FETCH_ACTUALITY_FAILURE,
      ],
      method: 'GET',
      endpoint: `/actualitys/${id}`,
      schema: Schemas.ACTUALITY,
    },
  };
}

export function loadActualitys () {
  return {
    [CALL_API]: {
      types: [
        FETCH_ALL_ACTUALITYS_REQUEST,
        FETCH_ALL_ACTUALITYS_SUCCESS,
        FETCH_ALL_ACTUALITYS_FAILURE,
      ],
      method: 'GET',
      endpoint: '/actualitys',
      schema: Schemas.ACTUALITY_ARRAY,
    },
  };
}

export function createOrUpdateActuality (actuality) {
  return {
    [CALL_API]: {
      types: [
        SET_ACTUALITY_REQUEST,
        SET_ACTUALITY_SUCCESS,
        SET_ACTUALITY_FAILURE,
      ],
      method: !actuality || !Number.isInteger(actuality.actuality_id) ? 'POST' : 'PUT',
      endpoint: !actuality || !Number.isInteger(actuality.actuality_id) ? '/actualitys' : `/actualitys/${actuality.actuality_id}`,
      schema: Schemas.ACTUALITY,
      body: actuality,
      successMessage: 'Actualité enregistrée avec succès',
    },
  };
}

export function deleteActuality (id) {
  return {
    id,
    [CALL_API]: {
      types: [
        DELETE_ACTUALITY_REQUEST,
        DELETE_ACTUALITY_SUCCESS,
        DELETE_ACTUALITY_FAILURE,
      ],
      method: 'DELETE',
      endpoint: `/actualitys/${id}`,
      successMessage: 'Actualité supprimée avec succès',
    },
  };
}
