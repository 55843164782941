import React from "react";
import PropTypes from "prop-types";
import { Line } from "react-chartjs-2";
import "chartjs-plugin-colorschemes";
import { map } from "lodash";

const Chart3Lines = ({ labels, lineLabels, data, height, colors }) => (
  <Line
    height={height}
    data={{
      labels,
      datasets: map(data, (singleData, index) => ({
        label: lineLabels[index],
        data: singleData,
        borderWidth: 2,
        pointRadius: 6,
        lineTension: 0.5,
        fill: false,
      })),
    }}
    options={{
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        },
      },
      scales: {
        yAxes: [{
          ticks: {
            stepSize: 1
          }
        }]
      },
      legend: {
        labels: {
          usePointStyle: true,
          boxWidth: 10,
        },
      },
      plugins: {
        colorschemes: {
          scheme: "tableau.SunsetSunrise7",
        },
      },
    }}
  />
);

Chart3Lines.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  lineLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
  colors: PropTypes.arrayOf(
    PropTypes.shape({
      background: PropTypes.string.isRequired,
      border: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default Chart3Lines;
