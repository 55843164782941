export default {
  entities: {
    accounts: {},
    options: {},

    actualitys: {},
    bills: {},
    categorys: {},
    clients: {},
    contacts: {},
    contracts: {},
    documents: {},
    equipments: {},
    passages: {},
    sharedcategorys: {},
    shareddocuments: {},
    sites: {},
    structures: {},
    technicians: {},
  },
  loaded: {
    appstorage: false,
    accounts: false,
    options: false,

    actualitys: false,
    bills: false,
    categorys: false,
    clients: false,
    contacts: false,
    contracts: false,
    documents: false,
    equipments: false,
    passages: false,
    sharedcategorys: false,
    shareddocuments: false,
    sites: false,
    structures: false,
    technicians: false,
  },
  loading: {
    accounts: false,
    options: false,

    actualitys: false,
    bills: false,
    categorys: false,
    clients: false,
    contacts: false,
    contracts: false,
    documents: false,
    equipments: false,
    passages: false,
    sharedcategorys: false,
    shareddocuments: false,
    sites: false,
    structures: false,
    technicians: false,
  },
  message: {
    text: '',
    messageType: '',
    delay: 0,
  },
  config: {
    menuIsTiny: null,
    menuIsOpen: null,
  },
};
